import React from "react";
import { IconType } from "react-icons/lib";
import { NavLink } from "react-router-dom";

interface IComponentProps {
  to: string;
  title: string;
  Icon: IconType;
}

const AdminLink: React.FC<IComponentProps> = ({ to, Icon, title }) => {
  const isActive = ({ isActive }: any) => (isActive ? "active-link" : "");

  return (
    <div className="admin-links-section">
      <NavLink to={to} className={isActive}>
        <div className="admin-headline first-headline">
          <Icon className="admin-icon" />
          <h1 className="sidebar-title">{title}</h1>
        </div>
      </NavLink>
    </div>
  );
};

export default AdminLink;
