import Logo from '../../assets/sitemap-logo.png';
import { Link } from 'react-router-dom';
import { MdLocationPin } from 'react-icons/md';
import { AiFillPhone, AiFillMail, AiOutlineInstagram, AiOutlineClockCircle } from 'react-icons/ai';
import { FaFacebook } from 'react-icons/fa';
import '../../styles/Footer.css';
import SitemapMobile from './SitemapMobile';
import useUser from '../../hooks/useUser';
import { UserRole } from '../../common/enums';

import Visa from '../../assets/payment/visa.jpg';
import Master from '../../assets/payment/master.png';
import Maestro from '../../assets/payment/Maestro.jpg';
import Diners from '../../assets/payment/diners.png';
import Amex from '../../assets/payment/amex.webp';

const Footer = () => {
  const user = useUser();

  const ONLINE_PAYMENT_AVAILABLE: boolean = false;

  return (
    <footer className='footer'>
      <section className='sitemap'>
        <div className='logo-sitemap'>
          <img src={Logo} alt='' loading='lazy' />
        </div>
        {window.innerWidth > 1000 ? (
          <>
            <div className='sitemap-block'>
              <h3>Korisnički nalog</h3>
              <Link to='/'>Početna</Link>
              {user === null && (
                <>
                  <Link to='/sign-in'>Prijava</Link>
                  <Link to='/register'>Registruj se</Link>
                  <Link to='/forgot-password'>Zaboravljena lozinka?</Link>
                </>
              )}
              {user !== null && user.roleId === UserRole.Buyer && (
                <>
                  <Link to='/account'>Nalog</Link>
                  <Link to='/my-orders'>Porudžbine</Link>
                  <Link to='/wishlist'>Lista želja</Link>
                </>
              )}
              {user !== null && user.roleId === UserRole.Admin && (
                <>
                  <Link to='/account'>Nalog</Link>
                  <Link to='/admin/products'>Proizvodi</Link>
                  <Link to='/admin/orders'>Porudžbine</Link>
                  <Link to='/admin/coupons'>Kuponi</Link>
                  <Link to='/admin/users'>Korisnici</Link>
                </>
              )}
              <Link to='/privacy-and-policy'>Politika privatnosti</Link>
            </div>
            <div className='sitemap-block'>
              <h3>Kupovina</h3>
              <Link to='/store'>Prodavnica</Link>
              <Link to='/store?searchType=new-collection'>Nova kolekcija</Link>
              <Link to='/store?searchType=discount'>Sniženje</Link>
              <Link to='/store?genders=0'>Muško</Link>
              <Link to='/store?genders=1'>Žensko</Link>
              <Link to='/store?genders=2'>Dečije</Link>
              <Link to='/shopping-cart'>Korpa</Link>
              <Link to='/check-order-status'>Status porudžbine</Link>
            </div>
            <div className='sitemap-block'>
              <h3>Kontakt</h3>
              <div className='location-data'>
                <span>
                  <AiFillMail size={23} style={{ marginRight: 4 }} /> Mejl
                </span>
                <p>
                  <a href='mailto:obucabig@gmail.com'>obucabig@gmail.com</a>
                </p>
              </div>
              <div className='location-data'>
                <span>
                  <AiFillPhone size={23} style={{ marginRight: 4 }} /> Telefon
                </span>
                <p>
                  <a href='tel:+38166048905'>+381 66 048 905</a>
                </p>
              </div>
              <div className='location-data'>
                <span>
                  <AiOutlineClockCircle size={23} style={{ marginRight: 4 }} /> Radno vreme
                </span>
                <p>Pon-Pet: 08:00 - 20:30</p>
              </div>
              <div className='location-data'>
                <h3>DRUŠTVENE MREŽE</h3>
                <div className='social-media'>
                  <a href='https://www.facebook.com/obucabig' target='_blank' rel='noreferrer'>
                    <div className='social-media-icon'>
                      <FaFacebook />
                    </div>
                  </a>
                  <a href=' https://www.instagram.com/obucabig' target='_blank' rel='noreferrer'>
                    <div className='social-media-icon'>
                      <AiOutlineInstagram />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className='sitemap-block'>
              <h3>Prodavnice</h3>
              <div className='location-data'>
                <span>
                  <MdLocationPin size={23} style={{ marginRight: 4 }} /> Niš
                </span>
                <p>Bul. Nemanjica 25a lok. 33</p>
              </div>
              <div className='location-data'>
                <span>
                  <MdLocationPin size={23} style={{ marginRight: 4 }} /> Pirot
                </span>
                <p>Knjaza Miloša 74</p>
              </div>
              <div className='location-data'>
                <span>
                  <MdLocationPin size={23} style={{ marginRight: 4 }} /> Kruševac
                </span>
                <p>Vidovdanska 205</p>
              </div>
            </div>
          </>
        ) : (
          <SitemapMobile />
        )}
      </section>
      <p className='note-for-copyrights'>
        Nastojimo da budemo što precizniji u opisu proizvoda, prikazu slika i samih cena, ali ne
        možemo garantovati da su sve informacije kompletne i bez grešaka. Cene na sajtu su iskazane
        u dinarima sa uračunatim porezom, a plaćanje se vrši isključivo u dinarima. Isporuka se vrši
        SAMO na teritoriji Republike Srbije.
      </p>
      {!ONLINE_PAYMENT_AVAILABLE && <br />}
      {ONLINE_PAYMENT_AVAILABLE && (
        <div className='supported-payment-cards'>
          <img src={Visa} alt='' />
          <img src={Master} alt='' />
          <img src={Maestro} alt='' />
          <img src={Amex} alt='' />
          <img src={Diners} alt='' />
        </div>
      )}
      <section className='copyright'>
        <h3>
          <b>&copy; Obuća Big {new Date().getFullYear()}</b>
        </h3>
      </section>
    </footer>
  );
};

export default Footer;
