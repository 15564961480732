import React from 'react';
import AdminLink from './AdminLink';
import {
  AiOutlineSetting,
  AiOutlineShopping,
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineBarChart,
} from 'react-icons/ai';
import { MdOutlineDiscount } from 'react-icons/md';
import { CgMail } from 'react-icons/cg';
import { BiImages } from 'react-icons/bi';
import useUser from '../../hooks/useUser';
import { UserRole } from '../../common/enums';

const AdminLinksMapping = () => {
  const user = useUser();

  return (
    <div className='admin-links'>
      <AdminLink to='/admin/dashboard' title='Kontrolna tabla' Icon={AiOutlineBarChart} />

      {user?.roleId === UserRole.Admin && (
        <AdminLink to='/admin/users' title='Korisnici' Icon={AiOutlineUser} />
      )}

      <AdminLink to='/admin/orders' title='Porudžbine' Icon={AiOutlineShoppingCart} />
      <AdminLink to='/admin/products' title='Proizvodi' Icon={AiOutlineShopping} />

      {user?.roleId === UserRole.Admin && (
        <>
        <AdminLink to='/admin/coupons' title='Kuponi' Icon={MdOutlineDiscount} />
          <AdminLink to='/admin/mail-servers' title='Mail serveri' Icon={CgMail} />
          <AdminLink to='/admin/web-images' title='Web slike' Icon={BiImages} />
          <AdminLink to='/admin/settings' title='Podešavanja' Icon={AiOutlineSetting} />
        </>
      )}
    </div>
  );
};

export default AdminLinksMapping;
