import React from 'react';
import useUser from '../../hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { UserRole, WebImagePosition } from '../../common/enums';
import { AiOutlineHeart, AiOutlineSearch, AiOutlineShopping } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface IComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const BuyerUI: React.FC<IComponentProps> = ({ setOpen }) => {
  const user = useUser();
  const navigate = useNavigate();

  const webImages = useSelector((state: RootState) => state.webImages).webImages;

  const openLink = (url: string) => {
    navigate(url);
    setOpen(false);
    document.body.style.overflow = 'visible';
  };

  return (
    <>
      <div className='burger-menu-links'>
        <div className='burger-menu-plain-link' onClick={() => openLink('/check-order-status')}>
          <AiOutlineSearch className='plain-icon' />
          <h3>Status porudžbine</h3>
        </div>
        {user !== null && user.roleId === UserRole.Buyer && (
          <>
            <div className='burger-menu-plain-link' onClick={() => openLink('/my-orders')}>
              <AiOutlineShopping className='plain-icon' />
              <h3>Porudžbine</h3>
            </div>
            <div className='burger-menu-plain-link' onClick={() => openLink('/wishlist')}>
              <AiOutlineHeart className='plain-icon' />
              <h3>Lista želja</h3>
            </div>
          </>
        )}
        <div className='burger-link' onClick={() => openLink('/store?genders=0')}>
          <h3>Muška obuća</h3>
          <img
            src={webImages.find((x) => x.imagePosition === WebImagePosition.MalePoster)?.url}
            alt=''
            className='burger-link-img'
          />
        </div>
        <div className='burger-link' onClick={() => openLink('/store?genders=1')}>
          <h3>Ženska obuća</h3>
          <img
            src={webImages.find((x) => x.imagePosition === WebImagePosition.FemalePoster)?.url}
            alt=''
            className='burger-link-img'
          />
        </div>
        <div className='burger-link' onClick={() => openLink('/store?genders=2')}>
          <h3>Dečija obuća</h3>
          <img
            src={webImages.find((x) => x.imagePosition === WebImagePosition.KidsPoster)?.url}
            alt=''
            className='burger-link-img'
          />
        </div>
        <div className='burger-link' onClick={() => openLink('/store?searchType=discount')}>
          <h3>Sniženja</h3>
          <img
            src={webImages.find((x) => x.imagePosition === WebImagePosition.DiscountPoster)?.url}
            alt=''
            className='burger-link-img'
          />
        </div>
        <div className='burger-link' onClick={() => openLink('/store?searchType=new-collection')}>
          <h3>Nova kolekcija</h3>
          <img
            src={
              webImages.find((x) => x.imagePosition === WebImagePosition.NewCollectionPoster)?.url
            }
            alt=''
            className='burger-link-img'
          />
        </div>
      </div>
      <div className='cart-and-account'>
        <button className='burger-btn cart-burger-btn' onClick={() => openLink('/shopping-cart')}>
          Korpa
        </button>
        <button
          className='burger-btn acc-burger-btn'
          onClick={() => openLink(user ? '/account' : '/sign-in')}
        >
          {user ? 'Nalog' : 'Prijavi se'}
        </button>
      </div>
    </>
  );
};

export default BuyerUI;
