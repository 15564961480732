import '../../styles/Header.css';
import Logo from '../../assets/big-obuca-logo.jpg';
import { Link, useNavigate } from 'react-router-dom';
import {
  AiOutlineSearch,
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineHeart,
} from 'react-icons/ai';
import useUser from '../../hooks/useUser';
import { UserRole } from '../../common/enums';
import React, { useState, useRef } from 'react';
import { isNullOrWhiteSpace } from '../../utils/strings';
import { ISavedProduct } from '../../models/product-base';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { isWinterAndAutumnSeason } from '../../helpers/date-helper';
import { TbSearch } from 'react-icons/tb';

interface ISubComponentProps {
  elRef: React.MutableRefObject<HTMLDivElement>;
  elOverlayRef: React.MutableRefObject<HTMLDivElement>;
}

interface ISubComponentPropsNavBarLink {
  title: string;
  route: string;
}

const Header = () => {
  const navigate = useNavigate();

  const modalRef = useRef<HTMLDivElement>(document.createElement('div'));
  const overlayRef = useRef<HTMLDivElement>(document.createElement('div'));

  const user = useUser();

  const products: ISavedProduct[] = useSelector((x: RootState) => x.shoppingCart.products);

  const NavbarLink: React.FC<ISubComponentPropsNavBarLink> = ({ route, title }) => {
    let currentUrl = window.location.href.replace(window.location.origin, '');
    const activeStyle = route === currentUrl ? 'active-navbar-link' : '';

    return (
      <Link to={route} className={activeStyle}>
        {title}
      </Link>
    );
  };

  const SearchPoroducts: React.FC<ISubComponentProps> = ({ elRef, elOverlayRef }) => {
    const [title, setTitle] = useState<string | null>(null);

    const close = () => {
      elRef.current.classList.remove('show-search-bar');
      elOverlayRef.current.classList.remove('show-overlay-search');

      setTimeout(() => {
        elOverlayRef.current.style.display = 'none';
      }, 255);

      document.body.style.overflow = 'auto';
    };

    window.addEventListener('popstate', () => (document.body.style.overflow = 'auto'));

    document.addEventListener('keydown', function (event) {
      if (event.key === 'Escape' || event.keyCode === 27) {
        close();
      }
    });

    return (
      <>
        <div className='search-bar-overlay' ref={elOverlayRef} onClick={close}></div>
        <div className='search-products-modal' ref={elRef}>
          <div className='nav-items'>
            <div className='input-search'>
              <input
                type='text'
                name='productsearch'
                id='productsearch'
                autoComplete='off'
                aria-autocomplete='none'
                onChange={(e) => setTitle(e.target.value)}
                onKeyUp={(e) => {
                  if (e.keyCode === 13 && !isNullOrWhiteSpace(title)) {
                    const formatedTitle = title?.replaceAll(' ', '').toLowerCase();
                    document.body.style.overflow = 'auto';
                    navigate('/store?title=' + formatedTitle);
                  }
                }}
                placeholder='Pretraga proizvoda...'
              />
              <button
                className='search-btn-header'
                onClick={() => {
                  if (!isNullOrWhiteSpace(title)) {
                    const formatedTitle = title?.replaceAll(' ', '').toLowerCase();
                    document.body.style.overflow = 'auto';
                    navigate('/store?title=' + formatedTitle);
                  }
                }}
              >
                <AiOutlineSearch size={25} style={{ color: '#fff' }} />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <SearchPoroducts elRef={modalRef} elOverlayRef={overlayRef} />
      <header className='header'>
        <nav className='navbar'>
          <div className='logo-container' onClick={() => navigate('/')}>
            <img src={Logo} alt='' loading='lazy' />
          </div>

          <div className='links'>
            <div
              className='link-a'
              onClick={() => {
                modalRef.current.classList.add('show-search-bar');
                overlayRef.current.style.display = 'block';
                overlayRef.current.classList.add('show-overlay-search');
                document.body.style.overflow = 'hidden';
              }}
            >
              <div className='link-items'>
                <TbSearch className='link-icon' />
                <p>Pretraga</p>
              </div>
            </div>

            {user && user.roleId === UserRole.Buyer && (
              <Link to='/account'>
                <div className='link-items'>
                  <AiOutlineUser className='link-icon' />
                  <p>Nalog</p>
                </div>
              </Link>
            )}

            {!user && (
              <Link to='/sign-in'>
                <div className='link-items'>
                  <AiOutlineUser className='link-icon' />
                  <p>Prijava</p>
                </div>
              </Link>
            )}
            {user && (
              <Link to='/wishlist' className='header-button'>
                <div className='link-items'>
                  <AiOutlineHeart className='link-icon' />
                  <p>Lista želja</p>
                </div>
              </Link>
            )}
            <Link to='/shopping-cart' className='header-button' style={{ marginLeft: 15 }}>
              <div className='link-items'>
                <div className='cart-products-count-mobile'>
                  <span>{products.length ?? 0}</span>
                </div>
                <AiOutlineShoppingCart className='link-icon' />
                <p>Korpa</p>
              </div>
            </Link>
          </div>
        </nav>
      </header>
      <nav className='navbar-2'>
        <NavbarLink route='/' title='Početna' />
        <NavbarLink route='/store' title='Prodavnica' />
        <NavbarLink route='/store?searchType=new-collection' title='Nova kolekcija' />
        <NavbarLink route='/store?searchType=discount' title='sniženje' />
        <NavbarLink route='/store?genders=0' title='Muško' />
        <NavbarLink route='/store?genders=1' title='Žensko' />
        <NavbarLink route='/store?genders=2' title='Dečije' />
        {isWinterAndAutumnSeason() ? (
          <NavbarLink route='/store?season=jesen-zima' title='JESEN-ZIMA' />
        ) : (
          <NavbarLink route='/store?season=prolece-leto' title='PROLEĆE-LETO' />
        )}
        <NavbarLink route='/check-order-status' title='Status porudžbine' />

        {user && user.roleId === UserRole.Buyer && (
          <NavbarLink route='/my-orders' title='Porudžbine' />
        )}
      </nav>
    </>
  );
};

export default Header;
