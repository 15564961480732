import React, { createContext } from 'react';
import { IPaginationContext } from '../../models/context';
import useResize from '../../hooks/useResize';
import PaginationMobile from './PaginationMobile';
import PaginationDesktop from './PaginationDesktop';

export const PaginationContext = createContext<IPaginationContext>({} as any);

const Pagination: React.FC<IPaginationContext> = ({ context, actionButton, sortCategories }) => {
  const renderMobile = useResize() <= 1000;

  const contextReference = { context, actionButton, sortCategories };

  return (
    <PaginationContext.Provider value={contextReference}>
      {renderMobile ? <PaginationMobile /> : <PaginationDesktop />}
    </PaginationContext.Provider>
  );
};

export default Pagination;
