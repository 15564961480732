import React, { useContext } from 'react';
import { IAdminBackofficeSearchContext } from '../../../models/context';
import { ProductsContext } from '../../../pages/admin/products/Products';
import { SliderList } from '../../Html/SliderList';
import useResize from '../../../hooks/useResize';
import FormGroup from '../../Html/FormGroup';
import Input from '../../Html/Input';
import Select from '../../Html/Select';
import { genders } from '../../../common/data';

const AdminBackofficeSearch = () => {
  const { dispatch, categories, sizes, brands, discounts } =
    useContext<IAdminBackofficeSearchContext>(ProductsContext);

  const searchOpenDefaultState = useResize() > 1000;

  return (
    <div className='search-product-admin admin-search-mobile'>
      <SliderList title='Pretraga' defaultState={searchOpenDefaultState}>
        <div className='admin-inputes mobile-admin-inputs'>
          <FormGroup>
            <Input
              label='Šifra artikla'
              onChange={(e) => dispatch({ type: 'code', payload: e.target.value })}
            />
            <Input
              label='Naziv artikla'
              onChange={(e) => dispatch({ type: 'title', payload: e.target.value })}
            />
          </FormGroup>

          <FormGroup>
            <Select
              hasNoValue={true}
              label='Brend'
              onChange={(e) => dispatch({ type: 'brand', payload: e.target.value })}
            >
              {brands?.map((x, i) => (
                <option value={x.name} key={i}>
                  {x.name}
                </option>
              ))}
            </Select>
            <Select
              hasNoValue={true}
              label='Kategorija'
              onChange={(e) => dispatch({ type: 'category', payload: e.target.value })}
            >
              {categories?.map((x, i) => (
                <option value={x.name} key={i}>
                  {x.name}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Select
              hasNoValue={true}
              label='Veličina'
              onChange={(e) => dispatch({ type: 'size', payload: e.target.value })}
            >
              {sizes?.map((x, i) => (
                <option value={x.size} key={i}>
                  {x.size}
                </option>
              ))}
            </Select>
            <Select
              hasNoValue={true}
              label='Pol'
              onChange={(e) => dispatch({ type: 'gender', payload: e.target.value })}
            >
              {genders?.map((x, i) => (
                <option value={x.id} key={i}>
                  {x.name}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Select
              hasNoValue={true}
              label='Dostupnost'
              onChange={(e) => dispatch({ type: 'available', payload: e.target.value })}
            >
              <option value={1}>Dostupno</option>
              <option value={0}>Nije dostupno</option>
            </Select>
            <Select
              hasNoValue={true}
              label='Popust'
              onChange={(e) => dispatch({ type: 'discount', payload: e.target.value })}
            >
              {discounts.length > 0 &&
                discounts.map((x, i) => (
                  <option value={x} key={i}>
                    -{x}%
                  </option>
                ))}
              <option value={-1}>Bez popusta</option>
            </Select>
          </FormGroup>
        </div>
      </SliderList>
      <div className='buttons-align'>
        <div className='buttons-wrp'>
          <button
            className='button-el-classic reset-btn-admin'
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => {
              dispatch({ type: 'reset' });
            }}
          >
            RESETUJ
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminBackofficeSearch;
