import React from 'react';
import Modal from '../Html/Modal';
import OrderService from '../../services/order.service';
import { Status } from './Order';
import { OrderStatus } from '../../common/enums';

interface IComponentProps {
  getOrders: () => Promise<void>;
  orderStatus: Status | undefined;
  isOpen: boolean;
  orderStatusRef: React.RefObject<HTMLSelectElement>;
  status: OrderStatus | undefined;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStatus: React.Dispatch<React.SetStateAction<OrderStatus | undefined>>;
}

const ChangeStatusModal: React.FC<IComponentProps> = ({
  getOrders,
  orderStatus,
  isOpen,
  orderStatusRef,
  status,
  setIsOpen,
  setStatus,
}) => {
  const changeStatus = async () => {
    await OrderService.changeStatus(orderStatus?.orderId as number, status as OrderStatus);
    setIsOpen(false);
    await getOrders();
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} title='PROMENI STATUS PORUDZBINE'>
      <div className='modal-data-list'>
        <div className='content-wrapper'>
          <div className='input-element'>
            <label>Status</label>
            <select
              ref={orderStatusRef}
              className='input-element full-width'
              id='order-status'
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setStatus(parseInt(e.target.value) as OrderStatus)
              }
            >
              <option value={OrderStatus.Processing}>Obrada</option>
              <option value={OrderStatus.Delivering}>Dostavljanje</option>
              <option value={OrderStatus.Finished}>Završeno</option>
              <option value={OrderStatus.Declined}>Otkazano</option>
            </select>
            <span className='modal-error' id='error-size'></span>
          </div>
        </div>
        <button onClick={changeStatus}>SAČUVAJ</button>
        <span className='modal-error'></span>
      </div>
    </Modal>
  );
};

export default ChangeStatusModal;
