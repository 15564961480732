import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Environment from '../../common/environment';
import '../../styles/Product.css';
import '../../styles/Store.css';
import { IProductInfo, ISize } from '../../models/product';
import { BiCartAdd } from 'react-icons/bi';
import { IProductBase, ISavedProduct } from '../../models/product-base';
import ProductListItem from '../../components/Product/Other/ProductListItem';
import { genders } from '../../common/data';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/reducers/shoppingCartReducer';
import { formatPrice, modalError } from '../../helpers/uiHelper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import ProductService from '../../services/product.service';
import Constants from '../../common/constants';
import { useScrollOnLoad } from '../../hooks/useScrollOnLoad';
import { ISEO } from '../../helpers/seo-config';
import MetaTags from '../../components/Html/MetaTags';
import OpenImage from '../../components/Html/OpenImage';
import WishlistService from '../../services/wishlist.service';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import useUser from '../../hooks/useUser';
import { AxiosAuthorized, AxiosBase } from '../../common/axios';
import { isNullOrWhiteSpace } from '../../utils/strings';

const Product = () => {
  const products: ISavedProduct[] = useSelector((x: RootState) => x.shoppingCart.products);

  const navigate = useNavigate();

  const { id } = useParams();

  const dispatch = useDispatch();

  useScrollOnLoad();

  const [product, setProduct] = useState<IProductInfo | null>(null);
  const [seo, setSeo] = useState<ISEO>({ title: '', route: '/product' });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [related, setRelated] = useState<IProductBase[]>([]);
  const [visited, setVisited] = useState<IProductBase[]>([]);
  const [size, setSize] = useState<ISize | null>(null);
  const [count, setCount] = useState<number>(1);
  const [isInCard, setIsInCard] = useState<boolean>(false);
  const [mainImage, setMainImage] = useState<string>('');
  const [renderModal, setRenderModal] = useState<boolean>(false);

  const sizeErrorRef = useRef<HTMLSpanElement>(null);

  const user = useUser();

  const currentScreenWidth = window.innerWidth;

  useEffect(() => {
    if (isOpen) {
      setRenderModal(true);
    } else {
      setTimeout(() => {
        setRenderModal(false);
      }, 275);
    }
  }, [isOpen]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);

    const load = async () => {
      try {
        if (!id) {
          navigate('/not-found');
          return;
        }

        const { data } = await AxiosBase.get(`/products/info/${id}`);

        setMainImage(data?.images[0]);
        setProduct(data);

        setSeo({
          title: data?.title ?? '',
          route: '/product/' + data.id,
        });

        if (user) {
          const savedInfo = await WishlistService.isSaved(Number(id));

          if (savedInfo.ok) {
            setIsSaved(savedInfo.data.saved);
          }
        }
      } catch {
        navigate('/not-found');
      }
    };

    load();
  }, [id, navigate, user]);

  useEffect(() => {
    (async () => {
      if (product?.id !== null && product?.id !== undefined) {
        await ProductService.view(product.id);
      }
    })();
  }, [product]);

  useLayoutEffect(() => {
    const model = products.find((x) => x.productCartId === `${product?.id}-${size?.id}`);
    setIsInCard(model !== undefined);
  }, [product, products, size]);

  useLayoutEffect(() => {
    const getRelated = async () => {
      const count = window.innerWidth < 1180 ? 4 : 5;

      let url = `${Environment.api}/products/related?category=${product?.category}&gender=${product?.gender}`;
      url += `&take=${count}&title=${product?.title}&brand=${product?.brand}`;

      const { data } = await AxiosAuthorized.get(url);
      setRelated(data);
    };

    if (product) {
      getRelated();
    }
  }, [product]);

  useLayoutEffect(() => {
    const getVisited = async () => {
      const count = window.innerWidth < 1180 ? 4 : 5;
      const { data } = await ProductService.recentlyVisited(count, product?.id ?? -1);
      setVisited(data);
    };

    if (product && user) {
      getVisited();
    }
  }, [product, user]);

  const saveToCart = async () => {
    if (product === null) {
      return;
    }

    if (size === null) {
      modalError(sizeErrorRef, Constants.sizeMustBeSelected);
      return;
    }

    let sizeModel = { ...size };
    sizeModel.count = count;

    setSize(size);

    const item: Partial<ISavedProduct> = {
      id: Number(product?.id),
      title: product?.title,
      size: sizeModel as ISize,
      image: product?.images[0]?.replace('w_700', 'w_150'),
      price: Number(product?.price),
      code: product.code,
      isNew: product.isNew,
      category: product.category,
      discount: product.discount,
    };

    dispatch(addToCart(item));

    setIsInCard(true);

    setTimeout(() => {
      setIsInCard(false);
      setCount(1);
      setSize(null);
    }, 1500);

    await ProductService.saveToCart(product?.id);
  };

  const saveToWishlist = async () => {
    if (!user) {
      let title = product?.title.replaceAll(' ', '-');
      navigate(
        `/sign-in?fallbackUrl=/product/${id}?title=${title}&saveToWishlist=1&productId=${id}`,
      );
    }

    const productId = Number(id);
    await WishlistService.save(productId);
    setIsSaved(true);
  };

  const removeFromWishlist = async () => {
    const productId = Number(id);
    await WishlistService.remove(productId);
    setIsSaved(false);
  };

  return (
    <>
      {!isNullOrWhiteSpace(mainImage) && renderModal && (
        <OpenImage
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          url={mainImage.replace('w_700', 'w_' + currentScreenWidth)}
        />
      )}
      <MetaTags data={seo} />
      <div className='product-wrapper'>
        {currentScreenWidth <= 980 && (
          <Swiper
            spaceBetween={0}
            centeredSlides={true}
            loop={false}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination]}
            className='product-swiper'
          >
            {product?.images.map((x, i) => (
              <SwiperSlide key={i}>
                <div
                  style={{
                    backgroundImage: `url(${x.replace('w_700', 'w_' + currentScreenWidth)})`,
                  }}
                  key={i}
                  className='product-img-mobile'
                  onClick={() => {
                    setIsOpen(true);
                    setMainImage(x);
                  }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        <div className='data-wrapper'>
          <div className='product-images'>
            <div className='images-list'>
              {product?.images &&
                product?.images.length > 1 &&
                currentScreenWidth > 500 &&
                product?.images.map((x, index) => (
                  <img
                    src={x.replace('w_700', 'w_200')}
                    alt={x}
                    key={index}
                    onClick={() => setMainImage(x)}
                    className={`mapped-image ${mainImage === x ? 'selected-image' : ''}`}
                  />
                ))}
            </div>
            {mainImage && (
              <img
                src={mainImage}
                alt={product?.title}
                className='main-image'
                onClick={() => setIsOpen(true)}
              />
            )}
          </div>
          <div className='product-data'>
            <h1 className='title'>{product?.title}</h1>
            <div className='tags-for-product'>
              {product?.isNew && (
                <div className='new-product-block'>
                  <span>NOVO</span>
                </div>
              )}
              {product?.discount !== null && product?.discount !== 0 && product !== null && (
                <div className='new-product-block discounted-product product-data-blck'>
                  <span>-{product.discount}%</span>
                </div>
              )}
            </div>
            <p>Šifra proizvoda: {product?.code}</p>
            <div className='prices-list'>
              {product?.discount !== null && product?.discount !== 0 && product !== null && (
                <h3>
                  <s>{formatPrice(Number(product.price))}</s>
                </h3>
              )}
              {product && (
                <h1 className='price'>
                  {formatPrice(
                    Number(product.price - product.price * ((product.discount ?? 0) / 100)),
                  )}
                </h1>
              )}
              {product?.discount !== null && product?.discount !== 0 && product !== null && (
                <h4 style={{ color: '#fa0a32' }}>
                  Ušteda:&nbsp;
                  {formatPrice(
                    Number(product!.price) -
                      Number(product!.price - product!.price * ((product!.discount ?? 0) / 100)),
                  )}
                </h4>
              )}
            </div>
            <h4>VELIČINE</h4>
            <div className='size-mapping'>
              {product?.sizes.map((x) => (
                <div
                  className={`size ${x.id === size?.id ? 'selected-size' : 'unselected-size'}`}
                  key={x.id}
                  onClick={() => {
                    if (x.id === size?.id) {
                      setSize(null);
                    } else {
                      setCount(1);
                      let model = { ...x };
                      model.count = count;
                      setSize(model);
                    }
                  }}
                >
                  <span>{x.size}</span>
                </div>
              ))}
            </div>
            <span ref={sizeErrorRef} className='modal-error'></span>
            <div className='cart-adder'>
              <h4 className='title-marg'>KOLIČINA</h4>
              <div className='cart-block'>
                <div className='cart-options'>
                  <button
                    onClick={() => {
                      const sizeModel = product?.sizes.find((x) => x.size === size?.size);

                      if (size !== null && sizeModel!.count >= count + 1) {
                        setCount(count + 1);

                        const model = { ...size };
                        model.count = count;

                        setSize(model);
                      }
                    }}
                  >
                    +
                  </button>
                  <div>
                    <h3>{count}</h3>
                  </div>
                  <button
                    onClick={() => {
                      if (count > 1) {
                        setCount(count - 1);
                      }
                    }}
                  >
                    -
                  </button>
                </div>
                <div className='product-button-options'>
                  <button className='add-to-cart' onClick={saveToCart}>
                    {isInCard ? (
                      'PROIZVOD JE DODAT U KORPU'
                    ) : (
                      <>
                        <BiCartAdd className='add-to-cart-icon' />
                        DODAJ U KORPU
                      </>
                    )}
                  </button>
                  <button
                    className='save-product-btn'
                    onClick={() => {
                      if (isSaved) {
                        removeFromWishlist();
                      } else {
                        saveToWishlist();
                      }
                    }}
                  >
                    {!isSaved ? <AiOutlineHeart /> : <AiFillHeart />}
                  </button>
                </div>
              </div>
            </div>
            <h4 className='title-marg'>MATERIJAL</h4>
            <table className='specifications'>
              <tbody>
                <tr>
                  <td>Unutrašnjost</td>
                  <td>{product?.interior}</td>
                </tr>
                <tr>
                  <td>Lice</td>
                  <td>{product?.interior}</td>
                </tr>
                <tr>
                  <td>Đon</td>
                  <td>{product?.sole}</td>
                </tr>
              </tbody>
            </table>
            <h4 className='title-marg'>TAGOVI</h4>
            <table className='specifications'>
              <tbody>
                <tr>
                  <td>Brend</td>
                  <td>{product?.brand}</td>
                </tr>
                <tr>
                  <td>Kategorija</td>
                  <td>{product?.category}</td>
                </tr>
                <tr>
                  <td>Pol</td>
                  <td>{genders.find((x) => x.id === Number(product?.gender ?? 0))?.name}</td>
                </tr>
                <tr>
                  <td>Sezona</td>
                  <td>{product?.season}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {related.length > 0 && (
          <div className='related-wrapper'>
            <h1 className='related-title'>SLIČNI PROIZVODI</h1>
            <div
              className={`related-products-mapping ${
                visited.length > 0 ? '' : ' products-bottom-margin'
              }`}
            >
              {related.map((x) => (
                <ProductListItem product={x} key={x.id} />
              ))}
            </div>
          </div>
        )}
        {visited.length > 0 && (
          <div className='related-wrapper recently-viewd'>
            <h1 className='related-title'>NEDAVNO POSEĆENO</h1>
            <div className='related-products-mapping products-bottom-margin'>
              {visited.map((x) => (
                <ProductListItem product={x} key={x.id} />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Product;
