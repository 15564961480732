import React, { useState, useEffect } from 'react';
import { ISavedProduct } from '../../../models/product-base';
import { ISize } from '../../../models/product';
import { increaseProductCount, removeFromCart } from '../../../redux/reducers/shoppingCartReducer';
import { useDispatch } from 'react-redux';
import { formatPrice } from '../../../helpers/uiHelper';
import { AxiosBase } from '../../../common/axios';
import OpenImage from '../../Html/OpenImage';

interface IComponentProps {
  product: ISavedProduct;
}

const CartItemMobile: React.FC<IComponentProps> = ({ product }) => {
  const [size, setSize] = useState<ISize | null>(null);
  const [count, setCount] = useState<number>(product.size.count);
  const [openImage, setOpenImage] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const load = async () => {
      const { data } = await AxiosBase.get(`/products/info/${product.id}`);
      const id = `${product?.id}-${product.size?.id}`;

      if (data === null || data?.available === false) {
        dispatch(removeFromCart(id));
      }
      const sizeData = data.sizes.find((x: any) => x.id === product.size.id);

      if (sizeData === null) {
        dispatch(removeFromCart(id));
      }

      setSize(sizeData);
    };

    load();
  }, [product, dispatch]);

  const increase = () => {
    if (size !== null && size?.count > count) {
      let value = count + 1;
      setCount(value);

      let productItem = { ...product };
      productItem.size = { ...productItem.size, count: value };

      dispatch(increaseProductCount(productItem));
    }
  };

  const decrease = () => {
    if (count > 1) {
      let value = count - 1;
      setCount(value);

      let productItem = { ...product };
      productItem.size = { ...productItem.size, count: value };

      dispatch(increaseProductCount(productItem));
    }
  };
  return (
    <>
      <OpenImage url={product.image} setIsOpen={setOpenImage} isOpen={openImage} />
      <article className='cart-article-mobile'>
        <div className='block-data-mobile padding-bottom-mobile'>
          <div className='cart-image-wrapper'>
            <img
              src={product.image}
              alt={product.title}
              decoding='async'
              loading='lazy'
              onClick={() => setOpenImage(true)}
            />
            <div className='main-checkout-details'>
              <h1 className='checkout-title'>{product.title}</h1>
              {product.discount !== null && product.discount !== 0 && (
                <div className='checkout-discount'>
                  <span>-{product.discount}%</span>
                </div>
              )}
              <p className='checkout-code'>Šifra proizvoda: {product.code}</p>
            </div>
          </div>
          <div className='data-block tablet-view'>
            <h3>VELIČINA</h3>
            <span className='table-size'>{product.size.size}</span>
          </div>
          <div className='data-block tablet-view'>
            <h3>KOLIČINA</h3>
            <div className='cart-block-2'>
              <div className='cart-options-2'>
                <button onClick={() => increase()}>+</button>
                <div>
                  <h3>{count}</h3>
                </div>
                <button onClick={() => decrease()}>-</button>
              </div>
            </div>
          </div>
          <div className='data-block tablet-view'>
            <h3>&nbsp;</h3>
            <button
              className='remove-btn'
              onClick={() => {
                const id = `${product?.id}-${product.size?.id}`;
                setCount(0);
                dispatch(removeFromCart(id));
              }}
            >
              UKLONI
            </button>
          </div>
        </div>
        <div className='block-data-mobile mobile-view mobile-padding'>
          <div className='data-block'>
            <h3>KOLIČINA</h3>
            <div className='cart-block-2'>
              <div className='cart-options-2'>
                <button onClick={() => increase()}>+</button>
                <div>
                  <h3>{count}</h3>
                </div>
                <button onClick={() => decrease()}>-</button>
              </div>
            </div>
          </div>
          <div className='data-block'>
            <h3>VELIČINA</h3>
            <span className='table-size'>{product.size.size}</span>
          </div>
          <div className='data-block'>
            <h3>&nbsp;</h3>
            <button
              className='remove-btn'
              onClick={() => {
                const id = `${product?.id}-${product.size?.id}`;
                setCount(0);
                dispatch(removeFromCart(id));
              }}
            >
              UKLONI
            </button>
          </div>
        </div>
        <div className='block-data-mobile cart-bottom-data'>
          <div className='data-block'>
            <h3>CENA</h3>
            {product.discount !== null && (
              <h3 className='regular-price'>
                <s>{formatPrice(product.price)}</s>
              </h3>
            )}
            <h1>{formatPrice(product.price - product.price * ((product.discount ?? 0) / 100))}</h1>
          </div>
          <div className='data-block'>
            <h3>UKUPNO</h3>
            <h1>
              {formatPrice(
                product.price * count - product.price * count * ((product.discount ?? 0) / 100),
              )}
            </h1>
          </div>
        </div>
      </article>
    </>
  );
};

export default CartItemMobile;
