import { useEffect, useRef } from "react";

const useBurgerMenu = (open: boolean) => {
  const burgerMenuRef = useRef<HTMLDivElement>(null);
  const burgerMenu = burgerMenuRef.current;
  let timeout: NodeJS.Timeout;

  useEffect(() => {
    if (burgerMenu === null) return;

    if (open) {
      burgerMenu?.classList.add("open-burger-anim");
      burgerMenu!.style.display = "initial";
    } else {
      burgerMenu?.classList.add("close-burger-anim");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    timeout = setTimeout(() => {
      burgerMenu?.classList.remove("open-burger-anim", "close-burger-anim");
      if (!open) burgerMenu!.style.display = "none";
    }, 300);

    return () => clearTimeout(timeout);
  }, [open]);

  return burgerMenuRef;
};

export default useBurgerMenu;
