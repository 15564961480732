import IResponse from "../models/response";
import { AxiosAuthorized } from "../common/axios";

export default class StatisticsService {
  private static serviceEndpoint: string = `/statistics`;

  public static async cloudinary(): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/cloudinary`;

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async cloudinaryStatus(): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/cloudinary-status`;

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }
}