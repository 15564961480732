import React from 'react';
import RangeSliderInput from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

interface IComponentProps {
  min: number;
  max: number;
  setPriceRange: React.SetStateAction<React.Dispatch<[number, number]>>;
  setPriceRangeValue: React.Dispatch<React.SetStateAction<[number, number] | null>>;
  priceRange: [number, number];
  setDeleteCurrentPage: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RangeSlider: React.FC<IComponentProps> = ({
  min,
  max,
  setPriceRange,
  setPriceRangeValue,
  setDeleteCurrentPage,
  priceRange,
}) => {
  const handleRangeChange = (newValues: any) => {
    setPriceRange(newValues);
  };

  return (
    <RangeSliderInput
      min={min}
      max={max}
      setp={100}
      value={priceRange}
      className='price-slider'
      onInput={handleRangeChange}
      onThumbDragEnd={() => {
        sessionStorage.setItem('searchCriteria_store', 'price_range');
        setPriceRangeValue(priceRange);
        setDeleteCurrentPage(true);
      }}
      onRangeDragEnd={() => {
        sessionStorage.setItem('searchCriteria_store', 'price_range');
        setPriceRangeValue(priceRange);
        setDeleteCurrentPage(true);
      }}
    />
  );
};
