/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useRef, useEffect } from 'react';
import { IAdminBackofficeSearchContext } from '../../models/context';
import { SettingsContext } from '../../pages/admin/settings/Settings';
import Modal from '../Html/Modal';
import FormGroup from '../Html/FormGroup';
import Input from '../Html/Input';
import Select from '../Html/Select';
import { SettingDataTypeMapping } from '../../common/data';
import { SettingDataType } from '../../common/enums';
import Notification from '../Html/Notification';
import { modalErrorInput } from '../../helpers/uiHelper';
import { isNullOrEmpty } from '../../utils/strings';
import Constants from '../../common/constants';
import SettingsService from '../../services/settings.service';
import { ISettings } from '../../models/settings';

interface IComponentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getSettings: () => Promise<void>;
  setting: ISettings;
}

const EditSettingModal: React.FC<IComponentProps> = ({
  isOpen,
  setIsOpen,
  getSettings,
  setting,
}) => {
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [settingType, setSettingType] = useState<SettingDataType>(setting.dataType);
  const [settingValue, setSettingValue] = useState<string | number | boolean>(setting.value);
  const [enableDeleting, setEnableDeleting] = useState<boolean>(setting.enableDeleting);

  let inputs = document.querySelectorAll<HTMLInputElement>('.edit-setting-input-' + setting.id);

  const nameRef = useRef<HTMLInputElement>(null);
  const valueRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      inputs = document.querySelectorAll<HTMLInputElement>('.edit-setting-input-' + setting.id);
      nameRef!.current!.value = setting.name;

      if (valueRef !== null && valueRef?.current !== null) {
        valueRef!.current!.value = setting.value;
      }

      setSettingType(setting.dataType);
      setEnableDeleting(setting.enableDeleting);
    }
  }, [isOpen]);

  const RenderValueInput: any = {};

  RenderValueInput[SettingDataType.String] = (
    <Input
      label='Vrednost'
      id='setting-value'
      elRef={valueRef}
      cssClasses={`edit-setting-input-${setting.id}`}
      onChange={(x) => setSettingValue(x.target.value)}
    />
  );

  RenderValueInput[SettingDataType.Number] = (
    <Input
      type='number'
      label='Vrednost'
      id='setting-value'
      elRef={valueRef}
      cssClasses={`edit-setting-input-${setting.id}`}
      onChange={(x) => setSettingValue(Number(x.target.value))}
    />
  );

  RenderValueInput[SettingDataType.Boolean] = (
    <Select
      label='Vrednost'
      defaultValue={settingValue ? 1 : 0}
      onChange={(x) => setSettingValue(Number(x.target.value) === 1)}
    >
      <option value={0}>Ne</option>
      <option value={1}>Da</option>
    </Select>
  );

  const { setDeleteCurrentPage, dispatch } =
    useContext<Partial<IAdminBackofficeSearchContext>>(SettingsContext);

  const saveSetting = async () => {
    let error = false;

    inputs.forEach((input) => {
      let spanModelError = document.getElementById(`error-${input.id}`) as HTMLSpanElement;

      if (input.id === 'setting-name' && isNullOrEmpty(input.value)) {
        modalErrorInput(spanModelError, Constants.fieldCannotBeEmpty);
        error = true;
      }

      if (input.id === 'setting-value' && settingType !== SettingDataType.Boolean) {
        if (settingType === SettingDataType.Number && isNaN(parseInt(input.value))) {
          modalErrorInput(spanModelError, Constants.fieldNotGoodFormat);
          error = true;
        }

        if (settingType === SettingDataType.String && isNullOrEmpty(input.value)) {
          modalErrorInput(spanModelError, Constants.fieldCannotBeEmpty);
          error = true;
        }
      }
    });

    if (error) return;

    const name = nameRef.current!.value;

    const reqModel = {
      id: setting.id,
      name: name,
      value: settingValue,
      enableDeleting: enableDeleting,
      dataType: settingType,
    };

    const { data, ok } = await SettingsService.edit(reqModel);

    if (ok) {
      setSettingValue('');
      setEnableDeleting(false);
      setSettingType(SettingDataType.String);
      nameRef.current!.value = '';

      if (valueRef !== null && valueRef?.current !== null) {
        valueRef.current!.value = '';
      }

      setIsOpen(false);
      setDeleteCurrentPage!(true);
      dispatch!({ type: 'reset' });
      await getSettings();
    }

    setError(!ok);
    setMessage(data?.message ?? '');

    setTimeout(() => {
      setError(false);
      setMessage('');
    }, 2000);
  };

  return (
    <Modal title='DODAJ PODEŠAVANJE' isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='modal-data-list'>
        <FormGroup type='no-wrapper'>
          <Input
            label='Naziv'
            elRef={nameRef}
            id='setting-name'
            cssClasses={`edit-setting-input-${setting.id}`}
          />
          <Select
            label='Tip vrednosti podešavanja'
            defaultValue={setting.dataType}
            onChange={(x) => {
              setSettingType(Number(x.target.value));

              if (Number(x.target.value) === SettingDataType.Boolean) {
                setSettingValue(false);
              }
            }}
          >
            {SettingDataTypeMapping.map((x) => (
              <option key={x.type} value={x.type}>
                {x.title}
              </option>
            ))}
          </Select>
          {RenderValueInput[settingType]}
          <Select
            label='Omogući brisanje ovog podešavanja'
            defaultValue={setting.enableDeleting ? 1 : 0}
            onChange={(x) => setEnableDeleting(Number(x.target.value) === 1)}
          >
            <option value={0}>Ne</option>
            <option value={1}>Da</option>
          </Select>
        </FormGroup>
        {!error ? (
          <button onClick={saveSetting}>SAČUVAJ</button>
        ) : (
          <div className='delete-acc-modal'>
            <Notification isOk={false} title={message} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditSettingModal;
