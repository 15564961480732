import Modal from '../Html/Modal';
import { useState } from 'react';
import IUser from '../../models/user';
import AccountService from '../../services/account.service';
import Select from '../Html/Select';
import FormGroup from '../Html/FormGroup';

interface IComponentProps {
  isVerifiedModalOpen: boolean;
  setIsVerifiedModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: IUser | null;
  getAll: () => Promise<void>;
}

const ChangeUserVerificationStatus = ({
  isVerifiedModalOpen,
  setIsVerifiedModalOpen,
  user,
  getAll,
}: IComponentProps) => {
  const [isVerified, setIsVerified] = useState<boolean>(user?.isVerified ?? false);

  const changeUserStatus = async (couponId: number, isVerified: boolean) => {
    await AccountService.changeVerificationStatus(couponId, isVerified);
    getAll();
    setIsVerifiedModalOpen(false);
  };

  return (
    <Modal
      isOpen={isVerifiedModalOpen}
      setIsOpen={setIsVerifiedModalOpen}
      title='PROMENI STATUS KUPONA'
    >
      <div className='modal-data-list'>
        <FormGroup type='no-wrapper'>
          <Select
            label='Status'
            defaultValue={isVerified === true ? 'true' : 'false'}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setIsVerified(e.target.value === 'true')
            }
          >
            <option value='true'>Verifikovan</option>
            <option value='false'>Nije verifikovan</option>
          </Select>
        </FormGroup>
        <button onClick={() => changeUserStatus(user?.id as number, isVerified)}>SAČUVAJ</button>
      </div>
    </Modal>
  );
};

export default ChangeUserVerificationStatus;
