import React, { useState, useEffect } from 'react';
import { MdAddShoppingCart } from 'react-icons/md';
import useUser from '../../../hooks/useUser';
import { UserRole } from '../../../common/enums';

interface IComponentProps {
  children: any;
}

const EmptyProducts: React.FC<IComponentProps> = ({ children }) => {
  const user = useUser();

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const timeout = !user || (user && user.roleId === UserRole.Buyer) ? 100 : 100;
    setTimeout(() => {
      setShow(true);
    }, timeout);
  }, [user]);

  return show ? (
    <div className='empty-shoppinh-cart'>
      <MdAddShoppingCart className='empty-icon' />
      <h1>NEMATE PROIZVODE</h1>
      {(!user || (user !== null && user.roleId === UserRole.Buyer)) && (
        <p>Trenutno nema proizvoda za prikazivanje. Molimo vas, pokušajte kasnije.</p>
      )}
      {user !== null && user.roleId === UserRole.Admin && (
        <p>
          Trenutno nemate ni jedan proizvod. Proizvode koje budete bili dodali biće prikazani ovde.
        </p>
      )}
      {user !== null && user.roleId === UserRole.Admin && <>{children}</>}
    </div>
  ) : (
    <></>
  );
};

export default EmptyProducts;
