import '../../styles/BurgerMenu.css';
import { FaTimes } from 'react-icons/fa';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNullOrWhiteSpace } from '../../utils/strings';
import { BsSearch } from 'react-icons/bs';

interface IComponentProps {
  setOpen: any;
  searchMenuRef: React.RefObject<HTMLDivElement>;
}

const SearcMobile = ({ setOpen, searchMenuRef }: IComponentProps) => {
  const [title, setTitle] = useState<string | null>(null);
  const navigate = useNavigate();

  return (
    <div ref={searchMenuRef} className='burger-menu'>
      <div className='burger-logo'>
        <FaTimes
          size={35}
          onClick={() => {
            setOpen(false);
            document.body.style.overflow = 'visible';
          }}
        />
      </div>
      <div className='search-block'>
        <input
          className='search-mobile-input'
          type='text'
          name='search'
          id='search'
          autoComplete='off'
          onChange={(e) => setTitle(e.target.value)}
          onKeyUp={(e) => {
            if (e.keyCode === 13 && !isNullOrWhiteSpace(title)) {
              const formatedTitle = title?.replaceAll(' ', '').toLowerCase();
              setOpen(false);
              document.body.style.overflow = 'auto';
              navigate('/store?title=' + formatedTitle);
            }
          }}
          placeholder='Pretraga proizvoda...'
        />
        <button
          className='search-btn-mobile'
          onClick={() => {
            const formatedTitle = title?.replaceAll(' ', '').toLowerCase();
            setOpen(false);
            document.body.style.overflow = 'auto';
            navigate('/store?title=' + formatedTitle);
          }}
        >
          <BsSearch />
        </button>
      </div>
    </div>
  );
};

export default SearcMobile;
