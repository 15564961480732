import React from 'react';

interface IComponentProps {
  label: string;
  children: React.ReactNode[];
  id?: string;
  elRef?: React.Ref<any>;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => any;
  defaultValue?: string | number | readonly string[] | undefined;
  hasNoValue?: boolean;
}

const Select: React.FC<IComponentProps> = ({
  label,
  id,
  elRef,
  onChange,
  defaultValue,
  children,
  hasNoValue,
}) => {
  return (
    <div className='input-element'>
      <label>{label}</label>
      <select
        ref={elRef}
        className='input-element full-width input-select'
        id='gender'
        onChange={onChange}
        defaultValue={defaultValue}
      >
        {hasNoValue && (
          <option label=' ' disabled selected style={{ display: 'none' }}>
            -- Izaberite --
          </option>
        )}
        {children}
      </select>
      <span className='modal-error' id={`error-${id}`}></span>
    </div>
  );
};

export default Select;
