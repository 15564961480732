import '../../../styles/Dashboard.css';
import { useContentWidth } from '../../../hooks/useContentWidth';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import CloudinaryStatistics from '../../../components/Statistics/CloudinaryStatistics';

const Dashboard = () => {
  useScrollOnLoad();
  useContentWidth();

  return (
    <section
      className='min-height-block dashboard admin-panel-width remove-btm-pd'
      id='admin-panel'
    > 
      <CloudinaryStatistics />
    </section>
  );
};

export default Dashboard;
