import React from 'react';

type Style = 'no-wrapper' | 'w-25' | 'w-50' | 'w-100' | 'column';

interface IComponentProps {
  children: React.ReactNode[] | React.ReactNode;
  type?: Style;
}

const FormGroup: React.FC<IComponentProps> = ({ children, type = 'w-25' }) => {
  const cssClass: any = {
    'w-25': 'admin-content-wrapper',
    'w-50': 'admin-content-wrapper content-wrapper-50',
    'w-100': 'admin-content-wrapper content-wrapper-100',
    'column': 'admin-column',
  };

  return <div className={`content-wrapper ${cssClass[type] ?? ''}`}>{children}</div>;
};

export default FormGroup;
