import { ISortModel } from "../models/search";
import IResponse from "../models/response";
import { IWebImagesSearchCriteria } from "../models/search-criteria";
import { URLQueries } from "../utils/url-queries";
import { AxiosAuthorized, AxiosBase } from "../common/axios";

export default class WebImagesService {
  private static serviceEndpoint: string = `/web-images`;

  public static async totalCount() {
    try {
      const api = `${this.serviceEndpoint}/total-count`;

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async uiList(screenWidth: number): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/ui-list?screenWidth=${screenWidth}`;

      const { data } = await AxiosBase.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async filter(sp: IWebImagesSearchCriteria, sort: ISortModel, resultsPerPage: number, currentPage: number): Promise<IResponse> {
    try {
      let api = `${this.serviceEndpoint}/filter?sortBy=${sort.sortBy}&sortDirection=${sort.sortDirection}&resultsPerPage=${resultsPerPage}&pageNumber=${currentPage}`;

      api = URLQueries.addList(sp, api, []);

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async add(req: any): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/`;

      const { data } = await AxiosAuthorized.post(api, req);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async edit(reqData: any): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/${reqData.id}`;

      const { data } = await AxiosAuthorized.put(api, reqData);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async delete(id: any): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/${id}`;

      const { data } = await AxiosAuthorized.delete(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async upload(formData: FormData, productId: number) {
    try {
      const api = `${this.serviceEndpoint}/upload/${productId}`;

      const { data } = await AxiosAuthorized.post(api, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }
}
