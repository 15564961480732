import React, { useContext } from 'react';
import { IconType } from 'react-icons/lib';
import { NavLink } from 'react-router-dom';
import { IAdminMobileNavigationContext } from '../../models/context';
import { AdminNavigationContext } from './AdminUI';

interface IComponentProps {
  to: string;
  title: string;
  Icon: IconType;
}

const BurgerLink: React.FC<IComponentProps> = ({ to, Icon, title }) => {
  const isActive = ({ isActive }: any) => (isActive ? 'active-link' : '');

  const { setOpen } = useContext<IAdminMobileNavigationContext>(AdminNavigationContext);

  const openLink = () => {
    setOpen(false);
    document.body.style.overflow = 'visible';
  };

  return (
    <div className='admin-links-section'>
      <NavLink to={to} className={isActive} onClick={openLink}>
        <div className='admin-headline first-headline'>
          <Icon className='admin-icon' />
          <h1 className='sidebar-title'>{title}</h1>
        </div>
      </NavLink>
    </div>
  );
};

export default BurgerLink;
