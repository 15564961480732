import React from 'react';

interface IComponentProps {
  children: React.ReactNode[] | React.ReactNode;
}

const FormGroupInline: React.FC<IComponentProps> = ({ children }) => {
  return <div className='wrap-checkout-elements'>{children}</div>;
};

export default FormGroupInline;
