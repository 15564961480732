import { useScrollOnLoad } from '../../hooks/useScrollOnLoad';
import '../../styles/PrivacyAndPolicy.css';

const PrivacyAndPolicy = () => {
  useScrollOnLoad();

  return (
    <div className='privacy-and-policy-page'>
      <h1>Politika privatnosti</h1>
      <div className='privacy-text'>
        <p>Poslednja izmena: 1. oktobar 2023. godine</p>
        <p>
          <b>"Obuća BIG“</b> ili <b>„mi“</b> označava privredno društvo BIG, matični broj 52084253,
          PIB: 100688458, sa sedištem na adresi Bulevar Nemanjića 25 A Niš.
        </p>
        <p>
          Vaša privatnost je od izuzetne važnosti za nas. Ova Polisa privatnosti opisuje kako
          prikupljamo, koristimo i štitimo vaše lične podatke kada koristite našu e-trgovinu za
          obuću.
        </p>
        <p>
          Ovi opšti uslovi korišćenja (<b>„Opšti uslovi“</b>) primenjuju se na Internet Sajtovima i
          imaju za svrhu da regulišu pristupanje i korišćenje Internet Sajtova od strane korisnika.
        </p>
        <p>
          Pojam <b>„Korišćenje“</b> znači sve operacije koje vrši korisnik nakon pristupanja
          Internet Sajtu, uključujući jednostavan pregled, bez obzira na sredstvo pristupa (npr.
          kompjuter, tablet ili pametni telefon), tip konekcije (privatna konekcija, konekcija
          trećeg lica ili javni wi-fi) i mesto konekcije.
        </p>
        <p>
          Korišćenje Internet Sajtova regulisano je ovim Opštim uslovima i uslovljeno je Vašom
          saglasnošću sa ovim Opštim uslovima.
        </p>
        <p>
          Svim ovim odredbama, uslovima, politikama i pravila možete pristupiti preko Internet Sajta
          u bilo kom trenutku. Oni mogu biti izmenjeni i dopunjeni, ali primenjuju se oni koji su na
          snazi na Internet Sajtu na datum kada Vi koristite Internet Sajt.
        </p>
        <b className='privacy-paragraph'>1. Funkcionalnosti Internet Sajta</b>
        <p>Na Internet Sajtu možete da:</p>
        <ul>
          <li>
            pretražite proizvode i usluge koje nudimo, sačuvate željene proizvode u korpu ili listu
            želja (samo registrovani korisnici mogu sačuvati proizvod u listu želja, proizvod će
            biti uklonjen iz iste u roku od 15 dana.)
          </li>
          <li>
            vršite online kupovinu proizvoda koji će biti isporučen na naznačenu adresu dostave.
            Prilikom naručivanja proizvoda, kupac će dobiti obaveštenje putem mejla da je uspešno
            poručio proizvod/e. Prilikom promene status porudžbine kupac će biti obavešten putem
            mejla.
            <p>Mogući su sledeći status porudžbine:</p>
            <ul>
              <li>
                <b>Obrada</b> - tek naručen proizvodi
              </li>
              <li>
                <b>Dostavljanje</b> - proizvodi su predati kurirskoj službi koja će ih isporučiti na
                naznačenu adresu dostave
              </li>
              <li>
                <b>Završeno</b> - proizvodi su uspešno isporučeni korisniku i korisnik je izvršio
                plaćanje kuriru
              </li>
              <li>
                <b>Otkazano</b> - porudžbina je otkazana
              </li>
            </ul>
          </li>
          <li>
            postavljate pitanja o proizvodima koje Mi prodajemo i upućujete druge vrste upita putem
            kontaktne forme u vidu interaktivnog formulara, e-maila ili putem telefona
          </li>
        </ul>
        <p>
          Ova lista funkcionalnosti je informativnog karaktera i zadržavamo pravo da dodamo,
          izmenimo ili uklonimo pojedine funkcionalnosti, privremeno ili stalno, bez potrebe da
          tražimo Vašu saglasnost.
        </p>

        <b className='privacy-paragraph'>2. Nedozvoljena ponašanja na Internet Sajtu</b>
        <p>
          Vi se obavezujete da korisite Internet Sajt i sve što je dostupno na Internet Sajtu ili
          putem Internet Sajta isključivo u zakonom dozvoljene svrhe i u skladu sa primenljivim
          zakonima i propisima, na odgovoran način i bez nanošenja štete Našem poslovnom ugledu,
          poslovnom ugledu sa nama povezanih pravnih lica i trećim licima.
        </p>
        <p>Naročito se obavezujete da:</p>
        <ul>
          <li>
            ne preuzimate, čuvate ili koristite bilo koji sadržaj koji pripada trećim licima i
            podleže pravima intelektualne svojine ili pravima zaštite podataka o ličnosti
          </li>
          <li>
            ne sakupljate i skladištite sadržaj Internet Sajta na nekom serveru ili drugom umreženom
            uređaju za skladištenje ili stvarate elektronsku bazu podataka tako što ćete sistematski
            preuzimati i skladištiti sadržinu Internet Sajta
          </li>
          <li>
            ne brišete ili menjate bilo koji sadržaj na Internet Sajtu ili pokušavate da zaobiđete
            bezbednost, ili ometate funkcionisanje Internet Sajta ili servera na kojima se čuva
          </li>
          <li>
            neovlašćeno ne pristupate Internet Sajtu, serveru na kom se nalazi ili bilo kom serveru,
            računaru ili bazi podataka koji su povezani sa Internet Sajtom, odnosno napadate
            Internet Sajt putem bilo koje aktivnosti koja za cilj ima onemogućavanje ili otežavanje
            rada Internet Sajta.
          </li>
          <li>
            ne kreirate linkove ka Internet Sajtu sa bilo koje druge internet lokacije bez Naše
            prethodne pisane saglasnosti, s tim da možete objaviti link ka Internet Sajtu na
            internet sajtu kojim Vi upravljate, pod uslovom da link nije obmanjujući i da Vi tačno
            navedete njegovu destinaciju i činjenicu da Mi nismo odobrili ili podržali taj link, Vaš
            internet sajt ili proizvode i usluge koje Vi nudite, i to tako da linkujete početnu
            stranicu našeg Internet Sajta (ne i da je frame-ujete ili kopirate) i pod uslovom da
            internet sajt povezan tim linkom ne sadrži nikakve nezakonite, preteće, uvredljive,
            klevetničke, pornografske, opscene, vulgarne, nedolične ili nasilne sadržaje, ili
            sadržaje koji mogu povrediti prava intelektualne svojine ili druga prava trećih lica
          </li>
          <li>
            ne skladištite, distribuirate ili objavljujete bilo kakav sadržaj koji je nezakonit,
            štetan, uvredljiv, rasistički, revizionistički, nemoralan, koji podstiče mržnju, ili
            koji može predstavljati napad na privatnost ili povredu ljudskih prava trećih lica,
            naročito prava intelektualne svojine ili prava na privatnost trećih lica
          </li>
          <p>
            ne kreirate lažne ili obmanjujuće porudžbine, a ako se može razumno proceniti da je
            izvršena porudžbina ove vrste, bićemo ovlašćeni da je otkažemo i obavestimo nadležne
            organe
          </p>
        </ul>

        <b className='privacy-paragraph'>3. Prikupljanje podataka</b>
        <p>Korisnički nalog - sistem jedinstvenog prijavljivanja</p>
        <p>
          Kako biste koristili neke funkcionalnosti Internet Prodavnica, morate registrovati
          korisnički nalog, osim ukoliko je Opštim uslovima predviđeno da se mogu koristiti i bez
          registracije ili prijavljivanja (npr. Online kupovina).
        </p>
        <p>Registracijom korisničkog naloga korisnici stiču mogućnost da:</p>
        <ul>
          <li>
            jednostavno i brzo vrše porudžbine na Internet Prodavnici koristeći prethodno unete
            kontakt podatke i podatke o adresi za isporuku
          </li>
          <li>
            kontrolišu i upravljaju svojim ličnim podacima, kao što su identifikacioni podaci (ime i
            prezime, e-mail adresa i lozinka, broj telefona), podaci o adresi (grad, okrug,
            poštanski broj, ulica i kućni broj, stan/kuća)
          </li>
          <li>imaju kontrolu nad svojim korisničkim nalogom, kao i da ga u celosti obrišu</li>
          <li>
            vrše uvid i eventualne obrade svih porudžbina, kao i statusa porudžbina, od momenta
            kreiranja, obrade, do isporuke
          </li>
          <li>
            prate proizvode koje su na Internet Prodavnicama označili da im se dopadaju, uključujući
            i promene cena i dostupnost veličina
          </li>
          <li>vrše uvid u proizvode koje su poslednje razgledali na Internet Prodavnici</li>
        </ul>
        <p>
          Kreiranjem korisničkog naloga Vi pristajete da se na Vas primenjuju i odredbe ovog odeljka
          VI ovih Opštih uslova.
        </p>
        <p>
          Obavezujete se da date tačne, potpune i pouzdane informacije prilikom registracije i
          upravljanja korisničkim nalogom, što nama omogućava da se usaglasimo sa svim našim
          obavezama.
        </p>
        <p>
          Molimo da imate u vidu da ste u obavezi da kontinuirano ažurirate vaše podatke ukoliko se
          promene na bilo koji način. Ovo je neophodno radi pravilnog ispunjavanja naših i Vaših
          obaveza. Vi snosite isključivu odgovornost za tačnost unetih podataka, kao i ukoliko neka
          naša obaveza nije ispunjenja ili nije potpuno ili ispravno ispunjena, zbog neažurnosti
          podataka. Obuća BIG nema pristup Vašem korisničkom nalogu, odnosno ne može vršiti izmenu
          unetih podataka.
        </p>
        <p>
          E-mail adresa, korisničko ime i broj telefona predstavljaju jedinstveni identifikator
          korisničkog naloga, a lozinka parametar za autentifikaciju i zaštitu korisničkog naloga.
          Autentifikacija se vrši pomoću korisničkog imena i lozinke.
        </p>
        <p>
          Verifikacija e-mail adrese je obavezan korak u cilju kompletranja procesa registracije
          korisničkog naloga.
        </p>
        <p>
          Mi zadržavamo pravo da ne potvrdimo, suspendujemo ili obrišemo bilo koje podatke za
          prijavljivanje i/ili zabranimo pristup korisničkom nalogu, u bilo koje vreme, ukoliko
          smatramo da su informacije koje ste nam dali u netačne ili da pripadaju ili se odnose na
          drugo lice.
        </p>

        <b className='privacy-paragraph'>4. Prikupljanje podataka</b>
        <p>Prilikom posete našoj e-trgovini, možemo prikupiti sledeće informacije:</p>
        <ul>
          <li>
            <p>Ime i prezime</p>
          </li>
          <li>
            <p>Adresa e-pošte</p>
          </li>
          <li>
            <p>Broj telefona</p>
          </li>
          <li>
            <p>Adresa dostave (okrug, grad, ulice, poštanski broj)</p>
          </li>
          <li>
            <p>Informacije o proizvodima koje ste pregledali ili kupili</p>
          </li>
        </ul>

        <b className='privacy-paragraph'>5. Upotreba podataka</b>
        <p>Vaše lične podatke koristimo kako bismo:</p>
        <ul>
          <li>
            <p>Obezbedili i dostavili naručene proizvode</p>
          </li>
          <li>
            <p>Kontaktirali vas u vezi sa statusom vaše narudžbine</p>
          </li>
          <li>
            <p>Prilagodili sadržaj i ponude u skladu sa vašim interesovanjima</p>
          </li>
          <li>
            <p>Analizirali kako koristite našu e-trgovinu i poboljšali je</p>
          </li>
          <li>
            <p>Obezbedili podršku korisnicima i rešili pritužbe</p>
          </li>
        </ul>

        <b className='privacy-paragraph'>6. Deljenje podataka</b>
        <p>Vaše lične podatke možemo deliti sa određenim trećim licima radi:</p>
        <ul>
          <li>
            <p>Isporuke i dostave naručenih proizvoda</p>
          </li>
          <li>
            <p>Analize podataka radi unapređenja naše e-trgovine</p>
          </li>
          <li>
            <p>Pravne obaveze i zaštite naših prava</p>
          </li>
        </ul>
        <p>
          Vaši lični podaci neće biti prodavani ili ustupani trećim licima u komercijalne svrhe bez
          vaše saglasnosti.
        </p>

        <b className='privacy-paragraph'>7. Bezbednost podataka</b>
        <p>
          Vaši lični podaci su zaštićeni tehničkim i organizacionim merama kako bi se sprečilo
          neovlašćeno pristupanje, gubljenje ili krađa podataka.
        </p>

        <b className='privacy-paragraph'>8. Prava korisnika</b>
        <p>
          Imate pravo da pristupite, ispravite ili obrišete svoje lične podatke koje smo prikupili.
          Prilikom brisanja korisničkog naloga, u slučaju da korisnik ima jednu ili više porudžbina,
          podaci vezani za njih neće biti izbrisani. To se pre svega odnosi na sledeće podatke: ime,
          prezime, e-mail, broj telefona kao i podaci o adresi dostave (okrug, grad, ulica,
          poštanski broj). Za sve zahteve i pitanja u vezi sa vašim ličnim podacima, možete nas
          kontaktirati putem adrese e-pošte&nbsp;
          <a href='mailto:obucabig@gmail.com' style={{ color: '#000' }}>
            <b>obucabig@gmail.com</b>
          </a>
          .
        </p>

        <b className='privacy-paragraph'>9. Pravila objavljivanja na Internet Sajtu</b>
        <p>
          Iako se trudimo da sve informacije sadržane na Internet Sajtu (osim sadržaja koji generiše
          Korisnik prilikom kreiranja naloga i poručivanja) budu tačne, potpune i precizne, ne
          dajemo garancije da je to zaista i slučaj. Mi ne garantujemo tačnost, kompletnost,
          pouzdanost, kvalitet ili originalnost bilo kog sadržaja na Internet Sajtu, te se
          isključuje, u najvećoj meri dozvoljenoj zakonom, bilo koja Naša odgovornost za bilo kakav
          gubitak ili štetu bilo koje prirode, koji nastanu kao rezultat Vašeg ili nečijeg drugog
          korišćenja Internet Sajta ili oslanjanja na bilo koji njegov sadržaj.
        </p>
        <p>
          Mi možemo s vremena na vreme promeniti format i sadržaj Internet Sajta. Vi ste saglasni da
          koristite Internet Sajt „u viđenom stanju“ i „kako je dostupan“ na Vaš sopstveni rizik.
        </p>
        <p>
          Vi ste odgovorni da obezbedite odgovarajuće mere sigurnosti (uključujući anti-virus
          softver i drugu bezbednosnu meru) kako bi zadovoljili Vaše konkretne zahteve u pogledu
          bezbednosti i pouzdanosti sadržaja.
        </p>
        <b className='privacy-paragraph'>10. Dostava i Plaćanje</b>
        <ul>
          <li>
            <p>Besplatna dostava za iznos preko 6000 RSD</p>
          </li>
          <li>
            <p>
              mogućavamo vam jednostavno plaćanje gotovinom pri isporuci. Naši kuriri će vam doneti
              vašu porudžbinu na naznačenu adresu.
            </p>
          </li>
          <li>
            <p>
              Imajte na umu da su sve cene prikazane na našoj e-trgovini uključujući PDV (porez na
              dodatu vrednost).
            </p>
          </li>
          <li>
            <p>Vršimo isporuku isključivo na teritoriji Republike Srbije.</p>
          </li>
          <li>
            <p>
              Nakon što potvrdite svoju narudžbinu, otkazivanje isporuke nije moguće. Molimo vas da
              pažljivo pregledate svoju narudžbinu pre nego što je potvrdite.
            </p>
          </li>
          <li>
            <p>
              Vaši lični podaci će biti upotrebljeni isključivo za obradu vaše narudžbine i
              omogućavanje isporuke vaših proizvoda.
            </p>
          </li>
        </ul>

        <b className='privacy-paragraph'>11. Kolačići (Cookies)</b>
        <p>
          Naša e-trgovina koristi kolačiće kako bismo poboljšali vaše iskustvo. Kolačići su male
          datoteke koje se čuvaju na vašem uređaju i pomažu nam da analiziramo kako koristite našu
          stranicu.
        </p>

        <b className='privacy-paragraph'>12. Izmene Polise privatnosti</b>
        <p>
          Ova Polisa privatnosti može biti ažurirana izmenama ili dopunama. Obaveza je korisnika da
          povremeno provere ovu stranicu radi ažuriranja.
        </p>
        <p>
          Vaša upotreba naše e-trgovine nakon objave bilo kakvih promena u Polisi privatnosti
          smatraće se vašim prihvatanjem tih promena.
        </p>
        <p>
          Ova Polisa privatnosti se primenjuje od datuma poslednjeg ažuriranja navedenog na vrhu
          dokumenta.
        </p>
        <b className='privacy-paragraph'>Kontakt podaci:</b>
        <ul>
          <li>
            <p>
              E-mail:&nbsp;
              <a href='mailto:obucabig@gmail.com' style={{ color: '#000' }}>
                <b>obucabig@gmail.com</b>
              </a>
            </p>
          </li>
          <li>
            <p>
              Broj telefona:&nbsp;
              <a href='tel:+38166048905' style={{ color: '#000' }}>
                <b>+38166048905</b>
              </a>
            </p>
          </li>
          <li>
            <p>Web adresa: www.obucabig.com</p>
          </li>
          <li>
            <p>Prodavnica u Nišu: Bul. Nemanjica 25a lok. 33</p>
          </li>
          <li>
            <p>Prodavnica u Pirotu: Knjaza Miloša 74</p>
          </li>
          <li>
            <p>Adresa u Kruševacu: Vidovdanska 205</p>
          </li>
        </ul>
        <b className='privacy-paragraph'>Podaci o firmi</b>
        <ul>
          <li>
            <p>PIB: 100688458</p>
          </li>
          <li>
            <p>Matični broj: 52084253</p>
          </li>
          <li>
            <p>Datum osnivanja: 31.05.1995.</p>
          </li>
          <li>
            <p>
              Delatnost: 4772 - Trgovina na malo obućom i predmetima od kože u specijalizovanim
              prodavnicama
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyAndPolicy;
