import { modalError } from '../../helpers/uiHelper';
import Modal from '../Html/Modal';
import ICoupon from '../../models/coupon';
import CouponService from '../../services/coupon.service';
import { useRef } from 'react';

interface IComponentProps {
  statusIsOpen: boolean;
  setStatusIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  status: boolean;
  setStatus: React.Dispatch<React.SetStateAction<boolean>>;
  currentCoupon: ICoupon | null;
  getAll: () => Promise<void>;
}

const ChangeCouponStatus = ({
  statusIsOpen,
  setStatusIsOpen,
  status,
  setStatus,
  currentCoupon,
  getAll,
}: IComponentProps) => {
  const resErrorRef = useRef<HTMLSpanElement>(null);
  const couponStatusRef = useRef<HTMLSelectElement>(null);

  const changeCouponStatus = async (couponId: number, couponActive: boolean) => {
    const { data, ok } = await CouponService.changeStatus(couponId, couponActive);
    if (ok) {
      getAll();
      setStatusIsOpen(false);
    } else {
      modalError(resErrorRef, data.message);
    }
  };

  return (
    <Modal isOpen={statusIsOpen} setIsOpen={setStatusIsOpen} title='PROMENI STATUS KUPONA'>
      <div className='modal-data-list'>
        <div className='content-wrapper'>
          <div className='input-element'>
            <label>Status</label>
            <select
              ref={couponStatusRef}
              className='input-element full-width'
              id='order-status'
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setStatus(e.target.value === 'true')
              }
            >
              <option value='true'>Aktivan</option>
              <option value='false'>Nije aktivan</option>
            </select>
            <span className='modal-error' id='error-size'></span>
          </div>
        </div>
        <button onClick={() => changeCouponStatus(currentCoupon?.id as number, status)}>
          SAČUVAJ
        </button>
        <span ref={resErrorRef} className='modal-error'></span>
      </div>
    </Modal>
  );
};

export default ChangeCouponStatus;
