import { createContext, useEffect, useReducer, useState } from 'react';
import AccountService from '../../../services/account.service';
import IUser from '../../../models/user';
import UserListItem from '../../../components/Account/UserListItem';
import { ISortModel } from '../../../models/search';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import MetaTags from '../../../components/Html/MetaTags';
import { SeoConfig } from '../../../helpers/seo-config';
import { useContentWidth } from '../../../hooks/useContentWidth';
import NoResultsFound from '../../../components/General/NoResultsFound';
import AdminBackofficeSearch from '../../../components/Account/AdminBackofficeSearch';
import { IAdminBackofficeBaseSearchContext } from '../../../models/context';
import { userReducer } from '../../../helpers/reducers';
import Pagination from '../../../components/Pagination/Pagination';
import { sortUsers } from '../../../common/data';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext<IAdminBackofficeBaseSearchContext>({} as any);

const Users = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState<IUser[]>([]);
  const [maxNumberOfPages, setMaxNumberOfPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [deleteCurrentPage, setDeleteCurrentPage] = useState<boolean>(false);
  const [sort, setSort] = useState<ISortModel>(sortUsers[0]);

  useScrollOnLoad();
  useContentWidth();

  const [search, dispatch] = useReducer(userReducer, {});

  const loadUsers = async () => {
    const { data, ok } = await AccountService.getAll(search, sort, resultsPerPage, currentPage);
    if (ok) {
      setUsers(data.data);
      setMaxNumberOfPages(data.pageCount);
    }
  };

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sort, resultsPerPage, currentPage]);

  useEffect(() => {
    setDeleteCurrentPage(true);
  }, [search]);

  useEffect(() => {
    if (deleteCurrentPage) {
      setCurrentPage(0);
      setDeleteCurrentPage(false);
    }
  }, [deleteCurrentPage]);

  const contextReference: IAdminBackofficeBaseSearchContext = {
    setCurrentPage,
    setDeleteCurrentPage,
    setResultsPerPage,
    setSort,
    maxNumberOfPages,
    currentPage,
    resultsPerPage,
    sort,
    dispatch,
  };

  return (
    <>
      <MetaTags data={SeoConfig.Users} />
      <div className='min-height-block dashboard admin-panel-width' id='admin-panel'>
        <AdminBackofficeSearch dispatch={dispatch} />

        <UserContext.Provider value={contextReference}>
          <Pagination
            context={UserContext}
            sortCategories={sortUsers}
            actionButton={{
              text: 'DODAJ ZAPOSLENOG',
              available: true,
              onClick: () => navigate('/admin/users/add-employee'),
            }}
          />
        </UserContext.Provider>

        <div className='users-list'>
          {users.length > 0 &&
            users.map((user, index) => (
              <UserListItem loadUsers={loadUsers} user={user} key={index} />
            ))}
        </div>

        {users.length === 0 && <NoResultsFound />}
      </div>
    </>
  );
};

export default Users;
