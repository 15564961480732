import { useEffect } from "react";
import useUser from "./useUser";
import { useNavigate } from "react-router-dom";

export const useUnauthorizedProtection = (shouldRedirect = false) => {
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user !== null && shouldRedirect) {
      navigate("/");
    }
  }, [user, shouldRedirect, navigate])
}