export const optimizeSmallImage = (url: string): string => {
  let parts = url.split('upload');
  return `${parts[0]}upload/w_100,c_scale,q_auto,f_auto,fl_lossy${parts[1]}`;
}


export const quotaStatus = (precentage: number) => {
  if (precentage > 65 && precentage < 85) {
    return { title: "UPOZORENJE", color: "#ff9c1c" }
  }

  if (precentage > 85) {
    return { title: "OPASNOST", color: "#fa0a32" }
  }
  
  return { title: "OK", color: "#17a66f" }
}