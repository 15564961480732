import React, { useRef, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

interface IComponentProps {
  url: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}

const OpenImage: React.FC<IComponentProps> = ({ isOpen, setIsOpen, url }) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const modalOverlay = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';

      overlayRef.current?.classList.remove('hidden-modal');
      modalOverlay.current?.classList.remove('hidden-modal');
    } else {
      document.body.style.overflow = 'auto';

      setTimeout(() => {
        modalOverlay.current?.classList.add('hidden-modal');
      }, 200);

      setTimeout(() => {
        overlayRef.current?.classList.add('hidden-modal');
      }, 250);
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={`overlay ${isOpen ? 'open-modal' : 'closed-modal'}`}
        onClick={() => setIsOpen(false)}
        ref={overlayRef}
      ></div>
      {isOpen && <AiOutlineClose className='modal-close-image' onClick={() => setIsOpen(false)} />}
      <img
        src={url}
        alt={document.title}
        className={`image-content ${isOpen ? 'open-modal' : 'closed-modal'}`}
        ref={modalOverlay}
      />
    </>
  );
};

export default OpenImage;
