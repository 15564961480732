import { useRef, useState } from "react";
import "../../styles/SignIn.css";
import AuthService from "../../services/auth.service";
import Notification from "../../components/Html/Notification";
import { isNullOrWhiteSpace } from "../../utils/strings";
import { useScrollOnLoad } from "../../hooks/useScrollOnLoad";
import MetaTags from "../../components/Html/MetaTags";
import { SeoConfig } from "../../helpers/seo-config";
import { useUnauthorizedProtection } from "../../hooks/useUnauthorizedProtection";

const ForgotPassword = () => {
  const emailRef = useRef(document.createElement("input"));

  const [ok, setOk] = useState<boolean>(false);
  const [isSentAgain, setIsSentAgain] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);

  useScrollOnLoad();
  useUnauthorizedProtection(true);

  const forgotPassword = async () => {
    const email = emailRef.current.value;

    if (isNullOrWhiteSpace(email)) {
      return;
    }

    setFailed(false);

    const data = await AuthService.forgotPassword(email);

    setOk(data.ok);
    setFailed(data.ok === false);
  };

  return (
    <>
      <MetaTags data={SeoConfig.ForgotPassword} />
      <div className="sign-in-page">
        <section className="form">
          <h1 className="forgot-password-title">Zaboravljena lozinka</h1>

          <div className="input-element">
            <label>Email</label>
            <input
              ref={emailRef}
              type="text"
              name=""
              id="email"
              placeholder="Email"
            />
          </div>

          {ok === true && (
            <Notification
              isOk={true}
              title={`Na vašu mejl adresu poslali smo ${
                isSentAgain ? "ponovo" : ""
              } poruku koja sadrži link za promenu lozinke.`}
            />
          )}

          {ok === true && (
            <p className="mail-note">
              U koliko niste dobili poruku na mejl kliknite
              <span
                onClick={() => {
                  setFailed(true);
                  setOk(false);
                  forgotPassword();
                  setIsSentAgain(true);
                }}
              >
                ovde
              </span>
              da bi vam poslali ponovo poruku.
            </p>
          )}

          {isSentAgain === true && !ok && (
            <Notification isOk={true} title={`Poruka se šalje ponovo...`} />
          )}

          {!ok && !failed && !isSentAgain && (
            <button onClick={forgotPassword} className="remove-hover-black">pošalji</button>
          )}
        </section>
      </div>
    </>
  );
};

export default ForgotPassword;
