import React, { useState, useLayoutEffect } from 'react';
import '../../../styles/Store.css';
import { IProductBase } from '../../../models/product-base';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../../../helpers/uiHelper';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import WishlistService from '../../../services/wishlist.service';
import useUser from '../../../hooks/useUser';
import { UserRole } from '../../../common/enums';

interface IComponentProps {
  product: IProductBase;
  setReload?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductListItem: React.FC<IComponentProps> = ({ product, setReload }) => {
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState<boolean>(product.saved);

  useLayoutEffect(() => {
    setIsSaved(product.saved);
  }, [product]);

  const user = useUser();

  let price = product.price - product.price * ((product.discount ?? 0) / 100);

  const saveToWishlist = async () => {
    if (!user) {
      navigate(`/sign-in?fallbackUrl=/store&saveToWishlist=1&productId=${product.id}`);
    }

    const productId = product.id;
    await WishlistService.save(productId);
    setIsSaved(true);
  };

  const removeFromWishlist = async () => {
    const productId = product.id;
    await WishlistService.remove(productId);
    setIsSaved(false);

    if (setReload !== null && setReload !== undefined) {
      setReload(true);
    }
  };

  return (
    <div className='product'>
      {user && user.roleId === UserRole.Buyer && isSaved === true ? (
        <AiFillHeart className='save-to-wishlist' onClick={() => removeFromWishlist()} />
      ) : (
        <AiOutlineHeart className='save-to-wishlist' onClick={() => saveToWishlist()} />
      )}
      <div
        className='product-internal-data'
        onClick={() =>
          navigate(`/product/${product.id}?title=${product.title.replaceAll(' ', '-')}`)
        }
      >
        {product.isNew && (
          <div className='new-product new-product-mapping-mobile'>
            <span>NOVO</span>
          </div>
        )}
        {product.discount !== null && product?.discount !== 0 && (
          <div className='new-product discounted-product new-product-mapping-mobile'>
            <span>-{product.discount}%</span>
          </div>
        )}
        <img src={product.image} alt={product.title} loading='lazy' decoding='async' />
        <div className='product-item-title-wrp'>
          <h2>{product.title}</h2>
        </div>
        <div className='product-item-price-wrp'>
          <p>
            {product.discount !== null && product.discount !== 0 && (
              <s className='discounted-price'>{formatPrice(Number(product.price))}</s>
            )}
            <b
              style={{
                color: product.discount !== null && product.discount !== 0 ? '#fa0a32' : '#000',
              }}
            >
              {formatPrice(Number(price))}
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductListItem;
