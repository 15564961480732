import React from "react";
import Modal from "../Html/Modal";
import WishlistService from "../../services/wishlist.service";

interface IComponentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteWishlistModal: React.FC<IComponentProps> = ({
  isOpen,
  setIsOpen,
  setReload,
}) => {
  const removeAll = async () => {
    await WishlistService.removeAll();
    setIsOpen(false);
    setReload(true);
  };

  return (
    <Modal
      title="IZBRIŠI SAČUVANE ARTIKLE"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="delete-acc-modal">
        <p>
          Da li ste sigurni da želite da izbrišete sačuvane proizvode is vaše
          liste želja?
        </p>
        <div className="buttons-delete">
          <button className="delete-btn" onClick={removeAll}>
            IZBRIŠI
          </button>
          <button onClick={() => setIsOpen(false)} className="remove-hover-black">OTKAŽI</button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteWishlistModal;
