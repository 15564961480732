import React, { useEffect, useState } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { FaArrowRight } from 'react-icons/fa';

interface IComponentProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoSettingsFound: React.FC<IComponentProps> = ({ setIsOpen }) => {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);
  }, []);

  return show ? (
    <div className='empty-shoppinh-cart'>
      <AiOutlineMail className='empty-icon' />
      <h1>NEMATE PODEŠAVANJA</h1>
      <p>Još uvek nemate ni jedno podešavanje koje se koristi u aplikaciji.</p>

      <button onClick={() => setIsOpen(true)} className='go-to-checkout'>
        DODAJ PODEŠAVANJE
        <FaArrowRight className='cart-arrow-start' />
      </button>
    </div>
  ) : (
    <></>
  );
};

export default NoSettingsFound;
