import React, { useState, useEffect } from 'react';
import Modal from '../Html/Modal';
import { IMailServer } from '../../models/mail-server';
import MailServerService from '../../services/mailserver.service';
import Notification from '../Html/Notification';
import Constants from '../../common/constants';
import Input from '../Html/Input';

interface IComponentProps {
  isOpen: boolean;
  server: IMailServer;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loadServers: () => Promise<void>;
}

const DeleteMailServer: React.FC<IComponentProps> = ({
  isOpen,
  server,
  setIsOpen,
  loadServers,
}) => {
  const [confirm, setConfirm] = useState<boolean>(false);
  const [enableDeleteBtn, setEnableDeleteBtn] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen && confirm) {
      setTimeout(() => {
        setConfirm(false);
        setEnableDeleteBtn(false);
      }, 200);
    }
  }, [isOpen, confirm]);

  const deleteAccount = async () => {
    await MailServerService.delete(server.id);
    setIsOpen(false);
    await loadServers();
  };

  const Delete = () => {
    return (
      <>
        <p style={{ marginTop: 5 }}>Da li ste sigurni da želite da izbrišete mejl server?</p>
        <p style={{ marginTop: 10 }}>
          <strong>Konfiguracija servera: </strong>
        </p>
        <table className='server-delete-data'>
          <tbody>
            <tr>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <td>Host:</td>
              <td style={{ fontWeight: 'bold' }}>{server.host}</td>
            </tr>
            <tr>
              <td>Port:</td>
              <td style={{ fontWeight: 'bold' }}>{server.port}</td>
            </tr>
            <tr>
              <td>Mejl pošiljaoca:</td>
              <td style={{ fontWeight: 'bold' }}>{server.senderMail}</td>
            </tr>
            <tr>
              <td>Tip servisa:</td>
              <td style={{ fontWeight: 'bold' }}>{server.serviceType}</td>
            </tr>
          </tbody>
        </table>

        {!enableDeleteBtn && (
          <>
            <Notification isOk={false} title={Constants.EnterSenderMail} />
            <Input
              label='Unesite Mejl pošiljaoca:'
              onChange={(e) => {
                const value = e.target.value;

                if (value === server.senderMail) {
                  setEnableDeleteBtn(true);
                } else {
                  setEnableDeleteBtn(false);
                }
              }}
            />
          </>
        )}

        <div className='buttons-delete'>
          {enableDeleteBtn && (
            <button className='delete-btn' onClick={deleteAccount}>
              IZBRIŠI
            </button>
          )}
          <button onClick={() => setIsOpen(false)}>OTKAŽI</button>
        </div>
      </>
    );
  };

  return (
    <Modal title='IZBRIŠI SERVER' isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='delete-acc-modal' style={{ paddingTop: 0 }}>
        {confirm ? (
          <Delete />
        ) : (
          <>
            <Notification isOk={false} title={Constants.MailServerWarning} />
            <div className='buttons-delete'>
              <button className='delete-btn' onClick={() => setConfirm(true)}>
                NASTAVI
              </button>
              <button onClick={() => setIsOpen(false)}>OTKAŽI</button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteMailServer;
