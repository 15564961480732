import React, { useState } from 'react';
import { IMailServer } from '../../models/mail-server';
import DeleteMailServer from './DeleteMailServer';
import { useNavigate } from 'react-router-dom';

interface IComponentProps {
  data: IMailServer;
  getMailServers: () => Promise<void>;
}

const MailServerListItem: React.FC<IComponentProps> = ({ data, getMailServers }) => {
  const [isDeleteServerModalOpen, setIsDeleteServerModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <>
      <DeleteMailServer
        loadServers={getMailServers}
        isOpen={isDeleteServerModalOpen}
        setIsOpen={setIsDeleteServerModalOpen}
        server={data}
      />
      <div className='user-data-wrapper'>
        <div className='primary-order-data'>
          <div className='align-items'>
            <h1>{data.host}</h1>
          </div>
          <div className='align-items'>
            <button
              className='order-button'
              onClick={() => navigate(`/admin/mail-servers/edit/${data.id}`)}
            >
              IZMENI
            </button>
            <button
              className='order-button edit-order-btn'
              onClick={() => setIsDeleteServerModalOpen(true)}
            >
              IZBRIŠI
            </button>
          </div>
        </div>
        <div className='order-data-admin'>
          <div className='contact-data-order-admin user-admin-data'>
            <h3>{data.port}</h3>
            <h3>{data.senderMail}</h3>
          </div>

          <div className='contact-data-order-admin user-admin-data'>
            <h3>{data.serviceType}</h3>
            <h3
              className='user-role-tag'
              style={{
                backgroundColor: !data.enabled ? '#fa0a32' : '#16C784',
              }}
            >
              {data.enabled ? 'Operativan' : 'Nije operativan'}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default MailServerListItem;
