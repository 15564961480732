import { useState, useEffect, useLayoutEffect, useReducer, createContext } from 'react';
import { ISortModel } from '../../../models/search';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import { useContentWidth } from '../../../hooks/useContentWidth';
import useUser from '../../../hooks/useUser';
import MetaTags from '../../../components/Html/MetaTags';
import { SeoConfig } from '../../../helpers/seo-config';
import { settingsReducer } from '../../../helpers/reducers';
import { sortMailTemplates } from '../../../common/data';
import NoResultsFound from '../../../components/General/NoResultsFound';
import Pagination from '../../../components/Pagination/Pagination';
import { IAdminBackofficeSearchContext } from '../../../models/context';
import { ISettings } from '../../../models/settings';
import SettingsService from '../../../services/settings.service';
import AdminBackofficeSearch from '../../../components/Settings/AdminBackofficeSearch';
import SettingListItem from '../../../components/Settings/SettingListItem';
import NoSettingsFound from '../../../components/Settings/NoSettingsFound';
import AddSettingModal from '../../../components/Settings/AddSettingModal';

export const SettingsContext = createContext<Partial<IAdminBackofficeSearchContext>>({} as any);

const Settings = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [data, setData] = useState<ISettings[]>([]);
  const [maxNumberOfPages, setMaxNumberOfPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [deleteCurrentPage, setDeleteCurrentPage] = useState<boolean>(false);
  const [sort, setSort] = useState<ISortModel>(sortMailTemplates[0]);

  useScrollOnLoad();
  useContentWidth();

  const [search, dispatch] = useReducer(settingsReducer, {});

  const user = useUser();

  useEffect(() => {
    setDeleteCurrentPage(true);
  }, [search]);

  useLayoutEffect(() => {
    (async () => {
      const { data } = await SettingsService.totalCount();
      setTotalCount(data.count);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (totalCount > 0) {
        await getSettings();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, search, sort, deleteCurrentPage, resultsPerPage, currentPage, totalCount]);

  const getSettings = async () => {
    const { data, ok } = await SettingsService.filter(search, sort, resultsPerPage, currentPage);
    if (ok) {
      setData(data.data);
      setMaxNumberOfPages(data.pageCount);

      if (deleteCurrentPage) {
        setCurrentPage(0);
        setDeleteCurrentPage(false);
      }
    }
  };

  const contextReference: Partial<IAdminBackofficeSearchContext> = {
    setCurrentPage,
    setDeleteCurrentPage,
    setResultsPerPage,
    setSort,
    maxNumberOfPages,
    currentPage,
    resultsPerPage,
    sort,
    dispatch,
  };

  return (
    <>
      <MetaTags data={SeoConfig.OrdersAdmin} />
      <div className='dashboard min-height-block  admin-panel-width' id='admin-panel'>
        <SettingsContext.Provider value={contextReference}>
          <AddSettingModal
            isOpen={isCreateModalOpen}
            setIsOpen={setIsCreateModalOpen}
            getSettings={getSettings}
          />
          {totalCount > 0 && (
            <>
              <AdminBackofficeSearch />
              <Pagination
                sortCategories={sortMailTemplates}
                context={SettingsContext}
                actionButton={{
                  available: true,
                  text: 'Dodaj podešavanje',
                  onClick: () => setIsCreateModalOpen(true),
                }}
              />
            </>
          )}

          <div className='users-list newsletter-subscribers'>
            {totalCount > 0 &&
              data.map((info, index) => (
                <SettingListItem data={info} key={index} getSettings={getSettings} />
              ))}

            {totalCount === 0 && <NoSettingsFound setIsOpen={setIsCreateModalOpen} />}
            {totalCount > 0 && data.length === 0 && <NoResultsFound />}
          </div>
        </SettingsContext.Provider>
      </div>
    </>
  );
};

export default Settings;
