import { useState, useEffect, createContext, useReducer, useLayoutEffect } from 'react';
import CouponService from '../../../services/coupon.service';
import ICoupon from '../../../models/coupon';
import ChangeCouponStatus from '../../../components/Coupons/ChangeCouponStatus';
import AddCoupon from '../../../components/Coupons/AddCoupon';
import EmptyCoupons from '../../../components/Coupons/EmptyCoupons';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import MetaTags from '../../../components/Html/MetaTags';
import { SeoConfig } from '../../../helpers/seo-config';
import { useContentWidth } from '../../../hooks/useContentWidth';
import CouponListItem from '../../../components/Coupons/CouponListItem';
import { IAdminBackofficeSearchContext } from '../../../models/context';
import { ISortModel } from '../../../models/search';
import { sortCoupons } from '../../../common/data';
import AdminBackofficeSearch from '../../../components/Coupons/AdminBackofficeSearch';
import Pagination from '../../../components/Pagination/Pagination';
import { couponsReducer } from '../../../helpers/reducers';
import NoResultsFound from '../../../components/General/NoResultsFound';

export const CouponsContext = createContext<Partial<IAdminBackofficeSearchContext>>({} as any);

const Coupons = () => {
  const [search, dispatch] = useReducer(couponsReducer, {});

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [statusIsOpen, setStatusIsOpen] = useState<boolean>(false);
  const [coupons, setCoupons] = useState<ICoupon[]>([]);
  const [currentCoupon, setCurrentCoupon] = useState<ICoupon | null>(null);
  const [status, setStatus] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [maxNumberOfPages, setMaxNumberOfPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [deleteCurrentPage, setDeleteCurrentPage] = useState<boolean>(false);
  const [sort, setSort] = useState<ISortModel>(sortCoupons[0]);

  useEffect(() => {
    setDeleteCurrentPage(true);
  }, [search]);

  useLayoutEffect(() => {
    (async () => {
      const { data } = await CouponService.getTotalCount();
      setTotalCount(data.count);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (totalCount > 0) {
        await getAll();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sort, deleteCurrentPage, resultsPerPage, currentPage, totalCount]);
  useScrollOnLoad();
  useContentWidth();

  const getAll = async () => {
    const { data, ok } = await CouponService.getAll(search, sort, resultsPerPage, currentPage);

    if (ok) {
      setCoupons(data.data);
      setMaxNumberOfPages(data.pageCount);
    }
  };

  const contextReference: Partial<IAdminBackofficeSearchContext> = {
    setCurrentPage,
    setDeleteCurrentPage,
    setResultsPerPage,
    setSort,
    maxNumberOfPages,
    currentPage,
    resultsPerPage,
    sort,
    dispatch,
  };

  return (
    <>
      <MetaTags data={SeoConfig.Coupons} />
      <div className='dashboard min-height-block admin-panel-width' id='admin-panel'>
        <AddCoupon isOpen={isOpen} setIsOpen={setIsOpen} getAll={getAll} />
        <ChangeCouponStatus
          statusIsOpen={statusIsOpen}
          setStatusIsOpen={setStatusIsOpen}
          status={status}
          setStatus={setStatus}
          currentCoupon={currentCoupon}
          getAll={getAll}
        />
        {totalCount > 0 && (
          <>
            <CouponsContext.Provider value={contextReference}>
              <AdminBackofficeSearch />
              <Pagination
                sortCategories={sortCoupons}
                context={CouponsContext}
                actionButton={{
                  available: true,
                  text: 'Dodaj kupon',
                  onClick: () => setIsOpen(true),
                }}
              />
            </CouponsContext.Provider>
            <div className='coupons-mapper'>
              {coupons.length > 0 &&
                coupons.map((coupon, i) => (
                  <CouponListItem
                    key={i}
                    coupon={coupon}
                    setCurrentCoupon={setCurrentCoupon}
                    setStatusIsOpen={setStatusIsOpen}
                  />
                ))}
            </div>
          </>
        )}
        {totalCount === 0 && (
          <EmptyCoupons>
            <button
              className='order-button'
              style={{ marginTop: 5 }}
              onClick={() => setIsOpen(true)}
            >
              DODAJ KUPON
            </button>
          </EmptyCoupons>
        )}

        {totalCount > 0 && coupons.length === 0 && <NoResultsFound />}
      </div>
    </>
  );
};

export default Coupons;
