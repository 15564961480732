import React from 'react';

interface IComponentProps {
  label: string;
  elRef: React.Ref<any>;
}

const ToggleSwitch: React.FC<IComponentProps> = ({ label, elRef }) => {
  return (
    <div className='toggle-wrapper'>
      <h2 className='toggle-switch-title'>{label}</h2>
      <div className='input-element align-end-btn'>
        <label className='product-switch'>
          <input ref={elRef} type='checkbox' />
          <span className='slider round'></span>
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
