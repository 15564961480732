import '../../styles/BurgerMenu.css';
import { FaTimes } from 'react-icons/fa';
import React from 'react';

interface IComponentProps {
  setOpen: any;
  searchMenuRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}

const FilterStoreMobile = ({ setOpen, searchMenuRef, children }: IComponentProps) => {
  return (
    <div ref={searchMenuRef} className='burger-menu'>
      <div className='burger-logo'>
        <FaTimes
          size={35}
          onClick={() => {
            setOpen(false);
            document.body.style.overflow = 'visible';
          }}
        />
      </div>
      {children}
    </div>
  );
};

export default FilterStoreMobile;
