import { DashboardSearchPeriods, Gender, MailTemplateType, SettingDataType, UserRole, WebImagePosition } from "./enums";
import NoPhoto from "../assets/no-photo.png";
import { IWebImageBase } from "../models/web-images";
import { ICloudinaryStatistics } from "../models/cloudinary";

export const genders = [
  {
    id: Gender.Male,
    name: "Muško"
  },
  {
    id: Gender.Female,
    name: "Žensko"
  },
  {
    id: Gender.Kids,
    name: "Dečije"
  }
];

export const sortProducts = [
  {
    sortBy: "title",
    sortDirection: "ASC",
    title: "Naziv proizvoda A-Z"
  },
  {
    sortBy: "title",
    sortDirection: "DESC",
    title: "Naziv proizvoda Z-A"
  },
  {
    sortBy: "price",
    sortDirection: "ASC",
    title: "Cena rastuće"
  },
  {
    sortBy: "price",
    sortDirection: "DESC",
    title: "Cena opadajuće"
  },
  {
    sortBy: "soldCount",
    sortDirection: "DESC",
    title: "Najprodavanije"
  },
  {
    sortBy: "timeAdded",
    sortDirection: "DESC",
    title: "Najnovije"
  },
  {
    sortBy: "discount",
    sortDirection: "DESC",
    title: "Najveći popust"
  },
];

export const adminSortProducts =
  [...sortProducts, {
    sortBy: "views",
    sortDirection: "DESC",
    title: "Najposećenije"
  }];

export const Categories = [
  {
    title: "Rieker",
    gender: Gender.Male
  },
  {
    title: "Patike",
    gender: Gender.Male
  },
  {
    title: "Skechers",
    gender: Gender.Male
  },
  {
    title: "Cipele",
    gender: Gender.Male
  },
  {
    title: "Čizme i cipele",
    gender: Gender.Male
  },
  {
    title: "Sandale",
    gender: Gender.Male
  },
  {
    title: "Papuče",
    gender: Gender.Male
  },
  {
    title: "Cipele na štiklu i salonke",
    gender: Gender.Female
  },
  {
    title: "Ravne cipele i baletanke",
    gender: Gender.Female
  },
  {
    title: "Sandale na štiklu i platformu",
    gender: Gender.Female
  },
  {
    title: "Ravne sandale",
    gender: Gender.Female
  },
  {
    title: "Čizme i jesenje cipele",
    gender: Gender.Female
  },
  {
    title: "Patike",
    gender: Gender.Female
  },
  {
    title: "Papuče",
    gender: Gender.Female
  },
  {
    title: "Čizme",
    gender: Gender.Kids
  },
  {
    title: "Patike",
    gender: Gender.Kids
  },
];

export const OrderStatuses = ["Obrada", "Dostavljanje", "Završeno", "Otkazano"];

export const sortOrders = [
  {
    sortBy: "orderTime",
    sortDirection: "DESC",

    title: "Najnovije"
  },
  {
    sortBy: "orderTime",
    sortDirection: "ASC",
    title: "Najstarije"
  }
];

export const sortMailServers = [
  {
    sortBy: "host",
    sortDirection: "DESC",

    title: "Host Z-A"
  },
  {
    sortBy: "host",
    sortDirection: "ASC",
    title: "Host A-Z"
  }
];

export const sortUsers = [
  {
    sortBy: "time",
    sortDirection: "DESC",

    title: "Najnovije"
  },
  {
    sortBy: "time",
    sortDirection: "ASC",
    title: "Najstarije"
  }
];


export const UserRolesMap = [
  {
    role: UserRole.Admin,
    title: "Administrator",
    color: "#fa0a32",
  },
  {
    role: UserRole.Buyer,
    title: "Kupac",
    color: "#000"
  },
  {
    role: UserRole.Employee,
    title: "Zposleni",
    color: "#0063e4"
  }
];

export const VerificationStauses = [
  {
    value: 1,
    title: "Verifikovan"
  },
  {
    value: 0,
    title: "Nije verifikovan"
  }
];


export const resultsPerPageMapping = [5, 10, 15, 20, 50];

export const sortCoupons = [
  {
    sortBy: "id",
    sortDirection: "DESC",
    title: "Najnovije"
  },
  {
    sortBy: "id",
    sortDirection: "ASC",
    title: "Najstarije"
  }
];

export const MailTemplateTypeData = [
  {
    title: "Verifikacija naloga",
    id: MailTemplateType.VerifyAccount
  },
  {
    title: "Aktiviran naloga",
    id: MailTemplateType.AccountActivated
  },
  {
    title: "Resetovanje lozinke",
    id: MailTemplateType.ResetPassword
  },
  {
    title: "Potvrda pordužbine",
    id: MailTemplateType.OrderConfirmation
  },
  {
    title: "Promena statusa pordužbine",
    id: MailTemplateType.OrderStatusUpdated
  }
];


export const sortMailTemplates = [
  {
    sortBy: "id",
    sortDirection: "DESC",
    title: "Najnovije"
  },
  {
    sortBy: "id",
    sortDirection: "ASC",
    title: "Najstarije"
  },
  {
    sortBy: "name",
    sortDirection: "ASC",
    title: "Naziv A-Z"
  },
  {
    sortBy: "name",
    sortDirection: "DESC",
    title: "Naziv Z-A"
  },
];

export const SettingDataTypeMapping = [
  {
    title: "Broj",
    type: SettingDataType.Number
  },
  {
    title: "Niz karaktera",
    type: SettingDataType.String
  },
  {
    title: "Logička vrednost",
    type: SettingDataType.Boolean
  },
];

export const DashboardPeriodsMapping = [
  {
    title: "Danas",
    value: DashboardSearchPeriods.Today,
  },
  {
    title: "Ove nedelje",
    value: DashboardSearchPeriods.ThisWeek
  },
  {
    title: "Ovaj mesec",
    value: DashboardSearchPeriods.ThisMonth
  },
  {
    title: "3 meseca",
    value: DashboardSearchPeriods.Last3Months
  },
  {
    title: "6 meseca",
    value: DashboardSearchPeriods.Last6Months
  },
  {
    title: "Ova godina",
    value: DashboardSearchPeriods.Year
  },
  {
    title: "Oduvek",
    value: DashboardSearchPeriods.AllTheTime
  }
];

export const defaultUserChartData = [
  {
    roleId: UserRole.Admin,
    count: 0
  },
  {
    roleId: UserRole.Employee,
    count: 0
  },
  {
    roleId: UserRole.Buyer,
    count: 0
  }
];

export const WebImagesPositionMapping = [
  {
    title: "Baner na početnoj stranici",
    value: WebImagePosition.Banner,
  },
  {
    title: "Muško obuća poster",
    value: WebImagePosition.MalePoster
  },
  {
    title: "Ženska obuća poster",
    value: WebImagePosition.FemalePoster
  },
  {
    title: "Dečija obuća poster",
    value: WebImagePosition.KidsPoster
  },
  {
    title: "Veliki poster",
    value: WebImagePosition.PosterMain
  },
  {
    title: "Mali poster gornji desni",
    value: WebImagePosition.TopRightPoster
  },
  {
    title: "Mali poster donji desni",
    value: WebImagePosition.BottomRightPoster
  },
  {
    title: "Levi poster",
    value: WebImagePosition.LeftPoster
  },
  {
    title: "Desin poster",
    value: WebImagePosition.RightPoster
  },
  {
    title: "Sniženje poster",
    value: WebImagePosition.DiscountPoster
  },
  {
    title: "Nova kolekcija poster",
    value: WebImagePosition.NewCollectionPoster
  },
]

export const sortWebImages = [
  {
    sortBy: "uploadTime",
    sortDirection: "DESC",
    title: "Najnovije"
  }, {
    sortBy: "uploadTime",
    sortDirection: "DESC",
    title: "Najstarije"
  }
];

export const defaultUIImages: IWebImageBase[] = [
  {
    imagePosition: WebImagePosition.Banner,
    url: NoPhoto,
    displayIndex: 0,
  },
  {
    imagePosition: WebImagePosition.Banner,
    url: NoPhoto,
    displayIndex: 1,
  },
  {
    imagePosition: WebImagePosition.PosterMain,
    url: NoPhoto,
    displayIndex: 0,
  },
  {
    imagePosition: WebImagePosition.BottomRightPoster,
    url: NoPhoto,
    displayIndex: 0,
  },
  {
    imagePosition: WebImagePosition.DiscountPoster,
    url: NoPhoto,
    displayIndex: 0,
  },
  {
    imagePosition: WebImagePosition.FemalePoster,
    url: NoPhoto,
    displayIndex: 0,
  },
  {
    imagePosition: WebImagePosition.KidsPoster,
    url: NoPhoto,
    displayIndex: 0,
  },
  {
    imagePosition: WebImagePosition.LeftPoster,
    url: NoPhoto,
    displayIndex: 0,
  },
  {
    imagePosition: WebImagePosition.MalePoster,
    url: NoPhoto,
    displayIndex: 0,
  },
  {
    imagePosition: WebImagePosition.NewCollectionPoster,
    url: NoPhoto,
    displayIndex: 0,
  },
  {
    imagePosition: WebImagePosition.RightPoster,
    url: NoPhoto,
    displayIndex: 0,
  },
  {
    imagePosition: WebImagePosition.TopRightPoster,
    url: NoPhoto,
    displayIndex: 0,
  }
];

export const defaultCloudinaryInfo: ICloudinaryStatistics = {
  plan: "FREEE",
  cloudinaryName: "----",
  lastUpdate: new Date(),
  credits: {
    usage: 0,
    limit: 0,
    usedPercentage: 0,
  },
  resourceCount: 0,
  transformations: {
    usage: 0,
    creditUsage: 0
  }
};