import { useState, useEffect } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom';

const AdminHeader = () => {
  const [isHidden, setIsHidden] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('popstate', () => {
      const contentWidth = document.querySelectorAll('.admin-panel-width');

      if (contentWidth !== undefined && contentWidth !== null) {
        contentWidth.forEach((x) => {
          if (isHidden) {
            x.classList.remove('show-content-admin');
            x.classList.add('hide-content-admin');
          } else {
            x.classList.remove('hide-content-admin');
            x.classList.add('show-content-admin');
          }
        });
      }
    });
  }, [isHidden]);

  const colapseBurger = () => {
    try {
      setIsHidden(!isHidden);

      const sideBar = document.getElementById('sidebar');

      const isResponsiveActivated = window.innerWidth > 1500;
      const showSidebarWidth = !isResponsiveActivated ? 250 : 300;
      const panelWidth = !isHidden ? 50 : showSidebarWidth;

      if (sideBar !== null && sideBar !== undefined) {
        sideBar.style.width = `${panelWidth}px`;
      }

      const sideBarLinks = document.querySelectorAll('.admin-links-mapping');
      const sideBarTitles = document.querySelectorAll('.sidebar-title');

      const classToRemove = !isHidden ? 'show-item-admin' : 'hide-item-admin';
      const classToAdd = isHidden ? 'show-item-admin' : 'hide-item-admin';

      if (sideBarLinks !== undefined && sideBarLinks !== null) {
        sideBarLinks.forEach((x) => x.classList.remove(classToRemove));
        sideBarLinks.forEach((x) => x.classList.add(classToAdd));
      }

      if (sideBarTitles !== undefined && sideBarTitles !== null) {
        sideBarTitles.forEach((x) => x.classList.remove(classToRemove));
        sideBarTitles.forEach((x) => x.classList.add(classToAdd));
      }

      const contentWidth = document.querySelectorAll('.admin-panel-width');

      if (contentWidth !== undefined && contentWidth !== null) {
        contentWidth.forEach((x) => {
          x.classList.add('add-trans');
          x.classList.remove('remove-trans');

          if (isHidden) {
            x.classList.remove('show-content-admin');
            x.classList.add('hide-content-admin');
          } else {
            x.classList.remove('hide-content-admin');
            x.classList.add('show-content-admin');
          }
        });
      }

      const adminHeader = document.getElementById('admin-header');

      if (adminHeader !== null && adminHeader !== undefined) {
        if (isHidden) {
          adminHeader.classList.add('hide-header-admin');
          adminHeader.classList.remove('show-header-admin');
        } else {
          adminHeader.classList.remove('hide-header-admin');
          adminHeader.classList.add('show-header-admin');
        }
      }

      const logo = document.getElementById('sitemap-logo-img');
      const logoDiv = document.getElementById('logo-div');

      if (logo !== null && logo !== undefined && logoDiv !== undefined && logoDiv !== null) {
        if (isHidden) {
          logo.classList.add('hide-logo-admin');
          logo.classList.remove('show-logo-admin');

          logoDiv.classList.add('hide-admin-logo-div');
          logoDiv.classList.remove('show-admin-logo-div');
        } else {
          logo.classList.remove('hide-logo-admin');
          logo.classList.add('show-logo-admin');

          logoDiv.classList.remove('hide-admin-logo-div');
          logoDiv.classList.add('show-admin-logo-div');
        }
      }

      const adminIcons = document.querySelectorAll('.admin-icon');

      if (sideBarTitles !== undefined && sideBarTitles !== null) {
        const classToRemoveIcon = !isHidden ? 'show-icon-admin' : 'hide-icon-admin';
        const classToAddIcon = isHidden ? 'show-icon-admin' : 'hide-icon-admin';

        adminIcons.forEach((x) => x.classList.remove(classToRemoveIcon));
        adminIcons.forEach((x) => x.classList.add(classToAddIcon));
      }

      const icons = document.querySelectorAll('.admin-links-section');
      const adminHeadline = document.querySelectorAll('.admin-headline');

      if (adminHeadline !== undefined && adminHeadline !== null) {
        adminHeadline.forEach((x) => {
          if (!isHidden) {
            x.classList.add('set-icons-in-center');
          } else {
            x.classList.remove('set-icons-in-center');
          }
        });
      }

      if (icons !== undefined && icons !== null) {
        icons.forEach((x) => {
          if (!isHidden) {
            x.classList.add('set-icons-in-center');
          } else {
            x.classList.remove('set-icons-in-center');
          }
        });
      }
    } catch {}
  };

  return (
    <div className='admin-header' id='admin-header'>
      <button className='colapse-sidebar' onClick={colapseBurger}>
        <GiHamburgerMenu />
      </button>
      <div className='admin-acc-data'>
        <Link to='/account'>
          <div className='link-items'>
            <AiOutlineUser className='link-icon' />
            <p>Nalog</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AdminHeader;
