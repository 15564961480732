import { useState, useLayoutEffect } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import "../../styles/SignIn.css";
import Notification from "../../components/Html/Notification";
import AccountService from "../../services/account.service";
import { useScrollOnLoad } from "../../hooks/useScrollOnLoad";
import { SeoConfig } from "../../helpers/seo-config";
import MetaTags from "../../components/Html/MetaTags";
import { useUnauthorizedProtection } from "../../hooks/useUnauthorizedProtection";

const Verify = () => {
  const { token } = useParams();

  const navigate: NavigateFunction = useNavigate();

  const [ok, setOk] = useState<boolean>(false);
  const [data, setData] = useState<any>("");

  useUnauthorizedProtection(true);

  useScrollOnLoad();

  useLayoutEffect(() => {
    (async () => {
      const data_ = await AccountService.verifyUserToken(token);
      setData(data_.data);
      setOk(data_.ok);
    })();
  }, [token]);

  return (
    <>
      <MetaTags data={SeoConfig.Verify} />
      <div className="sign-in-page">
        <section className="form">
          <h1 className="forgot-password-title">
            {ok === true ? "Nalog je verifikovan" : "Nalog nije verifikovan"}
          </h1>

          {ok === false && <Notification isOk={false} title={data.message} />}

          {ok === true && <Notification isOk={true} title={data.message} />}

          {ok === true && (
            <button onClick={() => navigate("/sign-in")} className="remove-hover-black">PRIJAVI SE</button>
          )}
        </section>
      </div>
    </>
  );
};

export default Verify;
