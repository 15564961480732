import React, { useState, useContext } from 'react';
import Modal from '../../Html/Modal';
import ProductService from '../../../services/product.service';
import Notification from '../../Html/Notification';
import { IAdminBackofficeSearchContext } from '../../../models/context';
import { ProductsContext } from '../../../pages/admin/products/Products';

interface IComponentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productId: number;
}

const DeleteProductModal: React.FC<IComponentProps> = ({ isOpen, setIsOpen, productId }) => {
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const { setDeleteCurrentPage, dispatch } =
    useContext<IAdminBackofficeSearchContext>(ProductsContext);

  const deleteProduct = async () => {
    const { data, ok } = await ProductService.delete(productId);

    if (ok) {
      setIsOpen(false);
      setDeleteCurrentPage(true);
      dispatch({ type: 'reset' });
    }

    setError(!ok);
    setMessage(data?.message ?? '');

    setTimeout(() => {
      setError(false);
      setMessage('');
    }, 2000);
  };

  return (
    <Modal title='IZBRIŠI PROIZVOD' isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='delete-acc-modal'>
        <p>Da li ste sigurni da želite da izbrišete ovaj proizvod?</p>
        {error === false ? (
          <div className='buttons-delete'>
            <button className='delete-btn' onClick={deleteProduct}>
              IZBRIŠI
            </button>
            <button onClick={() => setIsOpen(false)}>OTKAŽI</button>
          </div>
        ) : (
          <Notification title={message} isOk={false} />
        )}
      </div>
    </Modal>
  );
};

export default DeleteProductModal;
