import { useEffect } from "react";

export const useContentWidth = (timeout = 10) => {
  useEffect(() => {
    setTimeout(() => {
      try {
        const contentWidth = document.getElementById("admin-panel");
        const sideBar = document.getElementById("sidebar");

        let isHidden = false;

        if (contentWidth !== undefined && contentWidth !== null) {
          isHidden = sideBar?.clientWidth === 50;
        }

        if (contentWidth !== undefined && contentWidth !== null) {
          //contentWidth.classList.remove("add-trans");
          //contentWidth.classList.add("remove-trans");

          if (!isHidden) {
            contentWidth.classList.remove("show-content-admin");
            contentWidth.classList.add("hide-content-admin");
          } else {
            contentWidth.classList.remove("hide-content-admin");
            contentWidth.classList.add("show-content-admin");
          }
        }
      } catch { }
    }, timeout);
  }, [timeout]);

}