import { useState, useEffect, useLayoutEffect, useReducer, createContext } from 'react';
import { IMailServer } from '../../../models/mail-server';
import { ISortModel } from '../../../models/search';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import { useContentWidth } from '../../../hooks/useContentWidth';
import useUser from '../../../hooks/useUser';
import MetaTags from '../../../components/Html/MetaTags';
import { SeoConfig } from '../../../helpers/seo-config';
import { mailServerReducer } from '../../../helpers/reducers';
import { sortMailServers } from '../../../common/data';
import MailServerService from '../../../services/mailserver.service';
import NoResultsFound from '../../../components/General/NoResultsFound';
import MailServerListItem from '../../../components/MailServer/MailServerListItem';
import { useNavigate } from 'react-router-dom';
import NoMailServerFound from '../../../components/MailServer/NoMailServerFound';
import Pagination from '../../../components/Pagination/Pagination';
import { IAdminBackofficeSearchContext } from '../../../models/context';
import AdminBackofficeSearch from '../../../components/MailServer/AdminBackofficeSearch';

export const MailServerContext = createContext<Partial<IAdminBackofficeSearchContext>>({} as any);

const MailServers = () => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [data, setData] = useState<IMailServer[]>([]);
  const [maxNumberOfPages, setMaxNumberOfPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [deleteCurrentPage, setDeleteCurrentPage] = useState<boolean>(false);
  const [sort, setSort] = useState<ISortModel>(sortMailServers[0]);

  useScrollOnLoad();
  useContentWidth();

  const [search, dispatch] = useReducer(mailServerReducer, {});

  const user = useUser();

  const navigate = useNavigate();

  useEffect(() => {
    setDeleteCurrentPage(true);
  }, [search]);

  useLayoutEffect(() => {
    (async () => {
      const { data } = await MailServerService.totalCount();
      setTotalCount(data.count);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (totalCount > 0) {
        await getMailServers();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, search, sort, deleteCurrentPage, resultsPerPage, currentPage, totalCount]);

  const getMailServers = async () => {
    const { data, ok } = await MailServerService.filter(search, sort, resultsPerPage, currentPage);
    if (ok) {
      setData(data.data);
      setMaxNumberOfPages(data.pageCount);

      if (deleteCurrentPage) {
        setCurrentPage(0);
        setDeleteCurrentPage(false);
      }
    }
  };

  const contextReference: Partial<IAdminBackofficeSearchContext> = {
    setCurrentPage,
    setDeleteCurrentPage,
    setResultsPerPage,
    setSort,
    maxNumberOfPages,
    currentPage,
    resultsPerPage,
    sort,
    dispatch,
  };

  return (
    <>
      <MetaTags data={SeoConfig.OrdersAdmin} />
      <div className='dashboard min-height-block  admin-panel-width' id='admin-panel'>
        {totalCount > 0 && (
          <>
            <MailServerContext.Provider value={contextReference}>
              <AdminBackofficeSearch />
              <Pagination
                sortCategories={sortMailServers}
                context={MailServerContext}
                actionButton={{
                  available: true,
                  text: 'Dodaj server',
                  onClick: () => navigate('/admin/mail-servers/add-new'),
                }}
              />
            </MailServerContext.Provider>
          </>
        )}

        <div className='users-list newsletter-subscribers'>
          {totalCount > 0 &&
            data.map((info, index) => (
              <MailServerListItem data={info} key={index} getMailServers={getMailServers} />
            ))}

          {totalCount === 0 && <NoMailServerFound />}
          {totalCount > 0 && data.length === 0 && <NoResultsFound />}
        </div>
      </div>
    </>
  );
};

export default MailServers;
