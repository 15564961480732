import { ISortModel } from "../models/search";
import { ICouponsSearchCriteria } from "../models/search-criteria";
import { isNullOrWhiteSpace } from "../utils/strings";
import { AxiosAuthorized } from "../common/axios";

export default class CouponService {
  private static serviceEndpoint: string = `/coupon`;

  public static async addCoupon(
    code: string,
    discount: number,
    expireTime: Date
  ) {
    try {
      const api = `${this.serviceEndpoint}/`;

      const { data } = await AxiosAuthorized.post(
        api,
        { code, discount, expireTime, active: true },
      );

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async getAll(sp: ICouponsSearchCriteria, sort: ISortModel, resultsPerPage: number, currentPage: number) {
    try {
      let api = `${this.serviceEndpoint}/filter?sortBy=${sort.sortBy}&sortDirection=${sort.sortDirection}&resultsPerPage=${resultsPerPage}&pageNumber=${currentPage}`;

      if (sp.active !== undefined && sp.active !== null) {
        api += `&active=${sp.active ? "1" : "0"}`;
      }

      if (!isNullOrWhiteSpace(sp.code)) {
        api += `&code=${sp.code}`;
      }

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async getTotalCount() {
    try {
      const api = `${this.serviceEndpoint}/total-count`;

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async changeStatus(couponId: number, couponActive: boolean) {
    try {
      const api = `${this.serviceEndpoint}/activity/${couponId}`;

      const { data } = await AxiosAuthorized.put(api, { active: couponActive },);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async applyCoupon(code: string, amount: number) {
    try {
      const api = `${this.serviceEndpoint}/use/${code}`;

      const { data } = await AxiosAuthorized.put(api, { amount },);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }
}
