import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { user } from '../redux/reducers/userReducer';
import AccountService from '../services/account.service';
import { setAuthorizationHeader } from '../common/axios';

const AuthorizationMiddleware = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const userModel = await AccountService.info();
      if (userModel !== null) {
        dispatch(user(userModel));
        setAuthorizationHeader();
      }
    })();
  }, [dispatch]);

  return <></>;
};

export default AuthorizationMiddleware;
