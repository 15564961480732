import React, { useContext } from 'react';
import Select from '../Html/Select';
import { SliderList } from '../Html/SliderList';
import FormGroup from '../Html/FormGroup';
import Input from '../Html/Input';
import { IAdminBackofficeSearchContext } from '../../models/context';
import { MailServerContext } from '../../pages/admin/mail-servers/MailServers';
import useResize from '../../hooks/useResize';

const AdminBackofficeSearch = () => {
  const { dispatch } = useContext<Partial<IAdminBackofficeSearchContext>>(MailServerContext);
  const searchOpenDefaultState = useResize() > 1000;

  return (
    <div className='search-product-admin admin-search-mobile'>
      <SliderList title='Pretraga' defaultState={searchOpenDefaultState}>
        <div className='admin-inputes mobile-admin-inputs'>
          <FormGroup type='w-50'>
            <Input
              label='Email pošiljaoca'
              onChange={(e) =>
                dispatch!({
                  type: 'senderMail',
                  payload: e.target.value,
                })
              }
            />
            <Select
              label='Operativnost'
              hasNoValue={true}
              onChange={(e) =>
                dispatch!({
                  type: 'enabled',
                  payload: Number(e.target.value) === 1,
                })
              }
            >
              <option value='0'>Ne</option>
              <option value='1'>Da</option>
            </Select>
          </FormGroup>

          <FormGroup type='w-50'>
            <Input
              label='Host'
              onChange={(e) =>
                dispatch!({
                  type: 'host',
                  payload: e.target.value,
                })
              }
            />
            <Input
              label='Port'
              type='number'
              onChange={(e) =>
                dispatch!({
                  type: 'port',
                  payload: e.target.value,
                })
              }
            />
          </FormGroup>
        </div>
      </SliderList>

      <div className='buttons-align'>
        <div className='buttons-wrp'>
          <button
            className='button-el-classic reset-btn-admin'
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => {
              dispatch!({ type: 'reset' });
            }}
          >
            RESETUJ
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminBackofficeSearch;
