import { useState, useLayoutEffect, useRef } from 'react';
import '../../../styles/Admin.css';
import { isNullOrEmpty } from '../../../utils/strings';
import { modalError, modalErrorInput } from '../../../helpers/uiHelper';
import Constants from '../../../common/constants';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import MetaTags from '../../../components/Html/MetaTags';
import { SeoConfig } from '../../../helpers/seo-config';
import { useNavigate } from 'react-router-dom';
import { useDOMLoaderSequence } from '../../../hooks/useDOMLoaderSequence';
import MailServerService from '../../../services/mailserver.service';
import FormGroup from '../../../components/Html/FormGroup';
import Input from '../../../components/Html/Input';
import Select from '../../../components/Html/Select';

const AddMailServer = () => {
  const navigate = useNavigate();

  const [enabled, setEnabled] = useState<boolean>(true);

  const resErrorRef = useRef(document.createElement('span'));
  let inputs = document.querySelectorAll<HTMLInputElement>('.input-text');

  const enabledRef = useRef<HTMLSelectElement>(null);
  const hostRef = useRef<HTMLInputElement>(null);
  const portRef = useRef<HTMLInputElement>(null);
  const senderMailRef = useRef<HTMLInputElement>(null);
  const senderPasswordRef = useRef<HTMLInputElement>(null);
  const serviceTypeRef = useRef<HTMLInputElement>(null);

  useScrollOnLoad();

  useDOMLoaderSequence();

  useLayoutEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    inputs = document.querySelectorAll<HTMLInputElement>('.input-text');
  }, []);

  const addServer = async () => {
    let error = false;

    inputs.forEach((input) => {
      let spanModelError = document.getElementById(`error-${input.id}`) as any;

      if (isNullOrEmpty(input.value)) {
        modalErrorInput(spanModelError, Constants.fieldCannotBeEmpty);
        error = true;
      }

      if (input.id === 'port' && isNaN(parseFloat(input.value)) && !error) {
        modalErrorInput(spanModelError, Constants.portMustBeNumber);
        error = true;
      }
    });

    if (error) return;

    const host = hostRef.current!.value;
    const senderMail = senderMailRef.current!.value;
    const senderPassword = senderPasswordRef.current!.value;
    const port = parseFloat(portRef.current!.value);
    const serviceType = serviceTypeRef.current!.value;

    const server = {
      host,
      port,
      senderMail,
      senderPassword,
      serviceType,
      enabled,
    };

    let { data: productData, ok: productOk } = await MailServerService.add(server);

    if (!productOk) {
      modalError(resErrorRef, productData.message);
      return;
    }

    navigate('/admin/mail-servers');
  };

  return (
    <>
      <MetaTags data={SeoConfig.AddProduct} />
      <div
        className='sign-in-page register admin-panel-width edit-product-page add-product-page'
        id='admin-panel'
      >
        <section className='form add-page-form'>
          <h1>DODAJ NOVI SERVER</h1>

          <FormGroup type='no-wrapper'>
            <Input label='Host' id='host' elRef={hostRef} />
            <Input label='Port' id='port' elRef={portRef} type='numeric' />
          </FormGroup>

          <FormGroup type='no-wrapper'>
            <Input label='Emajl pošiljaoca' id='sender-mail' elRef={senderMailRef} />
            <Input label='Lozinka' id='sender-password' elRef={senderPasswordRef} />
          </FormGroup>

          <FormGroup type='no-wrapper'>
            <Select
              label='Operativan'
              elRef={enabledRef}
              id='enabled'
              hasNoValue={true}
              onChange={(x) => setEnabled(Number(x.target.value) === 1)}
            >
              <option value={1}>Da</option>
              <option value={0}>Ne</option>
            </Select>
            <Input label='Tip servisa' id='service-type' elRef={serviceTypeRef} />
          </FormGroup>

          <button onClick={addServer}>KREIRAJ</button>
          <span ref={resErrorRef} className='modal-error'></span>
        </section>
      </div>
    </>
  );
};

export default AddMailServer;
