import { AxiosAuthorized } from "../common/axios";


export default class WishlistService {
  private static serviceEndpoint: string = `/wishlist`;

  public static async getAll() {
    try {
      const api = `${this.serviceEndpoint}/all`;

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async save(productId: number) {
    try {
      const api = `${this.serviceEndpoint}/save`;

      const { data } = await AxiosAuthorized.post(
        api,
        { productId: productId },
      );

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async remove(productId: number) {
    try {
      const api = `${this.serviceEndpoint}/remove/${productId}`;

      const { data } = await AxiosAuthorized.delete(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async removeAll() {
    try {
      const api = `${this.serviceEndpoint}/remove-all`;

      const { data } = await AxiosAuthorized.delete(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async isSaved(productId: number) {
    try {
      const api = `${this.serviceEndpoint}/is-saved/${productId}`;

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }
}
