import '../../styles/BurgerMenu.css';
import useUser from '../../hooks/useUser';
import React from 'react';
import { UserRole } from '../../common/enums';
import BuyerUI from '../../components/BurgerMenu/BuyerUI';
import { AiOutlineClose } from 'react-icons/ai';
import AdminUI from '../../components/BurgerMenu/AdminUI';

interface IComponentProps {
  setOpen: any;
  burgerMenuRef: React.RefObject<HTMLDivElement>;
}

const BurgerMenu = ({ setOpen, burgerMenuRef }: IComponentProps) => {
  const user = useUser();
  const isBuyer = user?.roleId === UserRole.Buyer || !user;

  return (
    <div ref={burgerMenuRef} className='burger-menu'>
      <div className='burger-logo'>
        <AiOutlineClose
          size={35}
          onClick={() => {
            setOpen(false);
            document.body.style.overflow = 'visible';
          }}
        />
      </div>
      {isBuyer ? <BuyerUI setOpen={setOpen} /> : <AdminUI setOpen={setOpen} />}
    </div>
  );
};

export default BurgerMenu;
