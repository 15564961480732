import Constants from "../common/constants";
import { hasOnlyNumbers, hasOnlySpecialCharacters, hasOnlySpecialCharactersAndNumbers, hasSpace, validateUsername } from "../utils/strings";

export const formatPrice = (price: number): string => {
  const formattedPrice = price.toLocaleString("sr-RS", {
    style: "currency",
    currency: "RSD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formattedPrice.replace(",", ".");
};

export const formatDate = (dateTimeParam: Date): string => {
  const dateTime = new Date(dateTimeParam);

  let date: string = dateTime.getDate().toString();
  let month: string = (dateTime.getMonth() + 1).toString();
  let year: string = dateTime.getFullYear().toString();

  let hour: string = dateTime.getHours().toString();
  let minute: string = dateTime.getMinutes().toString();

  if (parseInt(date) < 10) date = `0${date}`;
  if (parseInt(month) < 10) month = `0${month}`;
  if (parseInt(hour) < 10) hour = `0${hour}`;
  if (parseInt(minute) < 10) minute = `0${minute}`;

  return `${date}.${month}.${year} ${hour}:${minute}`;
};

export const formatDateTime = (dateTimeParam: Date): string => {
  const dateTime = new Date(dateTimeParam);

  let date: string = dateTime.getDate().toString();
  let month: string = (dateTime.getMonth() + 1).toString();
  let year: string = dateTime.getFullYear().toString();

  let hour: string = dateTime.getHours().toString();
  let minute: string = dateTime.getMinutes().toString();
  let second: string = dateTime.getSeconds().toString();

  if (parseInt(date) < 10) date = `0${date}`;
  if (parseInt(month) < 10) month = `0${month}`;
  if (parseInt(hour) < 10) hour = `0${hour}`;
  if (parseInt(minute) < 10) minute = `0${minute}`;
  if (parseInt(second) < 10) second = `0${second}`;

  return `${date}.${month}.${year} ${hour}:${minute}:${second}`;
};

export const formatDateOnly = (dateTimeParam: Date): string => {
  const dateTime = new Date(dateTimeParam);

  let date: string = dateTime.getDate().toString();
  let month: string = (dateTime.getMonth() + 1).toString();
  let year: string = dateTime.getFullYear().toString();


  if (parseInt(date) < 10) date = `0${date}`;
  if (parseInt(month) < 10) month = `0${month}`;

  return `${date}.${month}.${year}`;
};


export const modalError = (element: any, message: string) => {
  element.current.style.display = "block";
  element.current.textContent = message;

  let timeout = setTimeout(() => {
    if (element.current !== null && element.current !== undefined) {
      element.current.style.display = "none";
      element.current.textContent = "none";
      clearTimeout(timeout);
    }
  }, 2000);
};

export const modalErrorInput = (element: HTMLSpanElement, message: string) => {
  if (element === null || element === undefined) return;

  element.style.display = "block";
  element.textContent = message;

  let timeout = setTimeout(() => {
    element.style.display = "none";
    element.textContent = "none";
    clearTimeout(timeout);
  }, 2000);
};

export const getQueryParamsForStore = (dispatch: any, sp: URLSearchParams) => {
  const genders =
    sp
      .get("genders")
      ?.split(",")
      ?.map((x: any) => Number(x)) ?? null;
  const sizes =
    sp
      .get("sizes")
      ?.split(",")
      ?.map((x: any) => Number(x)) ?? null;

  const categories = sp.get("categories")?.split(",") ?? null;
  const title = sp.get("title") ?? null;

  const sortBy = sp.get("sortBy") ?? "title";
  const sortDirection = sp.get("sortDirection") ?? "ASC";

  const searchType = sp.get("searchType") ?? null;

  const state = {
    genders,
    sizes,
    categories,
    sortBy,
    sortDirection,
    title,
    searchType
  };

  dispatch({
    type: "all",
    payload: state,
  });
};

export const isUsernameValid = (username: string, usernameErrorRef: React.MutableRefObject<HTMLSpanElement>): boolean => {
  if (hasSpace(username)) {
    modalError(usernameErrorRef, Constants.UsernameCantContainSpace);
    return false;
  }

  if (
    hasOnlyNumbers(username) ||
    hasOnlySpecialCharacters(username) ||
    hasOnlySpecialCharactersAndNumbers(username)
  ) {
    modalError(usernameErrorRef, Constants.UsernameMustContainLetters);
    return false;
  }

  if (!validateUsername(username)) {
    modalError(usernameErrorRef, Constants.UsernameInvalidFormat);
    return false;
  }

  if (username.length < 3) {
    modalError(usernameErrorRef, Constants.UsernameIsTooShort);
    return false;
  }

  return true;
}