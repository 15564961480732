import { Helmet as Head } from 'react-helmet';
import { ISEO } from '../../helpers/seo-config';

interface IComponentProps {
  data: ISEO;
}

const MetaTags: React.FC<IComponentProps> = ({ data }) => {
  const title = `Obuća BIG - ${data.title}`;

  return (
    <Head>
      <title>{title}</title>
      <meta name='twitter:title' content={title} />
      <meta property='og:title' content={title} />
      <meta property='og:url' content={`https://obucabig.com${data.route}`} />
      <meta itemProp='name' content={title} />
      <meta itemProp='title' content={title} />
      <meta itemProp='url' content={data.route} />
      <meta name='apple-mobile-web-app-title' content={data.title} />
    </Head>
  );
};

export default MetaTags;
