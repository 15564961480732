import { useState, useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import HeaderPhone from './Header/HeaderPhone';
import BurgerMenu from './BurgerMenu/BurgerMenu';
import useResizeEvent from '../hooks/useResizeEvent';
import useBurgerMenu from '../hooks/useBurgerMenu';
import { useDispatch, useSelector } from 'react-redux';
import { ISavedProduct } from '../models/product-base';
import { RootState } from '../redux/store';
import { loadProducts } from '../redux/reducers/shoppingCartReducer';
import SearcMobile from './BurgerMenu/SearchMobile';
import useUser from '../hooks/useUser';
import { UserRole } from '../common/enums';
import AdminSidebar from './Sidebar/AdminSidebar';
import AdminHeader from './Header/AdminHeader';
import ScrollToTop from './Partial/ScrollToTop';

const MasterLayout = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const MAX_PHONE_WIDTH: number = 1000;

  const products: ISavedProduct[] = useSelector((x: RootState) => x.shoppingCart.products);

  const user = useUser();

  const shouldResize = useResizeEvent(MAX_PHONE_WIDTH);
  const burgerMenuRef = useBurgerMenu(open);
  const searchRef = useBurgerMenu(openSearch);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (products.length < 1) {
      let storage = localStorage.getItem('cart');
      if (storage === null || storage === undefined) return;
      dispatch(loadProducts(JSON.parse(storage)));
    }
  }, [products, dispatch]);

  const hasAdminRights = user?.roleId === UserRole.Admin || user?.roleId === UserRole.Employee;

  return (
    <>
      <ScrollToTop />
      {shouldResize === true ? (
        user && hasAdminRights ? (
          <AdminHeader />
        ) : (
          <Header />
        )
      ) : user && hasAdminRights ? (
        <>
          <BurgerMenu setOpen={setOpen} burgerMenuRef={burgerMenuRef} />
          <HeaderPhone setOpen={setOpen} setOpenSearch={setOpenSearch} />
        </>
      ) : (
        <>
          <BurgerMenu setOpen={setOpen} burgerMenuRef={burgerMenuRef} />
          <SearcMobile setOpen={setOpenSearch} searchMenuRef={searchRef} />
          <HeaderPhone setOpen={setOpen} setOpenSearch={setOpenSearch} />
        </>
      )}
      {user && hasAdminRights && <AdminSidebar />}
      <Outlet />
      {user && hasAdminRights ? <></> : <Footer />}
    </>
  );
};

export default MasterLayout;
