import { useRef, useState, useEffect } from 'react';
import MetaTags from '../../components/Html/MetaTags';
import { SeoConfig } from '../../helpers/seo-config';
import { useScrollOnLoad } from '../../hooks/useScrollOnLoad';
import { modalError } from '../../helpers/uiHelper';
import OrderService from '../../services/order.service';
import { isNullOrEmpty } from '../../utils/strings';
import Constants from '../../common/constants';
import { OrderStatuses } from '../../common/data';
import Notification from '../../components/Html/Notification';

const CheckOrderStatus = () => {
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
        setMessage('');
        setIsError(false);
      }, 3000);
    }
  }, [show]);

  useScrollOnLoad();

  const uniqueId = useRef(document.createElement('input'));
  const emailRef = useRef(document.createElement('input'));

  const uniqueIdErrorRef = useRef(document.createElement('span'));
  const emailErrorRef = useRef(document.createElement('span'));

  const check = async () => {
    const orderId = uniqueId.current.value;
    const email = emailRef.current.value;

    if (isNullOrEmpty(orderId)) {
      modalError(uniqueIdErrorRef, Constants.OrderIdRequired);
      return;
    }

    if (isNaN(Number(orderId))) {
      modalError(uniqueIdErrorRef, Constants.OrderIdMustBeNumber);
      return;
    }

    if (isNullOrEmpty(email)) {
      modalError(emailErrorRef, Constants.emailCannotBeEmpty);
      return;
    }

    if (!email.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
      modalError(emailErrorRef, Constants.emailIsNotValid);
      return;
    }

    let { data, ok } = await OrderService.checkStatus(orderId, email);

    setShow(true);
    setIsError(ok);

    if (ok === false) {
      setMessage(data.message);
    } else {
      setMessage(`${Constants.YourOrderStatus}${OrderStatuses[data.status]}`);
    }
  };

  return (
    <>
      <MetaTags data={SeoConfig.OrderStatus} />
      <div className='sign-in-page'>
        <section className='form'>
          <h1>Status porudžbine</h1>
          <div className='input-element'>
            <label>Jedinstveni broj</label>
            <input
              ref={uniqueId}
              type='text'
              name=''
              id='uniqueId'
              placeholder='Jedinstveni broj'
            />
            <span className='modal-error' ref={uniqueIdErrorRef}></span>
          </div>
          <div className='input-element'>
            <label>Email</label>
            <input ref={emailRef} type='text' name='' id='email' placeholder='Email' />
            <span className='faded-note'>
              <span style={{ color: '#fa0a32' }}>*</span>Potrebno je uneti emajl koji ste ostavili
              kao kontakt podatak prilikom naručivanja.
            </span>
            <span className='modal-error' ref={emailErrorRef}></span>
          </div>
          {show === false && <button onClick={check}>PROVERI</button>}
          {show === true && <Notification title={message} isOk={isError} />}
        </section>
      </div>
    </>
  );
};

export default CheckOrderStatus;
