import React from 'react';
import { OrderStatuses } from '../../common/data';
import { SliderList } from '../Html/SliderList';
import useResize from '../../hooks/useResize';
import FormGroup from '../Html/FormGroup';
import Input from '../Html/Input';
import Select from '../Html/Select';

interface IComponentProps {
  dispatch: React.Dispatch<any>;
}

const AdminBackofficeSearch: React.FC<IComponentProps> = ({ dispatch }) => {
  const searchOpenDefaultState = useResize() > 1000;

  return (
    <div className='search-product-admin admin-search-mobile'>
      <SliderList title='Pretraga' defaultState={searchOpenDefaultState}>
        <div className='admin-inputes mobile-admin-inputs'>
          <FormGroup>
            <Input
              label='Broj porudžbine'
              onChange={(e) => dispatch({ type: 'orderId', payload: e.target.value })}
            />
            <Select
              hasNoValue={true}
              label='Status porudžbine'
              onChange={(e) => dispatch({ type: 'status', payload: e.target.value })}
            >
              {OrderStatuses.map((x, i) => (
                <option key={i} value={i}>
                  {x}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Input
              label='Okrug'
              onChange={(e) => dispatch({ type: 'county', payload: e.target.value })}
            />
            <Input
              label='Grad'
              onChange={(e) => dispatch({ type: 'city', payload: e.target.value })}
            />
          </FormGroup>

          <FormGroup>
            <Input
              label='Adresa'
              onChange={(e) => dispatch({ type: 'address', payload: e.target.value })}
            />
            <Input
              label='Poštanski broj'
              onChange={(e) => dispatch({ type: 'zip', payload: e.target.value })}
            />
          </FormGroup>

          <FormGroup>
            <Input
              label='Mejl'
              onChange={(e) => dispatch({ type: 'email', payload: e.target.value })}
            />
            <Input
              label='Broj telefona'
              onChange={(e) => dispatch({ type: 'phone', payload: e.target.value })}
            />
          </FormGroup>
        </div>
      </SliderList>

      <div className='buttons-align'>
        <div className='buttons-wrp'>
          <button
            className='button-el-classic reset-btn-admin'
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => dispatch({ type: 'reset' })}
          >
            RESETUJ
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminBackofficeSearch;
