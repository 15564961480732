import React, { useContext } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { resultsPerPageMapping } from '../../common/data';
import { ISortModel } from '../../models/search';
import { PaginationContext } from './Pagination';
import { UserRole } from '../../common/enums';
import useUser from '../../hooks/useUser';

const PaginationDesktop: React.FC = () => {
  const user = useUser();
  const { context, sortCategories, actionButton } = useContext(PaginationContext);

  const {
    resultsPerPage,
    currentPage,
    maxNumberOfPages,
    sort,
    setSort,
    setDeleteCurrentPage,
    setResultsPerPage,
    setCurrentPage,
  } = useContext(context);

  const previousPage = () => {
    setCurrentPage(currentPage === 0 ? 0 : currentPage - 1);
    setDeleteCurrentPage(false);

    if (currentPage !== 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const nextPage = () => {
    setDeleteCurrentPage(false);
    let maxPageCount = maxNumberOfPages - 1;

    if (currentPage < maxPageCount) {
      setCurrentPage(currentPage + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const sortByUpdate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const sortBy = e.target.value;
    const selected = sortCategories.find((x) => x.title === sortBy);

    setSort(selected as ISortModel);
  };

  const perPageUpdate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setResultsPerPage(Number(e.target.value));
    setDeleteCurrentPage(true);
  };

  const cssBuyer = user?.roleId === UserRole.Buyer ? ' buyer-search-top' : '';

  return (
    <div className={`backoffice-options admin-desktop-sort-options ${cssBuyer}`}>
      <div className='space-items'>
        <div className='pagination'>
          <button className='pagination-block' onClick={previousPage}>
            <FaChevronLeft />
          </button>
          <div className='pagination-block'>{currentPage + 1}</div>
          <button className='pagination-block' onClick={nextPage}>
            <FaChevronRight />
          </button>
        </div>
        <div className='results-per-page'>
          <p>Po stanici:</p>
          <select value={resultsPerPage} onChange={perPageUpdate}>
            {resultsPerPageMapping.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='results-per-page sort'>
        <p>Sortiraj:</p>
        <select value={sort.title} onChange={sortByUpdate}>
          {sortCategories.map((x, idnex) => (
            <option value={x.title} key={idnex}>
              {x.title}
            </option>
          ))}
        </select>
      </div>
      {actionButton?.available && (
        <button
          className='button-el-classic add-new-btn'
          style={{ marginLeft: 10, marginRight: 10 }}
          onClick={actionButton.onClick}
        >
          {actionButton.text}
        </button>
      )}
    </div>
  );
};

export default PaginationDesktop;
