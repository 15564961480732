import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import '../../styles/SignIn.css';
import { modalError } from '../../helpers/uiHelper';
import Constants from '../../common/constants';
import { isNullOrEmpty } from '../../utils/strings';
import useUser from '../../hooks/useUser';
import AccountService from '../../services/account.service';
import { useDispatch } from 'react-redux';
import { user as userReducer } from '../../redux/reducers/userReducer';
import { UserRole } from '../../common/enums';
import Notification from '../Html/Notification';

const EditContact = () => {
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [isOk, setIsOk] = useState<boolean>(false);
  const [enableSaveButton, setEnableSaveButton] = useState<boolean>(true);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
        setIsOk(false);
        setMessage('');
      }, 2500);
    }

    setTimeout(() => {
      setEnableSaveButton(true);
    }, 2600);
  }, [show]);

  const firstnameRef = useRef(document.createElement('input'));
  const lastnameRef = useRef(document.createElement('input'));
  const emailRef = useRef(document.createElement('input'));
  const phoneRef = useRef(document.createElement('input'));
  const countyRef = useRef(document.createElement('input'));
  const cityRef = useRef(document.createElement('input'));
  const addressRef = useRef(document.createElement('input'));
  const zipRef = useRef(document.createElement('input'));

  const firstnameErrorRef = useRef(document.createElement('span'));
  const lastnameErrorRef = useRef(document.createElement('span'));
  const emailErrorRef = useRef(document.createElement('span'));
  const phoneErrorRef = useRef(document.createElement('span'));
  const countyErrorRef = useRef(document.createElement('span'));
  const cityErrorRef = useRef(document.createElement('span'));
  const addressErrorRef = useRef(document.createElement('span'));
  const zipErrorRef = useRef(document.createElement('span'));

  const user = useUser();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    firstnameRef.current.value = user?.firstname as string;
    lastnameRef.current.value = user?.lastname as string;
    emailRef.current.value = user?.email as string;
    phoneRef.current.value = user?.phone as string;
    countyRef.current.value = user?.county as string;
    cityRef.current.value = user?.city as string;
    addressRef.current.value = user?.address as string;
    zipRef.current.value = user?.zip.toString() as string;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editContact = async () => {
    const firstname = firstnameRef.current.value;
    const lastname = lastnameRef.current.value;
    const email = emailRef.current.value;
    const phone = phoneRef.current.value;
    const county = countyRef.current.value;
    const city = cityRef.current.value;
    const address = addressRef.current.value;
    const zip = zipRef.current.value;

    if (isNullOrEmpty(firstname)) {
      modalError(firstnameErrorRef, Constants.firstnameCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(lastname)) {
      modalError(lastnameErrorRef, Constants.lastnameCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(email)) {
      modalError(emailErrorRef, Constants.emailCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(phone)) {
      modalError(phoneErrorRef, Constants.phoneCannotBeEmpty);
      return;
    }

    if (!email.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
      modalError(emailErrorRef, Constants.emailIsNotValid);
      return;
    }

    if (isNullOrEmpty(county)) {
      modalError(countyErrorRef, Constants.countyCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(city)) {
      modalError(cityErrorRef, Constants.cityCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(address)) {
      modalError(addressErrorRef, Constants.addressCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(zip)) {
      modalError(zipErrorRef, Constants.zipCannotBeEmpty);
      return;
    }

    if (isNaN(parseInt(zip))) {
      modalError(zipErrorRef, Constants.zipMustBeNumber);
      return;
    }

    if (
      firstname === user?.firstname &&
      lastname === user?.lastname &&
      email === user?.email &&
      phone === user?.phone &&
      county === user?.county &&
      city === user?.city &&
      address === user?.address &&
      parseInt(zip) === user?.zip
    ) {
      return;
    }

    setEnableSaveButton(false);

    const { data, ok } = await AccountService.editContact(
      firstname,
      lastname,
      email,
      phone,
      county,
      city,
      address,
      parseInt(zip),
    );

    if (ok) {
      setIsOk(true);
      setShow(true);
      setMessage(data.message);

      const userModel = await AccountService.info();
      if (userModel !== null) {
        dispatch(userReducer(userModel));
      }
    } else {
      setIsOk(false);
      setShow(true);
      setMessage(data.message);
    }
  };

  return (
    <div className='modal-data-list edit-account-modal'>
      <div className='inline-data'>
        <div className='content-wrapper'>
          <div className='input-element'>
            <label>Ime</label>
            <input ref={firstnameRef} type='text' name='' id='name' placeholder='Ime' />
            <span ref={firstnameErrorRef} className='modal-error'></span>
          </div>
          <div className='input-element'>
            <label>Prezime</label>
            <input ref={lastnameRef} type='text' name='' id='lastname' placeholder='Prezime' />
            <span ref={lastnameErrorRef} className='modal-error'></span>
          </div>

          {user?.roleId === UserRole.Buyer && (
            <>
              <div className='input-element'>
                <label>Okrug</label>
                <input ref={countyRef} type='text' name='' id='county' placeholder='Okrug' />
                <span ref={countyErrorRef} className='modal-error'></span>
              </div>
              <div className='input-element'>
                <label>Grad</label>
                <input ref={cityRef} type='text' name='' id='city' placeholder='City' />
                <span ref={cityErrorRef} className='modal-error'></span>
              </div>
            </>
          )}
        </div>

        <div className='content-wrapper'>
          <div className='input-element'>
            <label>Mejl</label>
            <input ref={emailRef} type='text' name='' id='mail' placeholder='Mejl' />
            <span ref={emailErrorRef} className='modal-error'></span>
          </div>
          <div className='input-element'>
            <label>Telefon</label>
            <input ref={phoneRef} type='text' name='' id='phone' placeholder='Telefon' />
            <span ref={phoneErrorRef} className='modal-error'></span>
          </div>

          {user?.roleId === UserRole.Buyer && (
            <>
              <div className='input-element'>
                <label>Poštanski broj</label>
                <input ref={zipRef} type='text' name='' id='zip' placeholder='Poštanski broj' />
                <span ref={zipErrorRef} className='modal-error'></span>
              </div>

              <div className='input-element'>
                <label>Adresa</label>
                <input ref={addressRef} type='text' name='' id='address' placeholder='Adresa' />
                <span ref={addressErrorRef} className='modal-error'></span>
              </div>
            </>
          )}
        </div>
      </div>
      {enableSaveButton && (
        <button onClick={editContact} className='save-edit-acc-btn'>
          SAČUVAJ
        </button>
      )}
      <div style={{ width: '93%', marginTop: !enableSaveButton ? 10 : 0 }}>
        {show && enableSaveButton === false && <Notification isOk={isOk} title={message} />}
      </div>
    </div>
  );
};

export default EditContact;
