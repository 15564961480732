import React from 'react';
import { SliderList } from '../../components/Html/SliderList';
import { Link } from 'react-router-dom';
import { MdLocationPin } from 'react-icons/md';
import { AiFillPhone, AiFillMail, AiOutlineInstagram, AiOutlineClockCircle } from 'react-icons/ai';
import { FaFacebook } from 'react-icons/fa';
import '../../styles/Footer.css';
import useUser from '../../hooks/useUser';
import { UserRole } from '../../common/enums';

const SitemapMobile = () => {
  const user = useUser();

  return (
    <>
      <div className='social-media-block'>
        <h3>DRUŠTVENE MREŽE</h3>
        <div className='social-media'>
          <a href='https://www.facebook.com/obucabig' target='_blank' rel='noreferrer'>
            <div className='social-media-icon'>
              <FaFacebook />
            </div>
          </a>
          <a href=' https://www.instagram.com/obucabig' target='_blank' rel='noreferrer'>
            <div className='social-media-icon'>
              <AiOutlineInstagram />
            </div>
          </a>
        </div>
      </div>
      <SliderList title='Korisnički nalog'>
        <div className='sitemap-block'>
          <Link to='/'>Početna</Link>
          {user === null && (
            <>
              <Link to='/sign-in'>Prijava</Link>
              <Link to='/register'>Registruj se</Link>
              <Link to='/forgot-password'>Zaboravljena lozinka?</Link>
            </>
          )}
          {user !== null && user.roleId === UserRole.Buyer && (
            <>
              <Link to='/account'>Nalog</Link>
              <Link to='/my-orders'>Porudžbine</Link>
              <Link to='/wishlist'>Lista želja</Link>
            </>
          )}
          {user !== null && user.roleId === UserRole.Admin && (
            <>
              <Link to='/account'>Nalog</Link>
              <Link to='/admin/products'>Proizvodi</Link>
              <Link to='/admin/orders'>Porudžbine</Link>
              <Link to='/admin/coupons'>Kuponi</Link>
              <Link to='/admin/users'>Korisnici</Link>
            </>
          )}
        </div>
      </SliderList>
      <SliderList title='Kupovina'>
        <div className='sitemap-block'>
          <Link to='/store'>Prodavnica</Link>
          <Link to='/store?searchType=new-collection'>Nova kolekcija</Link>
          <Link to='/store?searchType=discount'>Sniženje</Link>
          <Link to='/store?genders=0'>Muško</Link>
          <Link to='/store?genders=1'>Žensko</Link>
          <Link to='/store?genders=2'>Dečije</Link>
          <Link to='/shopping-cart'>Korpa</Link>
          <Link to='/check-order-status'>Status porudžbine</Link>
        </div>
      </SliderList>
      <SliderList title='Kontakt'>
        <div className='sitemap-block'>
          <div className='location-data'>
            <span>
              <AiFillMail size={23} style={{ marginRight: 4 }} /> Mejl
            </span>
            <p>
              <a href='mailto:obucabig@gmail.com'>obucabig@gmail.com</a>
            </p>
          </div>
          <div className='location-data'>
            <span>
              <AiFillPhone size={23} style={{ marginRight: 4 }} /> Telefon
            </span>
            <p>
              <a href='tel:+38166048905'>+381 66 048 905</a>
            </p>
          </div>
          <div className='location-data'>
            <span>
              <AiOutlineClockCircle size={23} style={{ marginRight: 4 }} /> Radno vreme
            </span>
            <p>Pon-Pet: 08:00 - 20:30</p>
          </div>
          <br />
        </div>
      </SliderList>
      <SliderList title='Prodavnice'>
        <div className='sitemap-block'>
          <div className='location-data'>
            <span>
              <MdLocationPin size={23} style={{ marginRight: 4 }} /> Niš
            </span>
            <p>Bul. Nemanjica 25a lok. 33</p>
          </div>
          <div className='location-data'>
            <span>
              <MdLocationPin size={23} style={{ marginRight: 4 }} /> Pirot
            </span>
            <p>Knjaza Miloša 74</p>
          </div>
          <div className='location-data'>
            <span>
              <MdLocationPin size={23} style={{ marginRight: 4 }} /> Kruševac
            </span>
            <p>Vidovdanska 205</p>
          </div>
        </div>
      </SliderList>
    </>
  );
};

export default SitemapMobile;
