import React, { useState, useContext } from 'react';
import Modal from '../Html/Modal';
import Notification from '../Html/Notification';
import { IAdminBackofficeSearchContext } from '../../models/context';
import WebImagesService from '../../services/webimages.service';
import { WebImagesContext } from '../../pages/admin/webimages/WebImages';

interface IComponentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
}

const DeleteWebImageModal: React.FC<IComponentProps> = ({ isOpen, setIsOpen, id }) => {
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const { setDeleteCurrentPage, dispatch } =
    useContext<Partial<IAdminBackofficeSearchContext>>(WebImagesContext);

  const deleteImage = async () => {
    const { data, ok } = await WebImagesService.delete(id);

    if (ok) {
      setIsOpen(false);
      setDeleteCurrentPage!(true);
      dispatch!({ type: 'reset' });
    }

    setError(!ok);
    setMessage(data?.message ?? '');

    setTimeout(() => {
      setError(false);
      setMessage('');
    }, 2000);
  };

  return (
    <Modal title='IZBRIŠI SLIKU' isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='delete-acc-modal'>
        <p>Da li ste sigurni da želite da izbrišete ovu sliku?</p>
        {error === false ? (
          <div className='buttons-delete'>
            <button className='delete-btn' onClick={deleteImage}>
              IZBRIŠI
            </button>
            <button onClick={() => setIsOpen(false)}>OTKAŽI</button>
          </div>
        ) : (
          <Notification title={message} isOk={false} />
        )}
      </div>
    </Modal>
  );
};

export default DeleteWebImageModal;
