import React, { useState, useLayoutEffect } from 'react';
import { CgUnavailable } from 'react-icons/cg';

const NoResultsFound = () => {
  const [render, setRender] = useState<boolean>(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setRender(true);
    }, 100);
  }, []);

  return render ? (
    <div className='empty-shoppinh-cart'>
      <CgUnavailable className='empty-icon' />
      <h1>NEMA REZULTATA PRETRAGE</h1>

      <p>Trenutno nema ni jedan rezultat koji odgovara unetim kriterijumima pretrage.</p>
    </div>
  ) : (
    <></>
  );
};

export default NoResultsFound;
