import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUser from '../hooks/useUser';
import { UserRole } from '../common/enums';
import { setAuthorizationHeader } from '../common/axios';

interface IComponentProps {
  component: JSX.Element;
  authorize: UserRole[];
}

const ProtectedRoute: React.FC<IComponentProps> = ({ component, authorize }) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    const token = localStorage.getItem('_token');

    setAuthorizationHeader();

    const unauthorized = token === null && !user;
    const accessDenied = !unauthorized && user && !authorize.includes(user.roleId);

    if (!unauthorized && accessDenied) {
      return navigate('/');
    }

    if (unauthorized || accessDenied) {
      const fallbackUrl = window.location.pathname;
      const fallback = fallbackUrl !== null ? `?fallbackUrl=${fallbackUrl}` : '';
      const returnUrl = !accessDenied ? fallback : '';

      return navigate(`/sign-in` + returnUrl);
    }

    setIsAuthorized(true);
  }, [user, navigate, authorize]);

  return <>{isAuthorized && component}</>;
};


export default ProtectedRoute;
