import { Gender } from "../common/enums";

export class StoreSearchCriteriaLoaderFilter {
  genders?: string;
  brands?: string;
  categories?: string;
  sizes?: string;
  minPrice?: number;
  maxPrice?: number;
  searchType?: string | undefined | null | any;
  season?: string;

  [key: string]: string | number | undefined;

  constructor(sp: any, priceRange_?: any, searchType_?: any) {
    this.searchType = searchType_;

    const properties = ['genders', 'brands', 'categories', 'sizes', 'seasons'];
    properties.forEach(property => {
      if (sp[property]?.length > 0) {
        this[property] = sp[property].join(',');
      }
    });

    if (priceRange_?.length === 2) {
      [this.minPrice, this.maxPrice] = priceRange_;
    }
  }
}

export class FilterStoreProducts {
  genders?: Gender[];
  sizes?: number[];
  categories?: string[];
  sortBy?: string;
  sortDirection?: string;
  title?: string;
  resultsPerPage?: number;
  pageNumber?: number;
  searchType?: string;
  minPrice?: string;
  maxPrice?: string;
  brands?: string[] | null;
  seasons?: string[]

  constructor(pageNumber_: number, resultsPerPage_: number, sp: any, priceRange_?: any) {
    if (sp) {
      this.sortBy = sp.sortBy || this.sortBy;
      this.sortDirection = sp.sortDirection || this.sortDirection;
      this.pageNumber = pageNumber_;
      this.resultsPerPage = resultsPerPage_;
      this.genders = sp.genders?.join(",") || this.genders;
      this.brands = sp.brands?.join(",") || this.brands;
      this.sizes = sp.sizes?.join(",") || this.sizes;
      this.categories = sp.categories?.join(",") || this.categories;
      this.title = sp.title?.trim()?.toLowerCase()?.replaceAll(' ', '') || this.title;
      this.searchType = sp.searchType || this.searchType;
      this.seasons = sp.seasons?.join(",") || this.seasons;

      if (priceRange_?.length === 2) {
        [this.minPrice, this.maxPrice] = [priceRange_[0].toString(), priceRange_[1].toString()];
      }
    }
  }
}