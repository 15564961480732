import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { IWebImageBase } from '../../models/web-images';
import { WebImagePosition } from '../../common/enums';

interface IComponentProps {
  images: IWebImageBase[];
}

const Banner: React.FC<IComponentProps> = ({ images }) => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className='mySwiper'
      >
        {images
          .filter((x) => x.imagePosition === WebImagePosition.Banner)
          .sort((a, b) => a.displayIndex - b.displayIndex)
          .map((image, i) => (
            <SwiperSlide key={i}>
              <img src={image.url} alt='Obuća BIG početna stranica' className='banner-img' />
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};

export default Banner;
