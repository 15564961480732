import React, { useState, useContext } from 'react';
import Modal from '../Html/Modal';
import Notification from '../Html/Notification';
import { IAdminBackofficeSearchContext } from '../../models/context';
import SettingsService from '../../services/settings.service';
import Input from '../Html/Input';
import Constants from '../../common/constants';
import { SettingsContext } from '../../pages/admin/settings/Settings';

interface IComponentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
  title: string;
}

const DeleteSettingModal: React.FC<IComponentProps> = ({ isOpen, setIsOpen, id, title }) => {
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [enabled, setEnabled] = useState<boolean>(false);

  const { setDeleteCurrentPage, dispatch } =
    useContext<Partial<IAdminBackofficeSearchContext>>(SettingsContext);

  const deleteImage = async () => {
    const { data, ok } = await SettingsService.delete(id);

    if (ok) {
      setIsOpen(false);
      setDeleteCurrentPage!(true);
      dispatch!({ type: 'reset' });
    }

    setError(!ok);
    setMessage(data?.message ?? '');

    setTimeout(() => {
      setError(false);
      setMessage('');
    }, 2000);
  };

  return (
    <Modal title='IZBRIŠI PODEŠAVANJE' isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='delete-acc-modal'>
        <Notification isOk={false} title={Constants.DeleteSettingConfirmation} />
        <table className='server-delete-data'>
          <tbody>
            <tr>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <td>Naziv:</td>
              <td style={{ fontWeight: 'bold' }}>{title}</td>
            </tr>
          </tbody>
        </table>
        <Input
          label='Unesite naziv podešavanja'
          onChange={(e) => {
            if (e.target.value === title) {
              setEnabled(true);
            } else {
              setEnabled(false);
            }
          }}
        />
        {error === false ? (
          <div className='buttons-delete'>
            <button className={`delete-btn ${enabled ? '' : 'freez-btn'}`} onClick={deleteImage}>
              IZBRIŠI
            </button>
            <button onClick={() => setIsOpen(false)}>OTKAŽI</button>
          </div>
        ) : (
          <Notification title={message} isOk={false} />
        )}
      </div>
    </Modal>
  );
};

export default DeleteSettingModal;
