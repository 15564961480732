import React, { useState } from 'react';
import { formatPrice } from '../../../helpers/uiHelper';
import { genders } from '../../../common/data';
import { IAdminProduct } from '../../../models/product';
import { AiFillEdit, AiOutlineDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { MdOutlineImageNotSupported } from 'react-icons/md';
import { isNullOrWhiteSpace } from '../../../utils/strings';
import DeleteProductModal from './DeleteProductModal';

interface IComponentProps {
  product: IAdminProduct;
}

const AdminProductMobile: React.FC<IComponentProps> = ({ product }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <>
      <DeleteProductModal isOpen={isOpen} setIsOpen={setIsOpen} productId={product.id} />
      <div className='admin-product-mobile'>
        <div className='headline-and-category'>
          <div className='mobile-product-titles'>
            <h1>{product.title}</h1>
            <h3>{product.category}</h3>
          </div>
          <div className='product-btns-mobile'>
            <AiFillEdit
              className='edit-product-btn-mobile'
              onClick={() => navigate(`/admin/products/edit/${product.id}`)}
            />
            {product.soldCount === 0 && (
              <AiOutlineDelete
                className='edit-product-btn-mobile delete-product'
                onClick={() => setIsOpen(true)}
              />
            )}
          </div>
        </div>
        <div className='image-and-base-product-info-admin'>
          {isNullOrWhiteSpace(product.image) ? (
            <div className='no-image-mobile'>
              <MdOutlineImageNotSupported size={65} />
            </div>
          ) : (
            <img
              src={product.image}
              alt={product.title}
              loading='lazy'
              decoding='async'
              className='admin-product-image-mobile'
            />
          )}
          <div className='admin-product-info-base'>
            <span className='mobile-base-info'>
              Šifra proizvoda:
              <span style={{ color: '#fa0a32' }}> {product.code}</span>
            </span>
            <span className='mobile-base-info'>
              Pol: {genders.find((x) => x.id === product.gender)?.name}
            </span>
            <span className='mobile-base-info'>Brend: {product.brand}</span>
            <span className='mobile-base-info'>Sezona: {product.season}</span>
            <span className='mobile-base-info'>
              Dostupnost:{' '}
              <span style={product.available === false ? { color: '#fa0a32' } : { color: "#16c784" }}>
                {product.available ? 'Dostupno' : 'Nije dostupno'}
              </span>
            </span>
          </div>
        </div>
        <div className='admin-product-sizes-mobile'>
          <h1>Veličine</h1>
          <div className='admin-product-sizes-mobile-mapping'>
            {product.sizes.map((sizeData, i) => (
              <div
                key={i}
                className='size-item'
                style={sizeData.count === 0 ? { backgroundColor: '#fa0a32', color: '#fff' } : {}}
              >
                <span>
                  <b>{sizeData.size}</b>
                </span>
                <span>({sizeData.count})</span>
              </div>
            ))}
          </div>
        </div>
        <div className='prices-mobile-product'>
          <div className='current-prices-mobile'>
            {product.discount !== null && product.discount !== 0 && (
              <h4 className='regular-price'>
                <s>{formatPrice(product.price)}</s>
              </h4>
            )}
            <h2>{formatPrice(product.price - product.price * ((product.discount ?? 0) / 100))}</h2>
          </div>
          <div className='product-mobile-right-info'>
            {product.discount !== null && product.discount !== 0 && (
              <div className='checkout-discount'>
                <span>-{product.discount}%</span>
              </div>
            )}
            {product.isNew && (
              <div className='checkout-discount' style={{ backgroundColor: '#000' }}>
                <span>NOVO</span>
              </div>
            )}
          </div>
        </div>
        <div className='product-statistics-mobile'>
          <div className='statistics-mobile-block'>
            <div className='statistic-mobile-data'>
              <h3>Prodato: </h3>
              <h4>{product.soldCount}</h4>
            </div>
            <div className='statistic-mobile-data'>
              <h3>Posećen: </h3>
              <h4>{product.views}</h4>
            </div>
          </div>
          <div className='statistics-mobile-block'>
            <div className='statistic-mobile-data'>
              <h3>Korpa: </h3>
              <h4>{product.cart}</h4>
            </div>
            <div className='statistic-mobile-data'>
              <h3>Lista želja: </h3>
              <h4>{product.wishlist}</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminProductMobile;
