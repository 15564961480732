import React, { useEffect } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

interface IComponentProps {
  isOpen: boolean;
}

const LoadingScreen: React.FC<IComponentProps> = ({ isOpen }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    window.addEventListener('popstate', () => (document.body.style.overflow = 'auto'));
  }, [isOpen]);

  return (
    <div className={`loading-screen ${isOpen ? 'show-loading' : 'hide-loading'}`}>
      <AiOutlineLoading3Quarters className='loading-icon' />
    </div>
  );
};

export default LoadingScreen;
