import React from 'react';
import { SliderList } from '../Html/SliderList';
import useResize from '../../hooks/useResize';
import Select from '../Html/Select';
import Input from '../Html/Input';
import FormGroup from '../Html/FormGroup';
import { UserRolesMap, VerificationStauses } from '../../common/data';

interface IComponentProps {
  dispatch: React.Dispatch<any>;
}

const AdminBackofficeSearch: React.FC<IComponentProps> = ({ dispatch }) => {
  const searchOpenDefaultState = useResize() > 1000;

  return (
    <div className='search-product-admin admin-search-mobile'>
      <SliderList title='Pretraga' defaultState={searchOpenDefaultState}>
        <div className='admin-inputes mobile-admin-inputs'>
          <FormGroup>
            <Select
              hasNoValue={true}
              label='Permisija'
              onChange={(e) => dispatch({ type: 'roleId', payload: e.target.value })}
            >
              {UserRolesMap.map((x, i) => (
                <option key={i} value={x.role}>
                  {x.title}
                </option>
              ))}
            </Select>
            <Select
              hasNoValue={true}
              label='Verifikovan'
              onChange={(e) => dispatch({ type: 'isVerified', payload: e.target.value })}
            >
              {VerificationStauses.map((x, i) => (
                <option key={i} value={x.value}>
                  {x.title}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Input
              label='Okrug'
              onChange={(e) => dispatch({ type: 'county', payload: e.target.value })}
            />
            <Input
              label='Grad'
              onChange={(e) => dispatch({ type: 'city', payload: e.target.value })}
            />
          </FormGroup>

          <FormGroup>
            <Input
              label='Adresa'
              onChange={(e) => dispatch({ type: 'address', payload: e.target.value })}
            />
            <Input
              label='Korisničko ime'
              onChange={(e) => dispatch({ type: 'username', payload: e.target.value })}
            />
          </FormGroup>

          <FormGroup>
            <Input
              label='Mejl'
              onChange={(e) => dispatch({ type: 'email', payload: e.target.value })}
            />
            <Input
              label='Broj telefona'
              onChange={(e) => dispatch({ type: 'phone', payload: e.target.value })}
            />
          </FormGroup>
        </div>
      </SliderList>

      <div className='buttons-align'>
        <div className='buttons-wrp'>
          <button
            className='button-el-classic reset-btn-admin'
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => dispatch({ type: 'reset' })}
          >
            RESETUJ
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminBackofficeSearch;
