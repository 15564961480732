import IEnvironment from "../models/environment";

const env = process.env;

const Environment: IEnvironment = {
  api: env.REACT_APP_SERVER as string,
  allowedMimeTypes: env.REACT_APP_ALLOWED_MIME_TYPES as string,
  apiKey: env.REACT_APP_API_KEY as string
};

export default Environment;
