const Constants = {
  usernameCannotBeEmpty: "Korisničko ime ne može biti prazno.",
  passwordCannotBeEmpty: "Lozinka ne može biti prazna.",
  emailCannotBeEmpty: "Email ne može biti prazan.",
  firstnameCannotBeEmpty: "Polje ime ne može biti prazno.",
  lastnameCannotBeEmpty: "Polje prezime ne može biti prazno.",
  phoneCannotBeEmpty: "Polje telefon ne može biti prazno.",
  countyCannotBeEmpty: "Polje okrug ne može biti prazno.",
  cityCannotBeEmpty: "Polje grad ne može biti prazno.",
  addressCannotBeEmpty: "Polje adresa ne može biti prazno.",
  zipCannotBeEmpty: "Polje poštanski broj ne može biti prazno.",
  zipMustBeNumber: "Polje poštanski broj ne moze sadržati slova.",
  checkboxCannotBeEmpty: "Checkbox mora biti čekiran.",
  checkboxCannotBeEmptyCheckout: "Neophodna je vaša saglasnost da se slažete sa politikom privatnosti.",
  fieldCannotBeEmpty: "Ovo polje ne može biti prazno.",
  newPasswordCannotBeSameAsOldPassword: "Nova šifra mora biti različita od stare šifre.",
  newPasswordConfirmMustBeSameAsNewPassword: "Lozinke se ne podudaraju.",
  emailIsNotValid: "Email nije u validnom formatu.",
  priceMustBeNumber: "Polje cena ne moze sadržati slova.",
  portMustBeNumber: "Polje port ne moze sadržati slova.",
  fieldCannotBeString: "Ovo polje mora sadržati samo brojeve.",
  fieldNotGoodFormat: "Ovo polje nije u dobrom formatu.",
  sizeMustBeSelected: "Izaberite veličinu.",
  resetPwdTokenInvalid: "Izgleda da je ovaj link istekao. Da bi ste resetovali lozinku potrebno je da na stranici 'Zaboravili ste lozinku?' unesete vaš emajl da bi vam poslali poruku sa linkom za resetovanje lozinke.",
  accountCreated: "Da bi ste nastavili proces registracije, potrebno je da pritisnete dugme 'Aktiviraj' u poruci koja vam je poslata na vašu emajl adresu kako bi ste verifikovali nalog.",
  accountVerificationNotRecived: "U koliko niste dobili mejl pritisnite dugme 'POŠALJI PONOVO' da bi vam ponovo poslali poruku za verifikaciju naloga na mejl.",
  OrderIdRequired: "Ovo polje je obavezno.",
  YourOrderStatus: "Status vaše porudžbine je ",
  OrderIdMustBeNumber: "Jedinstveni broj porudžbine mora biti broj i ne sme sadržati slova i specijalne karaktere.",
  UsernameCantContainSpace: "Korisničko ime ne sme da sadrži razmake.",
  UsernameInvalidFormat: "Korisničko ime sme sadržati samo velika i mala slova, brojeve i karaktere . i _",
  UsernameMustContainLetters: "Korisničko ime mora sadržati i slova.",
  EnterSenderMail: "Da bi ste izbrisali ovaj server neophodno je da u polju ispod unesete mejl pošiljaoca.",
  MailServerWarning: "Ova radnja zahteva opreznost! Ukoliko nemate preostalih operativnih mejl servera neće biti moguće slanje mejlova putem sajta. Korisnici neće moći da prave naloge jer verifikacija za naloga neće biti poslata na mejl, neće dobijati potvrde o porudžbinama niti informacije o promenu status porudžbine.",
  UsernameIsTooShort: "Korisničko ime ne sme biti kraće od 3 karaktera.",
  CloudinaryWarning: "Vaš Cloudinary nalog se nalazi u zoni upozorenja. Iskoristili ste {{PERCENT}}% vaših kredita. Poželjno je da sve slike koje postavljate budu što manje veličine kako ne bi ste prekoračili zonu upozorenja za Cloudinary servis.",
  CloudinaryDanger: "Vaš Cloudinary nalog se nalazi u opasnoj zoni. Iskoristili ste {{PERCENT}}% vaših kredita. Dodavanje, izmena i brisanje slika je blokirana kako bi ste izbegli troškove kao i blokiranje naloga. U slučaju da želite da izvršite sledeću akciju, kiknite ",
  DeleteSettingConfirmation: "Da li ste sigurni da želite da izbrišete ovo podešavanje? Da bi ste izbrisali ovo podešavanje neophodno je da unesete njegov naziv u polje ispod."
};

export default Constants;
