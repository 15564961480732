import { useRef, useState } from 'react';
import '../../styles/SignIn.css';
import { Link } from 'react-router-dom';
import IUserRegister from '../../models/user-register';
import AccountService from '../../services/account.service';
import { isNullOrEmpty } from '../../utils/strings';
import { isUsernameValid, modalError } from '../../helpers/uiHelper';
import Constants from '../../common/constants';
import AccountCreated from '../../components/Account/AccountCreated';
import { useScrollOnLoad } from '../../hooks/useScrollOnLoad';
import MetaTags from '../../components/Html/MetaTags';
import { SeoConfig } from '../../helpers/seo-config';
import { useUnauthorizedProtection } from '../../hooks/useUnauthorizedProtection';
import FormGroup from '../../components/Html/FormGroup';
import Input from '../../components/Html/Input';

const Register = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [acceptedPrivacyAndPolicy, setAcceptedPrivacyAndPolicy] = useState<boolean>(false);

  useScrollOnLoad();
  useUnauthorizedProtection(true);

  const firstnameRef = useRef(document.createElement('input'));
  const lastnameRef = useRef(document.createElement('input'));
  const emailRef = useRef(document.createElement('input'));
  const phoneRef = useRef(document.createElement('input'));
  const usernameRef = useRef(document.createElement('input'));
  const passwordRef = useRef(document.createElement('input'));
  const countyRef = useRef(document.createElement('input'));
  const cityRef = useRef(document.createElement('input'));
  const addressRef = useRef(document.createElement('input'));
  const zipRef = useRef(document.createElement('input'));

  const firstnameErrorRef = useRef(document.createElement('span'));
  const lastnameErrorRef = useRef(document.createElement('span'));
  const emailErrorRef = useRef(document.createElement('span'));
  const phoneErrorRef = useRef(document.createElement('span'));
  const usernameErrorRef = useRef(document.createElement('span'));
  const passwordErrorRef = useRef(document.createElement('span'));
  const countyErrorRef = useRef(document.createElement('span'));
  const cityErrorRef = useRef(document.createElement('span'));
  const addressErrorRef = useRef(document.createElement('span'));
  const zipErrorRef = useRef(document.createElement('span'));
  const checkboxErrorRef = useRef(document.createElement('span'));

  const resErrorRef = useRef<HTMLSpanElement>(null);

  const register = async () => {
    if (!acceptedPrivacyAndPolicy) {
      return;
    }

    const firstname = firstnameRef.current.value;
    const lastname = lastnameRef.current.value;
    const email = emailRef.current.value;
    const phone = phoneRef.current.value;
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    const county = countyRef.current.value;
    const city = cityRef.current.value;
    const address = addressRef.current.value;
    const zip = zipRef.current.value;

    if (isNullOrEmpty(firstname)) {
      modalError(firstnameErrorRef, Constants.firstnameCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(lastname)) {
      modalError(lastnameErrorRef, Constants.lastnameCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(email)) {
      modalError(emailErrorRef, Constants.emailCannotBeEmpty);
      return;
    }

    if (!email.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
      modalError(emailErrorRef, Constants.emailIsNotValid);
      return;
    }

    if (isNullOrEmpty(phone)) {
      modalError(phoneErrorRef, Constants.phoneCannotBeEmpty);
      return;
    }

    if (!isUsernameValid(username, usernameErrorRef)) {
      return;
    }

    if (isNullOrEmpty(password)) {
      modalError(passwordErrorRef, Constants.passwordCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(county)) {
      modalError(countyErrorRef, Constants.countyCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(city)) {
      modalError(cityErrorRef, Constants.cityCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(address)) {
      modalError(addressErrorRef, Constants.addressCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(zip)) {
      modalError(zipErrorRef, Constants.zipCannotBeEmpty);
      return;
    }

    let user: IUserRegister = {
      firstname,
      lastname,
      email,
      phone,
      username,
      password,
      county,
      city,
      address,
      zip,
    };

    const { data, ok } = await AccountService.signUp(user);

    if (ok) {
      setEmail(email);
      setIsOpen(true);
    } else {
      modalError(resErrorRef, data.message);
    }
  };

  return (
    <>
      <MetaTags data={SeoConfig.Register} />
      <AccountCreated isOpen={isOpen} setIsOpen={setIsOpen} email={email} />
      <div className='sign-in-page register'>
        <section className='form'>
          <h1>Napravite nalog</h1>

          <FormGroup type='no-wrapper'>
            <Input label='Ime' id='name' elRef={firstnameRef} customErrorRef={firstnameErrorRef} />
            <Input
              label='Prezime'
              id='lastname'
              elRef={lastnameRef}
              customErrorRef={lastnameErrorRef}
            />
          </FormGroup>

          <FormGroup>
            <Input label='Email' id='email' elRef={emailRef} customErrorRef={emailErrorRef} />
            <Input
              label='Broj telefona'
              id='phone'
              elRef={phoneRef}
              customErrorRef={phoneErrorRef}
            />
          </FormGroup>

          <FormGroup>
            <Input
              label='Korisničko ime'
              id='username'
              elRef={usernameRef}
              customErrorRef={usernameErrorRef}
            />
            <Input
              label='Lozinka'
              id='password'
              type='password'
              elRef={passwordRef}
              customErrorRef={passwordErrorRef}
            />
          </FormGroup>

          <div className='address-data'>
            <h3>Adresa</h3>
          </div>

          <FormGroup>
            <Input label='Okrug' id='county' elRef={countyRef} customErrorRef={countyErrorRef} />
            <Input label='Grad' id='city' elRef={cityRef} customErrorRef={cityErrorRef} />
          </FormGroup>

          <FormGroup>
            <Input
              label='Adresa'
              id='address'
              elRef={addressRef}
              customErrorRef={addressErrorRef}
            />
            <Input label='Poštanski broj' id='zip' elRef={zipRef} customErrorRef={zipErrorRef} />
          </FormGroup>

          <div className='privacy-policy-check sign-in-options'>
            <div className='remember-me'>
              <input
                onChange={(e) => setAcceptedPrivacyAndPolicy(e.target.checked)}
                type='checkbox'
                id='remeberme'
              />
              <p>
                Slažem se sa&nbsp;
                <Link to='/privacy-and-policy'>Politikom privatnošću.</Link>
              </p>
            </div>
            <span className='modal-error' ref={checkboxErrorRef}></span>
          </div>         
          <button
            onClick={register}
            className={`remove-hover-black ${acceptedPrivacyAndPolicy ? '' : 'freez-btn'}`}
          >
            REGISTRUJTE SE
          </button>
          <span className='modal-error' ref={resErrorRef}></span>
          <p className='register'>
            Već imate nalog? <Link to='/sign-in'>Prijavite se</Link>
          </p>
        </section>
      </div>
    </>
  );
};

export default Register;
