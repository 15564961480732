import React from 'react';
import Modal from '../Html/Modal';
import Lottie from 'lottie-react';
import SuccessAnimation from '../../assets/success.json';

interface IComponentProps {
  data: any;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrderCompleted: React.FC<IComponentProps> = ({ data, isOpen, setIsOpen }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} title='POTVRDA PORUDŽBINE'>
      <div className='order-confirmation-popup'>
        <div className='completed-animation'>
          <Lottie
            animationData={SuccessAnimation}
            loop={true}
            style={{
              width: '50%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </div>
        <h1>{data.message}</h1>
      </div>
    </Modal>
  );
};

export default OrderCompleted;
