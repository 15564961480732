import React, { useState } from 'react';
import { formatPrice } from '../../../helpers/uiHelper';
import { genders } from '../../../common/data';
import { IAdminProduct } from '../../../models/product';
import { AiFillEdit, AiOutlineDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { MdOutlineImageNotSupported } from 'react-icons/md';
import { isNullOrWhiteSpace } from '../../../utils/strings';
import DeleteProductModal from './DeleteProductModal';

interface IComponentProps {
  product: IAdminProduct;
}

export const AdminProduct: React.FC<IComponentProps> = ({ product }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <>
      <DeleteProductModal isOpen={isOpen} setIsOpen={setIsOpen} productId={product.id} />
      <div className='checkout-product admin-product'>
        <div className='checkout-main admin-main-checkout'>
          {isNullOrWhiteSpace(product.image) ? (
            <MdOutlineImageNotSupported className='no-product-image ' />
          ) : (
            <img
              src={product.image}
              alt={product.title}
              className='checkout-product-image admin-product-image'
              loading='lazy'
              decoding='async'
            />
          )}
          <div className='main-checkout-details'>
            <h1 className='checkout-title admin-checkout-title'>{product.title}</h1>
            <div className='admin-prouduct-primary-data'>
              <span className='checkout-code'>
                Šifra proizvoda:
                <span style={{ color: '#fa0a32' }}> {product.code}</span>
              </span>
              <span className='checkout-code'>Kategorija: {product.category}</span>
              <span className='checkout-code'>
                Pol: {genders.find((x) => x.id === product.gender)?.name}
              </span>
              <span className='checkout-code'>Brend: {product.brand}</span>
              <span className='checkout-code'>Sezona: {product.season}</span>
            </div>
          </div>
        </div>

        <div className='total-size-wrp'>
          <h3>VELIČINE</h3>
          <div className='size-wrapper'>
            {product.sizes.map((sizeData, i) => (
              <div
                key={i}
                className='size-item'
                style={sizeData.count === 0 ? { backgroundColor: '#fa0a32', color: '#fff' } : {}}
              >
                <span>
                  <b>{sizeData.size}</b>
                </span>
                <span>({sizeData.count})</span>
              </div>
            ))}
          </div>
        </div>
        <div className='other-product-data'>
          <div className='data-map'>
            <div className='data-checkout-div admin-prices'>
              {product.discount !== null && product.discount !== 0 && (
                <h4 className='regular-price'>
                  <s>{formatPrice(product.price)}</s>
                </h4>
              )}
              <h2>
                {formatPrice(product.price - product.price * ((product.discount ?? 0) / 100))}
              </h2>
            </div>
            {product.discount !== null && product.discount !== 0 && (
              <div className='checkout-discount'>
                <span>-{product.discount}%</span>
              </div>
            )}
            {product.isNew && (
              <div className='checkout-discount' style={{ backgroundColor: '#000' }}>
                <span>NOVO</span>
              </div>
            )}
          </div>
        </div>
        <div className='quantity-data'>
          <div className='statistics'>
            <h3>Prodato: </h3>
            <h4>{product.soldCount}</h4>
          </div>
          <div className='statistics'>
            <h3>Posećen: </h3>
            <h4>{product.views}</h4>
          </div>
          <div className='statistics'>
            <h3>Korpa: </h3>
            <h4>{product.cart}</h4>
          </div>
          <div className='statistics'>
            <h3>Lista želja: </h3>
            <h4>{product.wishlist}</h4>
          </div>
          <div
            className='product-status'
            style={{
              backgroundColor: product.available ? '#000' : '#fa0a32',
            }}
          >
            <span>{product.available ? 'DOSTUPAN' : 'NIJE DOSTUPAN'}</span>
          </div>
        </div>
        <div className='product-admin-actions'>
          <AiFillEdit
            className='edit-product-btn'
            onClick={() => navigate(`/admin/products/edit/${product.id}`)}
          />

          {product.soldCount === 0 && (
            <AiOutlineDelete
              className='edit-product-btn delete-product'
              onClick={() => setIsOpen(true)}
            />
          )}
        </div>
      </div>
    </>
  );
};
