import axios from "axios";
import Environment from "./environment";
import AuthService from "../services/auth.service";

const AxiosBase = axios.create({
  baseURL: Environment.api
});

AxiosBase.defaults.headers.common['x-api-key'] = Environment.apiKey;

const AxiosAuthorized = axios.create({
  baseURL: Environment.api
});

AxiosAuthorized.defaults.headers.common['x-api-key'] = Environment.apiKey;
AxiosAuthorized.defaults.headers.common['Authorization'] = `Bearer ${AuthService.getToken()}`;

const setAuthorizationHeader = () => {
  const token = AuthService.getToken();

  if (token) {
    AxiosAuthorized.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete AxiosAuthorized.defaults.headers.common['Authorization'];
  }
};

export { AxiosBase, AxiosAuthorized, setAuthorizationHeader }