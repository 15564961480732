import { useState, useEffect, useLayoutEffect, useReducer, createContext } from 'react';
import { ISortModel } from '../../../models/search';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import { useContentWidth } from '../../../hooks/useContentWidth';
import useUser from '../../../hooks/useUser';
import MetaTags from '../../../components/Html/MetaTags';
import { SeoConfig } from '../../../helpers/seo-config';
import { sortWebImages } from '../../../common/data';
import NoResultsFound from '../../../components/General/NoResultsFound';
import Pagination from '../../../components/Pagination/Pagination';
import { IAdminBackofficeSearchContext } from '../../../models/context';
import AdminBackofficeSearch from '../../../components/WebImages/AdminBackofficeSearch';
import { webImagesReducer } from '../../../helpers/reducers';
import { IWebImage } from '../../../models/web-images';
import WebImagesService from '../../../services/webimages.service';
import { useNavigate } from 'react-router-dom';
import NoWebImages from '../../../components/WebImages/NoWebImages';
import WebImageListItem from '../../../components/WebImages/WebImageListItem';

export const WebImagesContext = createContext<Partial<IAdminBackofficeSearchContext>>({} as any);

const WebImages = () => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [data, setData] = useState<IWebImage[]>([]);
  const [maxNumberOfPages, setMaxNumberOfPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [deleteCurrentPage, setDeleteCurrentPage] = useState<boolean>(false);
  const [sort, setSort] = useState<ISortModel>(sortWebImages[0]);

  useScrollOnLoad();
  useContentWidth();

  const navigate = useNavigate();

  const [search, dispatch] = useReducer(webImagesReducer, {});

  const user = useUser();

  useEffect(() => {
    setDeleteCurrentPage(true);
  }, [search]);

  useLayoutEffect(() => {
    (async () => {
      const { data } = await WebImagesService.totalCount();
      setTotalCount(data.count);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (totalCount > 0) {
        await loadData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, search, sort, deleteCurrentPage, resultsPerPage, currentPage, totalCount]);

  const loadData = async () => {
    const { data, ok } = await WebImagesService.filter(search, sort, resultsPerPage, currentPage);
    if (ok) {
      setData(data.data);
      setMaxNumberOfPages(data.pageCount);

      if (deleteCurrentPage) {
        setCurrentPage(0);
        setDeleteCurrentPage(false);
      }
    }
  };

  const contextReference: Partial<IAdminBackofficeSearchContext> = {
    setCurrentPage,
    setDeleteCurrentPage,
    setResultsPerPage,
    setSort,
    maxNumberOfPages,
    currentPage,
    resultsPerPage,
    sort,
    dispatch,
  };

  return (
    <>
      <MetaTags data={SeoConfig.OrdersAdmin} />
      <div className='dashboard min-height-block  admin-panel-width' id='admin-panel'>
        <WebImagesContext.Provider value={contextReference}>
          {totalCount > 0 && (
            <>
              <AdminBackofficeSearch />
              <Pagination
                sortCategories={sortWebImages}
                context={WebImagesContext}
                actionButton={{
                  available: true,
                  text: 'Dodaj sliku',
                  onClick: () => navigate('/admin/web-images/add'),
                }}
              />
            </>
          )}

          <div className='users-list newsletter-subscribers'>
            {totalCount > 0 &&
              data.map((info, index) => (
                <WebImageListItem data={info} key={index} loadData={loadData} />
              ))}

            {totalCount === 0 && <NoWebImages />}
            {totalCount > 0 && data.length === 0 && <NoResultsFound />}
          </div>
        </WebImagesContext.Provider>
      </div>
    </>
  );
};

export default WebImages;
