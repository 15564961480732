import React, { useState, useEffect } from 'react';
import { ISavedProduct } from '../../../models/product-base';
import '../../../styles/Checkout.css';
import '../../../styles/Product.css';
import { ISize } from '../../../models/product';
import { useDispatch } from 'react-redux';
import { increaseProductCount, removeFromCart } from '../../../redux/reducers/shoppingCartReducer';
import { formatPrice } from '../../../helpers/uiHelper';
import { AxiosBase } from '../../../common/axios';
import OpenImage from '../../Html/OpenImage';

interface IComponentProps {
  product: ISavedProduct;
}

const CheckoutProduct: React.FC<IComponentProps> = ({ product }) => {
  const [size, setSize] = useState<ISize | null>(null);
  const [count, setCount] = useState<number>(product.size.count);
  const [openImage, setOpenImage] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const load = async () => {
      const { data } = await AxiosBase.get(`/products/info/${product.id}`);
      const id = `${product?.id}-${product.size?.id}`;

      if (data === null || data?.available === false) {
        dispatch(removeFromCart(id));
      }
      const sizeData = data.sizes.find((x: any) => x.id === product.size.id);

      if (sizeData === null) {
        dispatch(removeFromCart(id));
      }

      setSize(sizeData);
    };

    load();
  }, [product, dispatch]);

  const increase = () => {
    if (size !== null && size?.count > count) {
      let value = count + 1;
      setCount(value);

      let productItem = { ...product };
      productItem.size = { ...productItem.size, count: value };

      dispatch(increaseProductCount(productItem));
    }
  };

  const decrease = () => {
    if (count > 1) {
      let value = count - 1;
      setCount(value);

      let productItem = { ...product };
      productItem.size = { ...productItem.size, count: value };

      dispatch(increaseProductCount(productItem));
    }
  };

  return (
    <>
      <OpenImage url={product.image} setIsOpen={setOpenImage} isOpen={openImage} />
      <div className='checkout-product'>
        <div className='checkout-main'>
          <img
            src={product.image}
            alt={product.title}
            className='checkout-product-image'
            loading='lazy'
            decoding='async'
            onClick={() => setOpenImage(true)}
          />
          <div className='main-checkout-details'>
            <h1 className='checkout-title'>{product.title}</h1>
            {product.discount !== null && product.discount !== 0 && (
              <div className='checkout-discount'>
                <span>-{product.discount}%</span>
              </div>
            )}
            <span className='checkout-code'>Šifra proizvoda: {product.code}</span>
          </div>
        </div>

        <table className='checkout-data-list'>
          <tbody>
            <tr>
              <th>VELIČINA</th>
              <th>KOLIČINA</th>
              <th>CENA</th>
              <th>UKUPNO</th>
            </tr>
            <tr>
              <td className='data-checkout-td'>
                <span className='table-size'>{product.size.size}</span>
              </td>
              <td className='data-checkout-td'>
                <div className='cart-block-2'>
                  <div className='cart-options-2'>
                    <button onClick={() => increase()}>+</button>
                    <div>
                      <h3>{count}</h3>
                    </div>
                    <button onClick={() => decrease()}>-</button>
                  </div>
                </div>
              </td>
              <td className='data-checkout-td'>
                <div className='data-checkout-div'>
                  {product.discount !== null && (
                    <h3 className='regular-price'>
                      <s>{formatPrice(product.price)}</s>
                    </h3>
                  )}
                  <h1>
                    {formatPrice(product.price - product.price * ((product.discount ?? 0) / 100))}
                  </h1>
                </div>
              </td>
              <td className='data-checkout-td'>
                <div className='data-checkout-div'>
                  <h1>
                    {formatPrice(
                      product.price * count -
                        product.price * count * ((product.discount ?? 0) / 100),
                    )}
                  </h1>
                </div>
              </td>
              <td className='reduced-width'>
                <button
                  className='remove-btn'
                  onClick={() => {
                    const id = `${product?.id}-${product.size?.id}`;
                    setCount(0);
                    dispatch(removeFromCart(id));
                  }}
                >
                  UKLONI
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CheckoutProduct;
