import React, { useEffect, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

interface ModalProps {
  children: React.ReactElement;
  title: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}

const Modal: React.FC<ModalProps> = ({ title, children, isOpen, setIsOpen }) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const modalOverlay = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';

      overlayRef.current?.classList.remove('hidden-modal');
      modalOverlay.current?.classList.remove('hidden-modal');
    } else {
      document.body.style.overflow = 'auto';

      setTimeout(() => {
        modalOverlay.current?.classList.add('hidden-modal');
      }, 200);

      setTimeout(() => {
        overlayRef.current?.classList.add('hidden-modal');
      }, 250);
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={`overlay ${isOpen ? 'open-modal' : 'closed-modal'}`}
        onClick={() => setIsOpen(false)}
        ref={overlayRef}
      ></div>
      <div className={`modal-content ${isOpen ? 'open-modal' : 'closed-modal'}`} ref={modalOverlay}>
        <div className='modal-headline'>
          <h1>{title}</h1>
          <AiOutlineClose className='modal-close' onClick={() => setIsOpen(false)} />
        </div>
        {children}
      </div>
    </>
  );
};

export default Modal;
