export interface ISEO {
  title: string;
  route: string;
}

export const SeoConfig = {
  Home: {
    title: "Početna",
    route: "/"
  },
  Store: {
    title: "Prodavnica",
    route: "/store"
  },
  NewCollection: {
    title: "Nova kolekcija",
    route: "/store?searchType=new-collection"
  },
  Discount: {
    title: "Sniženja",
    route: "/store?searchType=discount"
  },
  Male: {
    title: "Muška obuća",
    route: "/store?genders=0"
  },
  Female: {
    title: "Ženska obuća",
    route: "/store?genders=1"
  },
  Kids: {
    title: "Dečija obuća",
    route: "/store?genders=2"
  },
  SignIn: {
    title: "Prijavi se",
    route: "/sign-in"
  },
  Register: {
    title: "Registruj se",
    route: "/register"
  },
  NotFound: {
    title: "404",
    route: "/not-found"
  },
  Verify: {
    title: "Verifikujte nalog",
    route: "/account/verify"
  },
  ResetPassword: {
    title: "Resetujte lozinku",
    route: "/reset-password"
  },
  ForgotPassword: {
    title: "Zaboravljena loznika",
    route: "/forgot-password"
  },
  Account: {
    title: "Nalog",
    route: "/account"
  },
  Checkout: {
    title: "Plaćanje",
    route: "/checkout"
  },
  ShoppingCart: {
    title: "Korpa",
    route: "/shopping-cart"
  },
  AddProduct: {
    title: "Dodaj novi proizvod",
    route: "/admin/products/add-new"
  },
  EditProduct: {
    title: "Izmeni proizvod",
    route: "/admin/products/edit"
  },
  Coupons: {
    title: "Kuponi",
    route: "/admin/coupons"
  },
  Users: {
    title: "Korisnici",
    route: "/admin/users"
  },
  Products: {
    title: "Proizvodi",
    route: "/admin/products"
  },
  OrdersAdmin: {
    title: "Porudžbine",
    route: "/admin/orders"
  },
  Orders: {
    title: "Porudžbine",
    route: "/my-orders"
  },
  Wishlist: {
    title: "Lista želja",
    route: "/wishlist"
  },
  OrderStatus : {
    title: "Status porudžbine",
    route: "/check-order-status"
  }
}