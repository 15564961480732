import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWebImageBase } from "../../models/web-images";
import { defaultUIImages } from "../../common/data";

interface IWebImagesPayload {
  webImages: IWebImageBase[];
}

const initialState: IWebImagesPayload = {
  webImages: defaultUIImages
};

const webImagesSlice = createSlice({
  name: "webImages",
  initialState,
  reducers: {
    loadWebImages: (state: IWebImagesPayload, action: PayloadAction<IWebImageBase[]>) => {
      state.webImages = action.payload;
    }
  }
});

export const { loadWebImages } = webImagesSlice.actions;
export default webImagesSlice.reducer;
