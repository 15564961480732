import React, { useState } from 'react';
import { ISettings } from '../../models/settings';
import { SettingDataType } from '../../common/enums';
import EditSettingModal from './EditSettingModal';
import DeleteSettingModal from './DeleteSettingModal';

interface IComponentProps {
  data: ISettings;
  getSettings: () => Promise<void>;
}

const SettingListItem: React.FC<IComponentProps> = ({ data, getSettings }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const getSettingValue = () => {
    if (data.dataType === SettingDataType.Boolean) {
      return Number(data.value) === 1 ? 'Da' : 'Ne';
    }

    return data.value;
  };

  return (
    <>
      <EditSettingModal
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        setting={data}
        getSettings={getSettings}
      />
      <DeleteSettingModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        id={data.id}
        title={data.name}
      />
      <div className='user-data-wrapper'>
        <div className='primary-order-data'>
          <div className='align-items'>
            <h1 className='break-long-word'>{data.name}</h1>
          </div>
          <div className='align-items'>
            <button className='order-button' onClick={() => setIsEditModalOpen(true)}>
              IZMENI
            </button>
            {data.enableDeleting && (
              <button
                className='order-button edit-order-btn'
                onClick={() => {
                  if (data.enableDeleting) {
                    setIsDeleteModalOpen(true);
                  }
                }}
              >
                IZBRIŠI
              </button>
            )}
          </div>
        </div>
        <div className='order-data-admin'>
          <h3 className='break-long-word expand-word-width'>{getSettingValue()}</h3>
        </div>
      </div>
    </>
  );
};

export default SettingListItem;
