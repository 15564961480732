import IOrder from "../models/order";
import IResponse from "../models/response";
import { DashboardSearchPeriods, OrderStatus } from "../common/enums";
import { ISortModel } from "../models/search";
import { IAdminOrdersSearchCriteria } from "../models/search-criteria";
import { URLQueries } from "../utils/url-queries";
import { AxiosAuthorized } from "../common/axios";

export default class OrderService {
  private static serviceEndpoint: string = `/order`;

  public static async order(order: IOrder): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/new`;

      const { data } = await AxiosAuthorized.post(api, order);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }


  public static async loadTotalCount(): Promise<IResponse> {
    try {
      let url = `${this.serviceEndpoint}/total-count`;

      const { data } = await AxiosAuthorized.get(url);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async getAll(sp: IAdminOrdersSearchCriteria, sort: ISortModel, resultsPerPage: number, currentPage: number): Promise<IResponse> {
    try {
      let api = `${this.serviceEndpoint}/my?sortBy=${sort.sortBy}&sortDirection=${sort.sortDirection}&resultsPerPage=${resultsPerPage}&pageNumber=${currentPage}`;

      api = URLQueries.addList(sp, api, []);

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async changeStatus(orderId: number, orderStatus: OrderStatus): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/change-status/${orderId}`;

      const { data } = await AxiosAuthorized.put(api, { status: orderStatus },);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async checkStatus(orderId: string, email: string): Promise<IResponse> {
    try {
      let url = `${this.serviceEndpoint}/check-status?orderId=${Number(orderId)}&email=${email}`;

      const { data } = await AxiosAuthorized.get(url);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async statusesData(): Promise<IResponse> {
    try {
      let url = `${this.serviceEndpoint}/statuses`;

      const { data } = await AxiosAuthorized.get(url);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async statisticsByPeriod(period: DashboardSearchPeriods): Promise<IResponse> {
    try {
      let url = `${this.serviceEndpoint}/statistics?period=${period}`;

      const { data } = await AxiosAuthorized.get(url);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }
}
