import { AxiosBase } from '../common/axios';
import IResponse from '../models/response';
import { Cookies } from '../utils/cookies';
import { decodeBase64 } from '../utils/strings';

export default class AuthService {
  private static serviceEndpoint = `/authentication`;

  public static getToken(): string {
    return localStorage.getItem('_token') as string;
  }

  public static async signIn(username: string, password: string): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/sign-in`;

      const { data } = await AxiosBase.post(api, { username, password });

      let jwtToken = data.token;
      let payload = decodeBase64(jwtToken.split('.')[1] ?? "");

      const expireTime = new Date(payload.exp * 1000);

      localStorage.setItem('_token', data.token);

      Cookies.set("identity", data.token, expireTime)

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async forgotPassword(email: string): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/forgot-password`;

      const { data } = await AxiosBase.post(api, { email });

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async resetPassword(token: string, newPassword: string, confirmPassword: string) {
    try {
      const api = `${this.serviceEndpoint}/reset-password/${token}`;

      const { data } = await AxiosBase.put(api, {
        newPassword,
        confirmPassword,
      });

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async validateToken(token: any) {
    try {
      const api = `${this.serviceEndpoint}/validate/${token}`;
      await AxiosBase.put(api);

      return { ok: true };
    } catch (error: any) {
      return { ok: false };
    }
  }
}
