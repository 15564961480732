import React, { useEffect, useState } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const NoMailServerFound = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);
  }, []);

  return show ? (
    <div className='empty-shoppinh-cart'>
      <AiOutlineMail className='empty-icon' />
      <h1>NEMATE MAIL SERVER</h1>
      <p>
        Još uvek nemate ni jedan mejl server. Poruke korisnicima putem mejla će se slati sa mejl
        servera podešenog u konfiguracionom fajlu.
      </p>

      <button onClick={() => navigate('/admin/mail-servers/add-new')} className='go-to-checkout'>
        DODAJ SERVER
        <FaArrowRight className='cart-arrow-start' />
      </button>
    </div>
  ) : (
    <></>
  );
};

export default NoMailServerFound;
