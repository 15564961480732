import { useState, useLayoutEffect, useReducer, createContext, useEffect } from 'react';
import '../../../styles/Admin.css';
import { IBaseItem, ISizeBase } from '../../../models/product-base';
import { useNavigate } from 'react-router-dom';
import ProductService from '../../../services/product.service';
import { IAdminProduct } from '../../../models/product';
import { AdminProduct } from '../../../components/Product/Other/AdminProduct';
import { ISortModel } from '../../../models/search';
import EmptyProducts from '../../../components/Product/Other/EmptyProducts';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import MetaTags from '../../../components/Html/MetaTags';
import { SeoConfig } from '../../../helpers/seo-config';
import NoResultsFound from '../../../components/General/NoResultsFound';
import { useContentWidth } from '../../../hooks/useContentWidth';
import AdminProductMobile from '../../../components/Product/Other/AdminProductMobile';
import useResize from '../../../hooks/useResize';
import { IAdminBackofficeSearchContext } from '../../../models/context';
import { productsReducer } from '../../../helpers/reducers';
import Pagination from '../../../components/Pagination/Pagination';
import { sortProducts } from '../../../common/data';
import AdminBackofficeSearch from '../../../components/Product/Other/AdminBackofficeSearch';

export const ProductsContext = createContext<IAdminBackofficeSearchContext>({} as any);

const Products = () => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [categories, setCategories] = useState<IBaseItem[]>([]);
  const [brands, setBrands] = useState<IBaseItem[]>([]);
  const [sizes, setSizes] = useState<ISizeBase[]>([]);
  const [discounts, setDiscounts] = useState<number[]>([]);
  const [maxNumberOfPages, setMaxNumberOfPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [deleteCurrentPage, setDeleteCurrentPage] = useState<boolean>(false);
  const [sort, setSort] = useState<ISortModel>(sortProducts[0]);
  const [products, setProducts] = useState<IAdminProduct[]>([]);

  const [search, dispatch] = useReducer(productsReducer, {});
  const navigate = useNavigate();

  const isMobile = useResize() <= 1000;
  const mobile = totalCount > 0 && isMobile === true;

  useScrollOnLoad();
  useContentWidth();

  useLayoutEffect(() => {
    const loadData = async () => {
      const totalCountData = await ProductService.loadTotalCount();

      setTotalCount(totalCountData.data.count);

      if (totalCountData.data.count > 0) {
        const brandsData = await ProductService.loadBrands();
        const sizesData = await ProductService.loadSizes();
        const categoriesData = await ProductService.loadCategories();
        const discountsData = await ProductService.loadDiscounts();

        setBrands(brandsData.data);
        setSizes(sizesData.data);
        setCategories(categoriesData.data);
        setDiscounts(discountsData.data);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    setDeleteCurrentPage(true);
  }, [search]);

  useEffect(() => {
    if (deleteCurrentPage) {
      setCurrentPage(0);
      setDeleteCurrentPage(false);
    }
  }, [deleteCurrentPage]);

  useLayoutEffect(() => {
    const loadProducts = async () => {
      const { data } = await ProductService.getAll(currentPage, resultsPerPage, search, sort);

      setProducts(data.data);
      setMaxNumberOfPages(data.pageCount);
    };

    if (totalCount > 0) {
      loadProducts();
    }
  }, [currentPage, resultsPerPage, search, sort, totalCount]);

  const contextReference: IAdminBackofficeSearchContext = {
    setCurrentPage,
    setDeleteCurrentPage,
    setResultsPerPage,
    setSort,
    maxNumberOfPages,
    currentPage,
    resultsPerPage,
    sort,
    sizes,
    brands,
    categories,
    discounts,
    dispatch,
  };

  return (
    <>
      <MetaTags data={SeoConfig.Products} />
      <div className='dashboard min-height-block admin-panel-width' id='admin-panel'>
        <ProductsContext.Provider value={contextReference}>
          {totalCount === 0 && (
            <EmptyProducts>
              <button
                className='button-el-classic add-new-btn'
                style={{ marginLeft: 10, marginRight: 10 }}
                onClick={() => navigate('/admin/products/add-new')}
              >
                DODAJ NOVI PROIZVOD
              </button>
            </EmptyProducts>
          )}

          {totalCount > 0 && (
            <>
              <AdminBackofficeSearch />
              <Pagination
                sortCategories={sortProducts}
                context={ProductsContext}
                actionButton={{
                  available: true,
                  text: 'Dodaj proizvod',
                  onClick: () => navigate('/admin/products/add-new'),
                }}
              />
            </>
          )}

          {!mobile &&
            products?.map((product, index) => <AdminProduct product={product} key={index} />)}

          {mobile &&
            products?.map((product, index) => <AdminProductMobile product={product} key={index} />)}
        </ProductsContext.Provider>

        {totalCount > 0 && products.length === 0 && <NoResultsFound />}
      </div>
    </>
  );
};

export default Products;
