import '../../styles/BurgerMenu.css';
import Logo from '../../assets/big-obuca-logo.jpg';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import { AiOutlineSearch, AiOutlineShoppingCart, AiOutlineUser } from 'react-icons/ai';
import useUser from '../../hooks/useUser';
import { Link } from 'react-router-dom';
import { ISavedProduct } from '../../models/product-base';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { UserRole } from '../../common/enums';

interface IComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderPhone = ({ setOpen, setOpenSearch }: IComponentProps) => {
  const navigate = useNavigate();

  const products: ISavedProduct[] = useSelector((x: RootState) => x.shoppingCart.products);

  const user = useUser();

  return (
    <div className='header-main-menu header-phone-main'>
      <div className='header-phone'>
        <div className='align-header-content'>
          <RxHamburgerMenu
            size={25}
            style={{ marginLeft: 15 }}
            onClick={() => {
              setOpen(true);
              document.body.style.overflow = 'hidden';
            }}
          />
          <img src={Logo} alt='' onClick={() => navigate('/')} />
        </div>
        <div className='align-header-content'>
          {(!user || (user && user.roleId === UserRole.Buyer)) && (
            <div className='header-icon-block'>
              <AiOutlineSearch
                size={25}
                onClick={() => {
                  setOpenSearch(true);
                  document.body.style.overflow = 'hidden';
                }}
              />
            </div>
          )}
          <div className='header-icon-block'>
            <AiOutlineUser
              size={25}
              onClick={() => (user ? navigate('/account') : navigate('/sign-in'))}
            />
          </div>
          {(!user || (user && user.roleId === UserRole.Buyer)) && (
            <div className='header-icon-block cart-block-mobile'>
              <div className='cart-products-count-mobile'>
                <span>{products.length ?? 0}</span>
              </div>
              <AiOutlineShoppingCart size={25} onClick={() => navigate('/shopping-cart')} />
            </div>
          )}
        </div>
      </div>
      {(!user || (user && user.roleId === UserRole.Buyer)) && (
        <div className='navbar-2'>
          <Link to='/store'>Prodavnica</Link>
          <Link to='/store?searchType=new-collection'>Nova kolekcija</Link>
          <Link to='/store?searchType=discount'>Sniženja</Link>
        </div>
      )}
    </div>
  );
};

export default HeaderPhone;
