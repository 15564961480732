import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./reducers/counterReducer";
import shoppingCartReducer from "./reducers/shoppingCartReducer";
import userReducer from "./reducers/userReducer";
import webImagesReducer from "./reducers/webImagesReducer";

const store = configureStore({
  reducer: {
    counter: counterReducer,
    shoppingCart: shoppingCartReducer,
    user: userReducer,
    webImages: webImagesReducer
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
