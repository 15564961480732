import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { LuHeartOff } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const EmptyWishlist = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);
  }, []);

  return show ? (
    <div className="empty-shoppinh-cart">
      <LuHeartOff className="empty-icon" />
      <h1>LISTA ŽELJA JE PRAZNA</h1>
      <p>
        Još uvek nemate ništa u sačuvano u listi želja. Pogledajte veliki
        asortiman naših proizvoda i počnite sa kupovinom.
      </p>

      <button onClick={() => navigate("/store")} className="go-to-checkout">
        POČNITE SA KUPOVINOM
        <FaArrowRight className="cart-arrow-start" />
      </button>
    </div>
  ) : (
    <></>
  );
};

export default EmptyWishlist;
