export const isNewDate = (date: Date) => {

  const newDate = new Date(date);
  const currentDate = new Date();

  const difference = currentDate.getTime() - newDate.getTime();

  const daysDifference = difference / (1000 * 3600 * 24);

  return daysDifference <= 10;
}

export const isWinterAndAutumnSeason = (): boolean => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;

  if (
    (currentMonth === 9 && currentDate.getDate() >= 23) ||
    (currentMonth > 9 && currentMonth < 12) ||
    (currentMonth === 12 && currentDate.getDate() <= 20) ||
    (currentMonth >= 1 && currentMonth <= 3)
  ) {
    return true;
  } else {
    return false;
  }
}