import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/style.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import Router from "./routes/Router";
import AuthorizationMiddleware from "./routes/AuthorizationMiddleware";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.Fragment>
    <Provider store={store}>
      <AuthorizationMiddleware />
      <Router />
    </Provider>
  </React.Fragment>
);
