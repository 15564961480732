import { useLayoutEffect, useState } from "react";
import StatisticsService from "../services/statistics.service";
import { ICloudinaryStatusResponse } from "../models/cloudinary";
import { CloudinaryStatus } from "../common/enums";

export const useCloudinaryStatusChecker = (): ICloudinaryStatusResponse => {
  const defaultState: ICloudinaryStatusResponse = {
    status: CloudinaryStatus.Ok,
    usedPercentage: 0,
  };

  const [cloudinaryStatus, setCloudinaryStatus] = useState<ICloudinaryStatusResponse>(defaultState);

  useLayoutEffect(() => {
    (async () => {
      const { data } = await StatisticsService.cloudinaryStatus();
      setCloudinaryStatus(data);
    })();
  }, []);

  return cloudinaryStatus;
}