import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { MdOutlineImageNotSupported } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const NoWebImages: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);
  }, []);

  return show ? (
    <div className='empty-shoppinh-cart'>
      <MdOutlineImageNotSupported className='empty-icon' />
      <h1>NEMATE SLIKA</h1>
      <p>Još uvek nemate ni jednu sliku koja se prikazijue na sajtu.</p>

      <button onClick={() => navigate('/admin/web-images/add')} className='go-to-checkout'>
        DODAJ SLIKU
        <FaArrowRight className='cart-arrow-start' />
      </button>
    </div>
  ) : (
    <></>
  );
};

export default NoWebImages;
