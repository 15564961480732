import { IAdminOrdersSearchCriteria, IAdminProductSearchCriteria, IAdminUserSearchCriteria, ICouponsSearchCriteria, IMailServerSearchCriteria, ISettingsSearch, IStoreSearchCriteria, IWebImagesSearchCriteria } from "../models/search-criteria";

export const userReducer = (state: IAdminUserSearchCriteria, action: any) => {
  const { type, payload } = action;
  sessionStorage.setItem("searchCriteria_user", type);

  switch (type) {
    case "username":
      return { ...state, username: payload };
    case "email":
      return { ...state, email: payload };
    case "address":
      return { ...state, address: payload };
    case "isVerified":
      return { ...state, isVerified: payload };
    case "roleId":
      return { ...state, roleId: payload };
    case "county":
      return { ...state, county: payload };
    case "city":
      return { ...state, city: payload };
    case "phone":
      return { ...state, phone: payload };
    case "reset":
      return {};
    default:
      return state;
  }
};

export const productsReducer = (state: IAdminProductSearchCriteria, action: any) => {
  const { type, payload } = action;
  sessionStorage.setItem("searchCriteria_roducts", type);

  switch (type) {
    case "gender":
      return { ...state, gender: payload };
    case "size":
      return { ...state, size: payload };
    case "title":
      return { ...state, title: payload };
    case "code":
      return { ...state, code: payload };
    case "brand":
      return { ...state, brand: payload };
    case "category":
      return { ...state, category: payload };
    case "available":
      return { ...state, available: payload };
    case "discount":
      return { ...state, discount: payload };
    case "reset":
      return {};
    default:
      return state;
  }
};

export const ordersReducer = (state: IAdminOrdersSearchCriteria, action: any) => {
  const { type, payload } = action;
  sessionStorage.setItem("searchCriteria_orders", type);

  switch (type) {
    case "status":
      return { ...state, status: payload };
    case "orderId":
      return { ...state, orderId: payload };
    case "address":
      return { ...state, address: payload };
    case "zip":
      return { ...state, zip: payload };
    case "city":
      return { ...state, city: payload };
    case "county":
      return { ...state, county: payload };
    case "phone":
      return { ...state, phone: payload };
    case "email":
      return { ...state, email: payload };
    case "reset":
      return {};
    default:
      return state;
  }
};

export const defaultStoreSearchCriteria: IStoreSearchCriteria = {
  genders: null,
  sizes: null,
  title: null,
  categories: null,
  searchType: null,
  brands: null,
  sortBy: "title",
  sortDirection: "ASC",
};

export const storeReducer = (state: IStoreSearchCriteria, action: any) => {
  const { type, payload } = action;
  sessionStorage.setItem("searchCriteria_store", type);

  switch (type) {
    case "categories":
      return { ...state, categories: payload };
    case "sizes":
      return { ...state, sizes: payload };
    case "genders":
      return { ...state, genders: payload };
    case "searchType":
      return { ...state, searchType: payload };
    case "brands":
      return { ...state, brands: payload };
    case "seasons":
      return { ...state, seasons: payload };
    case "sort":
      return {
        ...state,
        sortBy: payload.sortBy,
        sortDirection: payload.sortDirection,
      };
    case "all":
      return payload;
    case "reset":
      return defaultStoreSearchCriteria;
    default:
      return state;
  }
};

export const mailServerReducer = (state: IMailServerSearchCriteria, action: any) => {
  const { type, payload } = action;
  sessionStorage.setItem("searchCriteria_mailServer", type);

  switch (type) {
    case "port":
      return { ...state, port: payload };
    case "enabled":
      return { ...state, enabled: payload };
    case "senderMail":
      return { ...state, senderMail: payload };
    case "host":
      return { ...state, host: payload };
    case "reset":
      return {};
    default:
      return state;
  }
};

export const couponsReducer = (state: ICouponsSearchCriteria, action: any) => {
  const { type, payload } = action;
  sessionStorage.setItem("searchCriteria_coupons", type);

  switch (type) {
    case "code":
      return { ...state, code: payload };
    case "active":
      return { ...state, active: payload };
    case "reset":
      return {};
    default:
      return state;
  }
};

export const settingsReducer = (state: ISettingsSearch, action: any) => {
  const { type, payload } = action;
  sessionStorage.setItem("searchCriteria_settings", type);

  switch (type) {
    case "name":
      return { ...state, name: payload };
    case "value":
      return { ...state, value: payload };
    case "reset":
      return {};
    default:
      return state;
  }
};

export const webImagesReducer = (state: IWebImagesSearchCriteria, action: any) => {
  const { type, payload } = action;
  sessionStorage.setItem("searchCriteria_settings", type);

  switch (type) {
    case "position":
      return { ...state, position: payload };
    case "available":
      return { ...state, available: payload };
    case "reset":
      return {};
    default:
      return state;
  }
};