import { useState, useLayoutEffect, useRef } from 'react';
import '../../../styles/Admin.css';
import { CloudinaryStatus, WebImagePosition } from '../../../common/enums';
import { LuImagePlus } from 'react-icons/lu';
import { isNullOrEmpty } from '../../../utils/strings';
import { modalError, modalErrorInput } from '../../../helpers/uiHelper';
import Constants from '../../../common/constants';
import { BsFillTrashFill } from 'react-icons/bs';
import { WebImagesPositionMapping } from '../../../common/data';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import MetaTags from '../../../components/Html/MetaTags';
import { SeoConfig } from '../../../helpers/seo-config';
import { useNavigate } from 'react-router-dom';
import { useDOMLoaderSequence } from '../../../hooks/useDOMLoaderSequence';
import LoadingScreen from '../../../components/Html/LoadingScreen';
import Environment from '../../../common/environment';
import Input from '../../../components/Html/Input';
import Select from '../../../components/Html/Select';
import ToggleSwitch from '../../../components/Html/ToggleSwitch';
import FormGroup from '../../../components/Html/FormGroup';
import OpenImage from '../../../components/Html/OpenImage';
import WebImagesService from '../../../services/webimages.service';
import Notification from '../../../components/Html/Notification';
import { useCloudinaryStatusChecker } from '../../../hooks/useCloudinaryStatusChecker';

type FileChangeEvent = React.ChangeEvent<HTMLInputElement>;

const AddWebImage = () => {
  const navigate = useNavigate();

  const [file, setFile] = useState<File | any>(null);
  const [isUniquePosition, setIsUniquePosition] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [position, setPosition] = useState<WebImagePosition>();
  const [cloudinaryDisabled, setCloudinaryDisabled] = useState<boolean>(false);

  const cloudinaryStatus = useCloudinaryStatusChecker();

  useLayoutEffect(() => {
    if (cloudinaryStatus.status === CloudinaryStatus.Danger) {
      setCloudinaryDisabled(true);
    }
  }, [cloudinaryStatus]);

  const resErrorRef = useRef(document.createElement('span'));
  let inputs = document.querySelectorAll<HTMLInputElement>('.input-text');

  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const displayIndexRef = useRef<HTMLInputElement>(null);
  const positionRef = useRef<HTMLSelectElement>(null);
  const isUniquePositionRef = useRef<HTMLSelectElement>(null);
  const resolutionRef = useRef<HTMLInputElement>(null);
  const availableRef = useRef<HTMLInputElement>(null);

  useScrollOnLoad();

  useDOMLoaderSequence();

  useLayoutEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    inputs = document.querySelectorAll<HTMLInputElement>('.input-text');
  }, []);

  const save = async () => {
    let error = false;

    inputs.forEach((input) => {
      let spanModelError = document.getElementById(`error-${input.id}`) as HTMLSpanElement;
      if (isNullOrEmpty(input.value)) {
        modalErrorInput(spanModelError, Constants.fieldCannotBeEmpty);
        error = true;
      }
    });

    if (error) {
      return;
    }

    const name = nameRef.current!.value;
    const description = descriptionRef.current!.value;
    const displayIndex = Number(displayIndexRef.current!.value);
    const resolution = resolutionRef.current!.value;
    const available = availableRef.current!.checked;

    let payload = {
      name,
      displayIndex: displayIndex,
      description,
      resolution,
      isUniquePosition,
      imagePosition: position,
      available,
    };

    let formData = new FormData();
    formData.append('file', file as any);

    setShowLoading(true);

    let { data: productData, ok: productOk } = await WebImagesService.add(payload);

    if (!productOk) {
      setShowLoading(false);
      modalError(resErrorRef, productData.message);
      return;
    }

    if (file !== null) {
      let { data: imageData, ok: imageOk } = await WebImagesService.upload(
        formData,
        productData.id,
      );

      if (!imageOk) {
        setShowLoading(false);
        modalError(resErrorRef, imageData.message);
        return;
      }
    }

    inputs.forEach((input) => {
      input.value = '';
    });

    setShowLoading(false);
    document.body.style.overflow = 'auto';
    navigate('/admin/web-images');
  };

  const removeImageElement = () => {
    setFile(null);
  };

  const addImage = (e: FileChangeEvent) => {
    const selectedFiles = e.target.files as FileList;
    if (selectedFiles.length < 1) return;

    setFile(selectedFiles[0]);
  };

  return (
    <>
      <OpenImage
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        url={file !== null ? URL.createObjectURL(file) : ''}
      />
      <LoadingScreen isOpen={showLoading} />
      <MetaTags data={SeoConfig.AddProduct} />
      <div className='sign-in-page register admin-panel-width edit-product-page' id='admin-panel'>
        <section className='form add-page-form'>
          <h1>DODAJ SLIKU</h1>

          <FormGroup type='no-wrapper'>
            <Input label='Naziv' elRef={nameRef} id='name' />
            <Input label='Rezolucija' elRef={resolutionRef} id='resolution' />
          </FormGroup>

          <FormGroup type='no-wrapper'>
            <Select
              elRef={positionRef}
              id='position'
              label='Pozicija'
              hasNoValue={true}
              onChange={(x) => setPosition(Number(x.target.value))}
            >
              {WebImagesPositionMapping.map((x) => (
                <option key={x.value} value={x.value}>
                  {x.title}
                </option>
              ))}
            </Select>
            <Select
              elRef={isUniquePositionRef}
              id='isUniquePosition'
              hasNoValue={true}
              label='Jedinstvena pozicija'
              onChange={(x) => setIsUniquePosition(Number(x.target.value) === 1)}
            >
              <option value={1}>Da</option>
              <option value={0}>Ne</option>
            </Select>
          </FormGroup>

          <FormGroup type='no-wrapper'>
            <Input
              label='Indeks na ekranu'
              elRef={displayIndexRef}
              id='displayIndex'
              type='number'
            />
            <Input label='Opis' elRef={descriptionRef} id='description' />
          </FormGroup>

          <ToggleSwitch label='Dostupan' elRef={availableRef} />

          <div className='w-img'>
            {cloudinaryDisabled && (
              <div className='disable-image-upload'>
                <h1 style={{ color: '#fa0a32' }}>UPOZORENJE!</h1>
                <strong>
                  {Constants.CloudinaryDanger.replace(
                    '{{PERCENT}}',
                    cloudinaryStatus.usedPercentage.toString(),
                  )}
                  <span className='danger-txt' onClick={() => setCloudinaryDisabled(false)}>
                    ovde
                  </span>
                </strong>
              </div>
            )}
            <div className='product-images-section'>
              <h3>Slika</h3>
              {cloudinaryStatus.status === CloudinaryStatus.Warning && (
                <Notification
                  isOk={true}
                  customTopMargin={0}
                  customBottomMargin={10}
                  title={Constants.CloudinaryWarning.replace(
                    '{{PERCENT}}',
                    cloudinaryStatus.usedPercentage.toString(),
                  )}
                  customColor='#ff9c1c'
                  customBorderColor='#db881b'
                />
              )}

              <div className='add-product-images'>
                <LuImagePlus className='img-ic' />
                <input
                  type='file'
                  name=''
                  id=''
                  multiple={false}
                  accept={Environment.allowedMimeTypes}
                  onChange={(e: FileChangeEvent) => addImage(e)}
                />
              </div>
              <div className='product-images-list'>
                {file !== null && (
                  <div className='product-image-block-admin'>
                    <div className='remove-img-wrp'>
                      <img
                        src={URL.createObjectURL(file)}
                        alt=''
                        onClick={() => {
                          setIsOpenModal(true);
                        }}
                      />
                      <BsFillTrashFill
                        color='#ffff'
                        className='remove-image-btn'
                        onClick={() => removeImageElement()}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <button onClick={save}>KREIRAJ</button>
          <span ref={resErrorRef} className='modal-error'></span>
        </section>
      </div>
    </>
  );
};

export default AddWebImage;
