import React from 'react';
import { isNullOrWhiteSpace } from '../../utils/strings';

interface IComponentProps {
  label: string;
  id?: string;
  elRef?: React.Ref<any>;
  type?: React.HTMLInputTypeAttribute;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  isRequired?: boolean;
  customErrorId?: string;
  customErrorRef?: React.Ref<any>;
  cssClasses?: string;
}

const Input: React.FC<IComponentProps> = ({
  label,
  id,
  elRef,
  onChange,
  type = 'text',
  isRequired = false,
  customErrorId = undefined,
  customErrorRef = null,
  cssClasses = undefined,
}) => {
  const hasCustomError = !isNullOrWhiteSpace(customErrorId);
  const css = `input-text ${isNullOrWhiteSpace(cssClasses) ? '' : cssClasses}`;

  return (
    <div className='input-element'>
      <label>
        {label} {isRequired ? <sup>*</sup> : <></>}{' '}
      </label>
      <input
        ref={elRef}
        type={type}
        name={id}
        className={css}
        id={id}
        onChange={onChange}
        placeholder={label}
        autoComplete='off'
      />
      <span
        className='modal-error'
        id={hasCustomError ? customErrorId : `error-${id}`}
        ref={customErrorRef}
      ></span>
    </div>
  );
};

export default Input;
