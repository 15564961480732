import IResponse from "../models/response";
import { ISortModel } from "../models/search";
import { IAdminProductSearchCriteria } from "../models/search-criteria";
import { FilterStoreProducts, StoreSearchCriteriaLoaderFilter } from "../models/filters";
import { URLQueries } from "../utils/url-queries";
import { AxiosAuthorized, AxiosBase } from "../common/axios";

export default class ProductService {
  private static serviceEndpoint = `/products`;

  public static async add(product: any): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/add`;

      const { data } = await AxiosAuthorized.post(api, product);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async edit(product: any, id: string): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/edit/${id}`;

      const { data } = await AxiosAuthorized.put(api, product);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async deleteImage(productImageId: number): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/image/${productImageId}`;

      const { data } = await AxiosAuthorized.delete(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async getProduct(id: string): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/info/${id}`;

      const { data } = await AxiosBase.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async getProductWithAllData(id: string): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/all-data/${id}`;

      const { data } = await AxiosAuthorized.get(api, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async addProductImages(formData: FormData, productId: number) {
    try {
      const api = `${this.serviceEndpoint}/upload-images/${productId}`;

      const { data } = await AxiosAuthorized.post(api, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async filter(searchCritera: FilterStoreProducts): Promise<IResponse> {
    try {
      const url = `${this.serviceEndpoint}/filter`;
      const query = URLQueries.addList(searchCritera, '', []);

      const { data } = await AxiosAuthorized.get(url + query);
      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async loadCategories(searchCritera?: StoreSearchCriteriaLoaderFilter, excludeProperties?: string[]): Promise<IResponse> {
    try {
      const url = `${this.serviceEndpoint}/categories`;
      let query = ``;

      query = URLQueries.addList(searchCritera, '', excludeProperties);

      const { data } = await AxiosBase.get(url + query);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async loadSizes(searchCritera?: StoreSearchCriteriaLoaderFilter, excludeProperties?: string[]): Promise<IResponse> {
    try {
      const url = `${this.serviceEndpoint}/sizes`;
      let query = ``;

      query = URLQueries.addList(searchCritera, '', excludeProperties);

      const { data } = await AxiosBase.get(url + query);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async loadGenders(searchCritera?: StoreSearchCriteriaLoaderFilter, excludeProperties?: string[]): Promise<IResponse> {
    try {
      const url = `${this.serviceEndpoint}/genders`;
      let query = ``;

      query = URLQueries.addList(searchCritera, '', excludeProperties);

      const { data } = await AxiosBase.get(url + query);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async loadDiscounts(): Promise<IResponse> {
    try {
      const url = `${this.serviceEndpoint}/discounts`;

      const { data } = await AxiosBase.get(url);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async loadPriceRange(searchCritera?: StoreSearchCriteriaLoaderFilter, excludeProperties?: string[]): Promise<IResponse> {
    try {
      const url = `${this.serviceEndpoint}/price-range`;
      const query = URLQueries.addList(searchCritera, '', excludeProperties);

      const { data } = await AxiosBase.get(url + query);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async loadTotalCount(): Promise<IResponse> {
    try {
      let url = `${this.serviceEndpoint}/total-count`;

      const { data } = await AxiosBase.get(url);

      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async loadBrands(searchCritera?: StoreSearchCriteriaLoaderFilter, excludeProperties?: string[]): Promise<IResponse> {
    try {
      const url = `${this.serviceEndpoint}/brands`;
      let query = ``;

      query = URLQueries.addList(searchCritera, '', excludeProperties);

      const { data } = await AxiosBase.get(url + query);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async loadSeasons(searchCritera?: StoreSearchCriteriaLoaderFilter, excludeProperties?: string[]): Promise<IResponse> {
    try {
      const url = `${this.serviceEndpoint}/seasons`;
      let query = ``;

      query = URLQueries.addList(searchCritera, '', excludeProperties);

      const { data } = await AxiosBase.get(url + query);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async getAll(currentPage: number, resultsPerPage: number, search: IAdminProductSearchCriteria, sort: ISortModel): Promise<IResponse> {
    try {
      let api = `${this.serviceEndpoint}/filter-all?pageNumber=${currentPage}&resultsPerPage=${resultsPerPage}&sortBy=${sort.sortBy}&sortDirection=${sort.sortDirection}`;

      api = URLQueries.addList(search, api, []);

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async pageCount(resultsPerPage: number): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/page-count?resultsPerPage=${resultsPerPage}`;

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async view(productId: number): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/view/${productId}`;

      const { data } = await AxiosAuthorized.put(api, null);

      return data;
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async saveToCart(productId: number): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/save-to-cart/${productId}`;

      const { data } = await AxiosBase.put(api);

      return data;
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async changeImagePriority(imageId: number): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/change-image-priority/${imageId}`;

      const { data } = await AxiosAuthorized.put(api, null);

      return data;
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async recentlyVisited(take: number, productId: number): Promise<IResponse> {
    try {
      let url = `${this.serviceEndpoint}/visited?take=${take}&productId=${productId}`;

      const { data } = await AxiosAuthorized.get(url);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async delete(productId: number): Promise<IResponse> {
    try {
      let url = `${this.serviceEndpoint}/${productId}`;

      const { data } = await AxiosAuthorized.delete(url);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async mostInteracted(): Promise<IResponse> {
    try {
      let url = `${this.serviceEndpoint}/most-interacted`;

      const { data } = await AxiosAuthorized.get(url);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }
}
