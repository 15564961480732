import { useLayoutEffect, useRef } from 'react';
import '../../../styles/Admin.css';
import { isNullOrEmpty } from '../../../utils/strings';
import { isUsernameValid, modalError, modalErrorInput } from '../../../helpers/uiHelper';
import Constants from '../../../common/constants';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import MetaTags from '../../../components/Html/MetaTags';
import { SeoConfig } from '../../../helpers/seo-config';
import { useNavigate } from 'react-router-dom';
import { useDOMLoaderSequence } from '../../../hooks/useDOMLoaderSequence';
import AccountService from '../../../services/account.service';
import FormGroup from '../../../components/Html/FormGroup';
import Input from '../../../components/Html/Input';

const AddEmployee = () => {
  const navigate = useNavigate();

  const resErrorRef = useRef(document.createElement('span'));
  let inputs = document.querySelectorAll<HTMLInputElement>('.input-text');

  const firstnameRef = useRef<HTMLInputElement>(null);
  const lastnameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const emailErrorRef = useRef<HTMLSpanElement>(document.createElement('span'));
  const usernameErrorRef = useRef<HTMLSpanElement>(document.createElement('span'));

  useScrollOnLoad();

  useDOMLoaderSequence();

  useLayoutEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    inputs = document.querySelectorAll<HTMLInputElement>('.input-text');
  }, []);

  const addEmployee = async () => {
    let error = false;

    inputs.forEach((input) => {
      let spanModelError = document.getElementById(`error-${input.id}`) as any;

      if (isNullOrEmpty(input.value)) {
        modalErrorInput(spanModelError, Constants.fieldCannotBeEmpty);
        error = true;
      }
    });

    const username = usernameRef.current?.value;
    const password = passwordRef.current?.value;
    const email = emailRef.current?.value;
    const phone = phoneRef.current?.value;
    const firstname = firstnameRef.current?.value;
    const lastname = lastnameRef.current?.value;

    if (!email!.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
      modalError(emailErrorRef, Constants.emailIsNotValid);
      return;
    }

    if (!isUsernameValid(username!, usernameErrorRef)) {
      return;
    }

    if (error) return;

    const employee = {
      username,
      password,
      email,
      phone,
      firstname,
      lastname,
    };

    let { data: dataRes, ok: isOk } = await AccountService.addEmoloyee(employee);

    if (!isOk) {
      modalError(resErrorRef, dataRes.message);
      return;
    }

   navigate('/admin/users');
  };

  return (
    <>
      <MetaTags data={SeoConfig.AddProduct} />
      <div
        className='sign-in-page register admin-panel-width edit-product-page add-product-page'
        id='admin-panel'
      >
        <section className='form add-page-form'>
          <h1>DODAJ ZAPOSLENOG</h1>

          <FormGroup type='no-wrapper'>
            <Input label='Ime' id='fistname' elRef={firstnameRef} />
            <Input label='Prezime' id='lastname' elRef={lastnameRef} />
          </FormGroup>

          <FormGroup type='no-wrapper'>
            <Input label='Mejl' id='email' elRef={emailRef} customErrorRef={emailErrorRef} />
            <Input label='Broj telefona' id='phone' elRef={phoneRef} />
          </FormGroup>

          <FormGroup type='no-wrapper'>
            <Input
              label='Korisničko ime'
              id='username'
              elRef={usernameRef}
              customErrorRef={usernameErrorRef}
            />
            <Input label='Lozinka' id='password' elRef={passwordRef} type='password' />
          </FormGroup>

          <button onClick={addEmployee}>DODAJ</button>
          <span ref={resErrorRef} className='modal-error'></span>
        </section>
      </div>
    </>
  );
};

export default AddEmployee;
