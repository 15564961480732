import { ISortModel } from "../models/search";
import IResponse from "../models/response";
import { ISettingsSearch } from "../models/search-criteria";
import { URLQueries } from "../utils/url-queries";
import { AxiosAuthorized } from "../common/axios";

export default class SettingsService {
  private static serviceEndpoint: string = `/settings`;

  public static async totalCount() {
    try {
      const api = `${this.serviceEndpoint}/total-count`;

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async filter(sp: ISettingsSearch, sort: ISortModel, resultsPerPage: number, currentPage: number): Promise<IResponse> {
    try {
      let api = `${this.serviceEndpoint}/filter?sortBy=${sort.sortBy}&sortDirection=${sort.sortDirection}&resultsPerPage=${resultsPerPage}&pageNumber=${currentPage}`;

      api = URLQueries.addList(sp, api, []);

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async add(req: any): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/`;

      const { data } = await AxiosAuthorized.post(api, req);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async edit(reqData: any): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/${reqData.id}`;

      const { data } = await AxiosAuthorized.put(api, reqData);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async delete(id: any): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/${id}`;

      const { data } = await AxiosAuthorized.delete(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }
}
