import React, { useState, useId } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

interface SliderListProps {
  children: React.ReactElement;
  title: string;
  defaultState?: boolean;
}

export const SliderList: React.FC<SliderListProps> = ({
  children,
  title,
  defaultState = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultState);
  const key = useId();

  return (
    <div className='store-search-block' key={key}>
      <div className='store-headline' onClick={() => setIsOpen(!isOpen)}>
        <h3>{title}</h3>
        {isOpen ? <AiOutlineMinus /> : <AiOutlinePlus />}
      </div>
      <div className={isOpen ? 'open-list' : 'close-list'}>{children}</div>
    </div>
  );
};
