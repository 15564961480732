import React, { useState, useEffect } from 'react';
import { IWebImage } from '../../models/web-images';
import { WebImagesPositionMapping } from '../../common/data';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../helpers/uiHelper';
import { WebImageStatus } from '../../common/enums';
import { isNullOrWhiteSpace } from '../../utils/strings';
import DeleteWebImageModal from './DeleteWebImageModal';
import OpenImage from '../Html/OpenImage';

interface IComponentProps {
  data: IWebImage;
  loadData: () => Promise<void>;
}

const WebImageListItem: React.FC<IComponentProps> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState<boolean>(false);
  const [renderModal, setRenderModal] = useState<boolean>(false);

  useEffect(() => {
    if (isPhotoModalOpen) {
      setRenderModal(true);
    } else {
      setTimeout(() => {
        setRenderModal(false);
      }, 275);
    }
  }, [isPhotoModalOpen]);

  const navigate = useNavigate();

  return (
    <>
      {renderModal && (
        <OpenImage isOpen={isPhotoModalOpen} setIsOpen={setIsPhotoModalOpen} url={data.url} />
      )}
      <DeleteWebImageModal id={data.id} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      <div className='user-data-wrapper'>
        <div className='primary-order-data'>
          <div className='align-items'>
            <h1 className='break-long-word'>
              {WebImagesPositionMapping.find((x) => x.value === data.imagePosition)?.title}
            </h1>
          </div>
          <div className='align-items'>
            {data.status === WebImageStatus.Uploaded && (
              <button className='order-button' onClick={() => setIsPhotoModalOpen(true)}>
                PRIKAŽI SLIKU
              </button>
            )}
            <button className='order-button' onClick={() => navigate('/admin/web-images/edit')}>
              IZMENI
            </button>
            <button className='order-button edit-order-btn' onClick={() => setIsModalOpen(true)}>
              IZBRIŠI
            </button>
          </div>
        </div>
        <div className='order-data-admin'>
          <div className='contact-data-order-admin user-admin-data'>
            <h1>Osnovne informacije</h1>
            <h3>Naziv: {data.name}</h3>
            <h3>Indeks: {data.displayIndex}</h3>
            <h3>Veličina: {data.size} MB</h3>
            <h3>Tip slike: {data.mimeType}</h3>
            <h3>
              Tip pozicije: {data.isUniquePosition === true ? 'Jedinstvena' : 'Nije jedinstvena'}
            </h3>
          </div>

          <div className='contact-data-order-admin user-admin-data'>
            <h1>Ostalo</h1>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                gap: 7,
              }}
            >
              <h3
                className='user-role-tag'
                style={{
                  backgroundColor: !data.available ? '#fa0a32' : '#16c784',
                }}
              >
                {data.available === true ? 'Dostupna' : 'Nije dostupna'}
              </h3>
              <h3
                className='user-role-tag'
                style={{
                  backgroundColor: data.status === WebImageStatus.Uploading ? '#ffba19' : '#16c784',
                }}
              >
                {data.status === WebImageStatus.Uploading ? 'Postavlja se' : 'Postavljena'}
              </h3>
            </div>
            <h3>
              Vreme postavljanja: &nbsp;
              {formatDate(data.uploadTime)}
            </h3>
            <h3>Opis: {isNullOrWhiteSpace(data.description) ? '----' : data.description}</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebImageListItem;
