import { useLayoutEffect, useState } from 'react';
import MetaTags from '../../components/Html/MetaTags';
import { SeoConfig } from '../../helpers/seo-config';
import { IProductBase } from '../../models/product-base';
import WishlistService from '../../services/wishlist.service';
import ProductListItem from '../../components/Product/Other/ProductListItem';
import EmptyWishlist from '../../components/Wishlist/EmptyWishlist';
import DeleteWishlistModal from '../../components/Wishlist/DeleteWishlistModal';

const Wishlist = () => {
  const [products, setProducts] = useState<IProductBase[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useLayoutEffect(() => {
    (async () => {
      const { data, ok } = await WishlistService.getAll();

      if (ok) {
        setProducts(data);
        setReload(false);
      }
    })();
  }, [reload]);

  return (
    <>
      <DeleteWishlistModal setReload={setReload} setIsOpen={setIsOpen} isOpen={isOpen} />
      <MetaTags data={SeoConfig.Wishlist} />
      <div className='dashboard min-height-block remove-top-padding wishlist-dashboard'>
        {products.length > 0 ? (
          <>
            <div className='backoffice-options wishlist-header'>
              <h1>Broj proizvoda: {products.length}</h1>
              <button className='delete-all-saved' onClick={() => setIsOpen(true)}>
                IZBRIŠI SVE
              </button>
            </div>
            <div className='related-products-mapping wishlist-products'>
              {products.map((product, index) => (
                <ProductListItem setReload={setReload} product={product} key={index} />
              ))}
            </div>
          </>
        ) : (
          <EmptyWishlist />
        )}
      </div>
    </>
  );
};

export default Wishlist;
