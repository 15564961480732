import React, { useContext } from 'react';
import { SliderList } from '../Html/SliderList';
import FormGroup from '../Html/FormGroup';
import useResize from '../../hooks/useResize';
import Select from '../Html/Select';
import { IAdminBackofficeSearchContext } from '../../models/context';
import { WebImagesPositionMapping } from '../../common/data';
import { WebImagesContext } from '../../pages/admin/webimages/WebImages';

const AdminBackofficeSearch = () => {
  const { dispatch } = useContext<Partial<IAdminBackofficeSearchContext>>(WebImagesContext);
  const searchOpenDefaultState = useResize() > 1000;

  return (
    <div className='search-product-admin admin-search-mobile'>
      <SliderList title='Pretraga' defaultState={searchOpenDefaultState}>
        <div className='admin-inputes mobile-admin-inputs'>
          <FormGroup type='w-50'>
            <Select
              hasNoValue={true}
              label='Pozicija'
              onChange={(e) => dispatch!({ type: 'position', payload: Number(e.target.value)})}
            >
              {WebImagesPositionMapping.map((x) => (
                <option value={x.value} key={x.value}>
                  {x.title}
                </option>
              ))}
            </Select>
          </FormGroup>
          <FormGroup type='w-50'>
            <Select
              hasNoValue={true}
              label='Status'
              onChange={(e) => dispatch!({ type: 'available', payload: Number(e.target.value) === 1 })}
            >
              <option value='0'>Nije aktivan</option>
              <option value='1'>Aktivan</option>
            </Select>
          </FormGroup>
        </div>
      </SliderList>
      <div className='buttons-align'>
        <div className='buttons-wrp'>
          <button
            className='button-el-classic reset-btn-admin'
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => {
              dispatch!({ type: 'reset' });
            }}
          >
            RESETUJ
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminBackofficeSearch;
