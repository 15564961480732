import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useResizeEvent = (breakpoint: number) => {
  const [shouldResize, setShouldResize] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth > breakpoint) {
        setShouldResize(true);
      } else {
        setShouldResize(false);
      }
    };

    resize();

    window.addEventListener("load", resize);
    window.addEventListener("resize", resize);
    window.addEventListener("popstate", resize);

    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("popstate", resize);
    };
  }, [breakpoint, location]);

  return shouldResize;
};

export default useResizeEvent;
