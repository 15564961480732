import { useState, useLayoutEffect, useRef } from 'react';
import '../../../styles/Admin.css';
import { base64ReversedDecrypt, isNullOrEmpty } from '../../../utils/strings';
import { modalError, modalErrorInput } from '../../../helpers/uiHelper';
import Constants from '../../../common/constants';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import MetaTags from '../../../components/Html/MetaTags';
import { SeoConfig } from '../../../helpers/seo-config';
import { useNavigate, useParams } from 'react-router-dom';
import { useDOMLoaderSequence } from '../../../hooks/useDOMLoaderSequence';
import MailServerService from '../../../services/mailserver.service';
import FormGroup from '../../../components/Html/FormGroup';
import Input from '../../../components/Html/Input';
import Select from '../../../components/Html/Select';
import { IMailServer } from '../../../models/mail-server';

const EditMailServer = () => {
  const navigate = useNavigate();

  const [server, setServer] = useState<IMailServer | null>(null);
  const [enabled, setEnabled] = useState<boolean>(true);

  const { id } = useParams();

  const resErrorRef = useRef(document.createElement('span'));
  let inputs = document.querySelectorAll<HTMLInputElement>('.input-text');

  const enabledRef = useRef<HTMLSelectElement>(null);
  const hostRef = useRef<HTMLInputElement>(null);
  const portRef = useRef<HTMLInputElement>(null);
  const senderMailRef = useRef<HTMLInputElement>(null);
  const senderPasswordRef = useRef<HTMLInputElement>(null);
  const serviceTypeRef = useRef<HTMLInputElement>(null);

  useScrollOnLoad();

  useDOMLoaderSequence();

  useLayoutEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    inputs = document.querySelectorAll<HTMLInputElement>('.input-text');
  }, []);

  useLayoutEffect(() => {
    const getServer = async () => {
      const { data, ok } = await MailServerService.getById(Number(id));

      if (ok) {
        setServer(data);
      }
    };

    getServer();
  }, [id]);

  useLayoutEffect(() => {
    if (server) {
      enabledRef.current!.value = server?.enabled ? '1' : '0';
      hostRef.current!.value = server?.host ?? '';
      portRef.current!.value = server?.port.toString() ?? '';
      senderMailRef.current!.value = server?.senderMail.toString() ?? '';
      senderPasswordRef.current!.value = base64ReversedDecrypt(server?.senderPassword ?? '');
      serviceTypeRef.current!.value = server?.serviceType ?? '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [server]);

  const editServer = async () => {
    let error = false;

    inputs.forEach((input) => {
      let spanModelError = document.getElementById(`error-${input.id}`) as any;

      if (isNullOrEmpty(input.value)) {
        modalErrorInput(spanModelError, Constants.fieldCannotBeEmpty);
        error = true;
      }

      if (input.id === 'port' && isNaN(parseFloat(input.value)) && !error) {
        modalErrorInput(spanModelError, Constants.portMustBeNumber);
        error = true;
      }
    });

    if (error) return;

    const host = hostRef.current!.value;
    const senderMail = senderMailRef.current!.value;
    const senderPassword = senderPasswordRef.current!.value;
    const port = parseFloat(portRef.current!.value);
    const serviceType = serviceTypeRef.current!.value;

    const server = {
      id,
      host,
      port,
      senderMail,
      senderPassword,
      serviceType,
      enabled,
    };

    let { data: productData, ok: productOk } = await MailServerService.edit(server);

    if (!productOk) {
      modalError(resErrorRef, productData.message);
      return;
    }

    navigate('/admin/mail-servers');
  };

  return (
    <>
      <MetaTags data={SeoConfig.AddProduct} />
      {server && (
        <div
          className='sign-in-page register admin-panel-width edit-product-page add-product-page'
          id='admin-panel'
        >
          <section className='form add-page-form'>
            <h1>IZMENI SERVER</h1>

            <FormGroup type='no-wrapper'>
              <Input label='Host' id='host' elRef={hostRef} />
              <Input label='Port' id='port' elRef={portRef} type='numeric' />
            </FormGroup>

            <FormGroup type='no-wrapper'>
              <Input label='Emajl pošiljaoca' id='sender-mail' elRef={senderMailRef} />
              <Input label='Lozinka' id='sender-password' elRef={senderPasswordRef} />
            </FormGroup>

            <FormGroup type='no-wrapper'>
              <Select
                label='Operativan'
                elRef={enabledRef}
                id='enabled'
                onChange={(x) => setEnabled(Number(x.target.value) === 1)}
              >
                <option value={1}>Da</option>
                <option value={0}>Ne</option>
              </Select>
              <Input label='Tip servisa' id='service-type' elRef={serviceTypeRef} />
            </FormGroup>

            <button onClick={editServer}>SAČUVAJ</button>
            <span ref={resErrorRef} className='modal-error'></span>
          </section>
        </div>
      )}
    </>
  );
};

export default EditMailServer;
