import React, { useLayoutEffect, useState } from 'react';
import { ICloudinaryStatistics } from '../../models/cloudinary';
import { defaultCloudinaryInfo } from '../../common/data';
import StatisticsService from '../../services/statistics.service';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { TbResize } from 'react-icons/tb';
import { BiImages } from 'react-icons/bi';
import { SiWindows11 } from 'react-icons/si';
import CloudinaryLogo from '../../assets/cloudinary_logo.png';
import { quotaStatus } from '../../utils/cloudinary';
import CountUp from 'react-countup';

const CloudinaryStatistics = () => {
  const [cloudinaryStatistics, setCloudinaryStatistics] =
    useState<ICloudinaryStatistics>(defaultCloudinaryInfo);

  useLayoutEffect(() => {
    (async () => {
      const cloudinaryData = await StatisticsService.cloudinary();
      setCloudinaryStatistics(cloudinaryData.data);
    })();
  }, []);

  return (
    <div className='statistics-by-period cloudinary-stats'>
      <div
        className='period-selection period-selection-cloudinary'
        style={{ backgroundColor: 'transparent', alignItems: 'center' }}
      >
        <img
          src={CloudinaryLogo}
          alt='Cloudinary logo'
          width={30}
          height={30}
          style={{ borderRadius: 3, marginRight: 7 }}
        />
        <h1 style={{ lineHeight: 1 }}>Cloudinary statistika</h1>
      </div>
      <div className='period-data-mapping cloudinary-data-mapping'>
        <div className='cloudinary-div'>
          <div className='cloudinary-data cloudinary-data-first'>
            <div className='cloudinary-data-title'>
              <SiWindows11 className='windows-logo' />
              <h3>Upotreba kredita u poslednjih 30 dana</h3>
            </div>
            <div className='cloudinary-precentage'>
              <h1>
                {cloudinaryStatistics?.credits.usage} / {cloudinaryStatistics?.credits.limit}
              </h1>
              <p>
                <b>{cloudinaryStatistics?.credits?.usedPercentage}% </b>iskorišćeno
              </p>
            </div>
          </div>
          <div className='other-cloudinary-stats'>
            <div className='cloudinary-data-title'>
              <BiImages className='windows-logo' />
              <div className='mini-cloudinary-stat'>
                <p>Broj resursa</p>
                <h1>
                  <CountUp end={cloudinaryStatistics?.resourceCount ?? 0} />
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className='cloudinary-div'>
          <div className='other-cloudinary-stats'>
            <div className='cloudinary-data-title'>
              <AiOutlineInfoCircle className='windows-logo' />
              <div className='mini-cloudinary-stat'>
                <h4>Ime: {cloudinaryStatistics?.cloudinaryName}</h4>
                <div className='cloudinary-plan'>{cloudinaryStatistics?.plan}</div>
              </div>
            </div>
          </div>
          <div className='other-cloudinary-stats'>
            <div className='cloudinary-data-title'>
              <TbResize className='windows-logo' />
              <div className='mini-cloudinary-stat'>
                <p>Broj transformacija</p>
                <div className='inline-cloudinary'>
                  <h1>{cloudinaryStatistics?.transformations.usage}</h1>
                  <p>{cloudinaryStatistics?.transformations?.creditUsage} kredita</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='quota-status'
          style={{
            backgroundColor: quotaStatus(cloudinaryStatistics?.credits?.usedPercentage ?? 0).color,
          }}
        >
          <h3>{quotaStatus(cloudinaryStatistics?.credits?.usedPercentage ?? 0).title}</h3>
        </div>
      </div>
    </div>
  );
};

export default CloudinaryStatistics;
