import React from 'react';
import { BsCircleFill } from 'react-icons/bs';
import { formatDateOnly } from '../../helpers/uiHelper';
import ICoupon from '../../models/coupon';
import useResize from '../../hooks/useResize';

interface IComponentProps {
  coupon: ICoupon;
  setCurrentCoupon: React.Dispatch<React.SetStateAction<ICoupon | null>>;
  setStatusIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CouponListItem: React.FC<IComponentProps> = ({
  coupon,
  setCurrentCoupon,
  setStatusIsOpen,
}) => {
  const shouldRerender = useResize() <= 650;

  return (
    <div className='primary-order-data' style={{ margin: '5px 0' }}>
      {!shouldRerender && (
        <>
          <div className='contact-data-order-admin primary-coupon-width'>
            <div className='align-items'>
              <BsCircleFill color={coupon.active ? '#000' : '#fa0a32'} />
              <h1 style={{ color: coupon.active ? '#000' : '#fa0a32' }}>Kod: {coupon.code}</h1>
            </div>
            <h3>Datum isteka: {formatDateOnly(coupon.expireTime)}</h3>
          </div>
          <div className='coupon-discount'>
            <span>-{coupon.discount}%</span>
          </div>
          <div className='align-items'>
            {coupon.active ? (
              <button
                className='order-button'
                onClick={() => {
                  setStatusIsOpen(true);
                  setCurrentCoupon(coupon);
                }}
              >
                Promeni status
              </button>
            ) : (
              <div className='order-button' style={{ visibility: 'hidden' }}></div>
            )}
          </div>
        </>
      )}
      {shouldRerender && (
        <div className='mobile-coupon'>
          <div className='discount-and-data-mobile'>
            <div className='contact-data-order-admin primary-coupon-width'>
              <div className='align-items'>
                <BsCircleFill color={coupon.active ? '#000' : '#fa0a32'} />
                <h1 style={{ color: coupon.active ? '#000' : '#fa0a32' }}>Kod: {coupon.code}</h1>
              </div>
              <h3>Datum isteka: {formatDateOnly(coupon.expireTime)}</h3>
            </div>
            <div className='coupon-discount'>
              <span>-{coupon.discount}%</span>
            </div>
          </div>
          {coupon.active && (
            <button
              className='order-button edit-coupon-mobile'
              onClick={() => {
                setStatusIsOpen(true);
                setCurrentCoupon(coupon);
              }}
            >
              Promeni status
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default CouponListItem;
