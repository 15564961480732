export enum UserRole {
  Buyer = 0,
  Admin = 1,
  Employee = 2
}

export enum Gender {
  Male = 0,
  Female = 1,
  Kids = 2
}

export enum OrderStatus {
  Processing = 0,
  Delivering = 1,
  Finished = 2,
  Declined = 3
}

export enum ImageType {
  Normal = 0,
  Main = 1
}

export enum MailTemplateType {
  VerifyAccount = 0,
  AccountActivated = 1,
  ResetPassword = 2,
  OrderConfirmation = 3,
  OrderStatusUpdated = 4,
}

export enum SettingKey {
  ClientAppUrl = "ClientApp.URL",
  VerifyAccountUrl = "ClientApp.AccountVerificationURL",
  AdminEmail = "Admin.Email",
  AccountVerificationExpirationTokenTime = "Account.VerificationTokenExpireTimeInHours",
  ForgotPasswordTokenExpireTime = "Account.ForgotPasswordTokenExpirationTimeInHours",
  EnableAccountVerification = "Account.EnableAccountVerification",
  CloudinaryName = "Cloudinary.Name",
  CloudinaryApiKey = "Cloudinary.ApiKey",
  CloudinaryApiSecret = "Cloudinary.ApiSecret",
  AllowedMimeTypes = "Cloudinary.AllowedMimeTypes",
  AllowedMaxFileSize = "Cloudinary.MaxFileSizeInMB",
  EnableImageCompression = "Cloudinary.EnableImageCompression"
}

export enum SettingDataType {
  Number = 0,
  String = 1,
  Boolean = 2
}

export enum DashboardSearchPeriods {
  Today = 0,
  ThisWeek = 1,
  ThisMonth = 2,
  Last3Months = 3,
  Last6Months = 4,
  Year = 5,
  AllTheTime = 6
}

export enum WebImagePosition {
  Banner = 0,
  MalePoster = 1,
  FemalePoster = 2,
  KidsPoster = 3,
  PosterMain = 4,
  TopRightPoster = 5,
  BottomRightPoster = 6,
  LeftPoster = 7,
  RightPoster = 8,
  NewCollectionPoster = 9,
  DiscountPoster = 10
}

export enum WebImageStatus {
  Uploading = 0,
  Uploaded = 1
}

export enum CloudinaryStatus {
  Ok = 0,
  Warning = 1,
  Danger = 2
}