import React from 'react';
import { BsChevronUp } from 'react-icons/bs';

const ScrollToTop = () => {
  const [visible, setVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > window.screen.height / 3) {
        setVisible(true);
      }

      if (window.scrollY < window.screen.height / 3) {
        setVisible(false);
      }
    });
  }, []);

  return visible ? (
    <button
      className='scroll-to-top-btn'
      onClick={() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }}
    >
      <BsChevronUp />
    </button>
  ) : (
    <></>
  );
};

export default ScrollToTop;
