import IUserRegister from "../models/user-register";
import IResponse from "../models/response";
import { ISortModel } from "../models/search";
import { IAdminUserSearchCriteria } from "../models/search-criteria";
import { URLQueries } from "../utils/url-queries";
import { AxiosAuthorized, AxiosBase } from "../common/axios";

export default class AccountService {
  private static serviceEndpoint: string = `/account`;

  public static async signUp(user: IUserRegister): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/sign-up`;

      const { data } = await AxiosBase.post(api, user);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async info() {
    try {
      const api = `${this.serviceEndpoint}/info`;

      const { data } = await AxiosAuthorized.get(api);

      return data;
    } catch (error) {
      localStorage.removeItem("_token");
      return null;
    }
  }

  public static async verifyUserToken(token: string | any) {
    try {
      const api = `${this.serviceEndpoint}/verify/${token}`;

      const { data } = await AxiosBase.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async resendVerificationEmail(email: string | any) {
    try {
      const api = `${this.serviceEndpoint}/resend-verification`;

      const { data } = await AxiosBase.put(api, { email });

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/change-password`;

      const { data } = await AxiosAuthorized.put(
        api,
        { oldPassword, newPassword },
      );

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async editContact(
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    county: string,
    city: string,
    address: string,
    zip: number
  ): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/edit`;

      const { data } = await AxiosAuthorized.put(
        api,
        { firstname, lastname, email, phone, county, city, address, zip },
      );

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async getAll(sp: IAdminUserSearchCriteria, sort: ISortModel, resultsPerPage: number, currentPage: number): Promise<IResponse> {
    try {
      let api = `${this.serviceEndpoint}/all?sortBy=${sort.sortBy}&sortDirection=${sort.sortDirection}&resultsPerPage=${resultsPerPage}&pageNumber=${currentPage}`;

      api = URLQueries.addList(sp, api, []);

      const { data } = await AxiosAuthorized.get(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async deleteUserAccountByAdmin(userId: number): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/${userId}`;

      const { data } = await AxiosAuthorized.delete(api);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async changeVerificationStatus(userId: number, isVerified: boolean): Promise<IResponse> {
    try {
      const api = `${this.serviceEndpoint}/change-status/${userId}`;

      const { data } = await AxiosAuthorized.put(api, { isVerified });

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async addEmoloyee(requestData: any) {
    try {
      const api = `${this.serviceEndpoint}/add-employee`;

      const { data } = await AxiosAuthorized.post(api, requestData);

      return { data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }

  public static async statistics(): Promise<IResponse> {
    try {
      let url = `${this.serviceEndpoint}/statistics`;

      const { data } = await AxiosAuthorized.get(url);
      return { data: data, ok: true };
    } catch (error: any) {
      return { data: error.response.data[0], ok: false };
    }
  }
}
