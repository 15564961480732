import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISavedProduct } from "../../models/product-base";

interface InitalState {
  value: boolean;
  products: ISavedProduct[];
}

const initialState: InitalState = { value: false, products: [] };

const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState: initialState,
  reducers: {
    openCart: (state) => {
      state.value = !state.value;
    },
    addToCart: (state, action: PayloadAction<Partial<ISavedProduct>>) => {
      let product = action.payload;
      let productCartId = `${product.id}-${product.size?.id}`;

      product.productCartId = productCartId;

      let exsistingProduct: any = state.products.find((x) => x.productCartId === productCartId);

      if (!exsistingProduct) {
        state.products.push(product as ISavedProduct);
      }

      if (exsistingProduct) {
        const products = state.products;

        for (let i = 0; i < products.length; i++) {
          let item = products[i];

          if (item.productCartId === product.productCartId) {
            item.size.count = product?.size?.count ?? 1;
          }
        }

        state.products = products;
      }

      localStorage.setItem("cart", JSON.stringify(state.products));
    },
    removeFromCart: (state, action: PayloadAction<string>) => {
      const productCartId = action.payload;

      state.products = state.products.filter(
        (x) => x.productCartId !== productCartId
      );

      if (state.products.length === 0) {
        localStorage.removeItem("cart")
      } else {
        localStorage.setItem("cart", JSON.stringify(state.products));
      }
    },
    increaseProductCount: (state, action: PayloadAction<ISavedProduct>) => {
      const product = action.payload;
      const products = state.products;

      for (let i = 0; i < products.length; i++) {
        let item = products[i];

        if (item.productCartId === product.productCartId) {
          item.size.count = product.size.count;
        }
      }

      state.products = products;

      localStorage.setItem("cart", JSON.stringify(state.products));
    },
    loadProducts: (state, action: PayloadAction<ISavedProduct[]>) => {
      state.products = action.payload;
    },
    removeAllFromCart: (state) => {
      state.products = [];
      localStorage.removeItem("cart");
    },
  },
});

export const {
  openCart,
  addToCart,
  removeFromCart,
  increaseProductCount,
  loadProducts,
  removeAllFromCart,
} = shoppingCartSlice.actions;
export default shoppingCartSlice.reducer;
