import { useState, useLayoutEffect, useRef } from 'react';
import '../../../styles/Admin.css';
import { CloudinaryStatus, Gender } from '../../../common/enums';
import Modal from '../../../components/Html/Modal';
import { LuImagePlus } from 'react-icons/lu';
import { isNullOrEmpty } from '../../../utils/strings';
import { modalError, modalErrorInput } from '../../../helpers/uiHelper';
import Constants from '../../../common/constants';
import { ISizeBase } from '../../../models/product-base';
import ProductService from '../../../services/product.service';
import { BsFillTrashFill } from 'react-icons/bs';
import { AiFillEdit } from 'react-icons/ai';
import { Categories, genders } from '../../../common/data';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import MetaTags from '../../../components/Html/MetaTags';
import { SeoConfig } from '../../../helpers/seo-config';
import { useNavigate } from 'react-router-dom';
import { useDOMLoaderSequence } from '../../../hooks/useDOMLoaderSequence';
import LoadingScreen from '../../../components/Html/LoadingScreen';
import Environment from '../../../common/environment';
import Input from '../../../components/Html/Input';
import Select from '../../../components/Html/Select';
import ToggleSwitch from '../../../components/Html/ToggleSwitch';
import FormGroup from '../../../components/Html/FormGroup';
import OpenImage from '../../../components/Html/OpenImage';
import Notification from '../../../components/Html/Notification';
import { useCloudinaryStatusChecker } from '../../../hooks/useCloudinaryStatusChecker';

type FileChangeEvent = React.ChangeEvent<HTMLInputElement>;

const AddProduct = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sizes, setSizes] = useState<ISizeBase[]>([]);
  const [primaryImage, setPrimaryImage] = useState<number | undefined>(undefined);
  const [files, setFiles] = useState<File[]>([]);
  const [gender, setGender] = useState<Gender>(Gender.Male);
  const [productImages, setProductImages] = useState<string[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [mainImage, setMainImage] = useState<string>('');
  const [cloudinaryDisabled, setCloudinaryDisabled] = useState<boolean>(false);

  const cloudinaryStatus = useCloudinaryStatusChecker();

  const resErrorRef = useRef(document.createElement('span'));
  let inputs = document.querySelectorAll<HTMLInputElement>('.input-text');

  const articleNameRef = useRef<HTMLInputElement>(null);
  const articleCodeRef = useRef<HTMLInputElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);
  const categoryRef = useRef<HTMLSelectElement>(null);
  const genderRef = useRef<HTMLSelectElement>(null);
  const interiorRef = useRef<HTMLInputElement>(null);
  const exteriorRef = useRef<HTMLInputElement>(null);
  const soleRef = useRef<HTMLInputElement>(null);
  const countRef = useRef<HTMLInputElement>(null);
  const discountRef = useRef<HTMLInputElement>(null);
  const brandRef = useRef<HTMLInputElement>(null);
  const sizeRef = useRef<HTMLInputElement>(null);
  const availableRef = useRef<HTMLInputElement>(null);
  const sizeIndexRef = useRef<number>(-1);
  const expireDiscountDateRef = useRef<HTMLInputElement>(null);
  const seasonRef = useRef<HTMLSelectElement>(null);

  useScrollOnLoad();

  useLayoutEffect(() => {
    if (cloudinaryStatus.status === CloudinaryStatus.Danger) {
      setCloudinaryDisabled(true);
    }
  }, [cloudinaryStatus]);

  useDOMLoaderSequence();

  useLayoutEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    inputs = document.querySelectorAll<HTMLInputElement>('.input-text');
  }, []);

  const addProduct = async () => {
    let error = false;

    inputs.forEach((input) => {
      let spanModelError = document.getElementById(`error-${input.id}`) as HTMLSpanElement;

      if (input.id !== 'size' && input.id !== 'count') {
        if (isNullOrEmpty(input.value) && input.id !== 'discount') {
          modalErrorInput(spanModelError, Constants.fieldCannotBeEmpty);
          error = true;
        }

        if (input.id === 'price' && isNaN(parseFloat(input.value)) && !error) {
          modalErrorInput(spanModelError, Constants.priceMustBeNumber);
          error = true;
        }

        if (input.id === 'discount' && !isNullOrEmpty(input.value)) {
          if (parseInt(input.value) < 0 || parseInt(input.value) > 100) {
            modalErrorInput(spanModelError, Constants.fieldNotGoodFormat);
            error = true;
          }
        }
      }
    });

    if (error) return;

    const articleName = articleNameRef.current!.value;
    const articleCode = articleCodeRef.current!.value;
    const brand = brandRef.current!.value;
    const price = parseFloat(priceRef.current!.value);
    const category = categoryRef.current!.value;
    const gender = parseInt(genderRef.current!.value);
    const interior = interiorRef.current!.value;
    const exterior = exteriorRef.current!.value;
    const sole = soleRef.current!.value;
    const discount = parseInt(discountRef.current!.value);
    const available = availableRef.current!.checked;
    const expireDiscountDate = expireDiscountDateRef.current!.value ?? null;
    const season = seasonRef.current!.value;

    const availableStatus = productImages.length === 0 || sizes.length === 0 ? false : available;

    let productBase = {
      product: {
        title: articleName,
        brand,
        price,
        exterior,
        interior,
        sole,
        code: articleCode,
        gender,
        category,
        available: availableStatus,
        discount,
        discountExpirationDate: expireDiscountDate,
        season,
      },
      sizes,
    };

    let formData = new FormData();

    if (files !== undefined && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }

      if (primaryImage === undefined || primaryImage === null) {
        setPrimaryImage(0);
      }
    }

    setShowLoading(true);

    let { data: productData, ok: productOk } = await ProductService.add(productBase);

    if (!productOk) {
      setShowLoading(false);
      modalError(resErrorRef, productData.message);
      return;
    }

    if (files !== undefined && files.length > 0) {
      let { data: imageData, ok: imageOk } = await ProductService.addProductImages(
        formData,
        productData.id,
      );

      if (!imageOk) {
        setShowLoading(false);
        modalError(resErrorRef, imageData.message);
        return;
      }

      const primaryEntity = imageData.success[Number(primaryImage)];

      if (primaryEntity) {
        await ProductService.changeImagePriority(primaryEntity.id);
      }
    }

    productImages.forEach((image) => {
      URL.revokeObjectURL(image);
    });

    inputs.forEach((input) => {
      input.value = '';
    });

    setSizes([]);
    setProductImages([]);
    setShowLoading(false);
    document.body.style.overflow = 'auto';
    navigate('/admin/products');
  };

  const saveSize = () => {
    let error = false;

    inputs.forEach((input) => {
      let spanModelError = document.getElementById(`error-${input.id}`) as HTMLSpanElement;

      if ((input.id === 'size' || input.id === 'count') && isNullOrEmpty(input.value)) {
        modalErrorInput(spanModelError, Constants.fieldCannotBeEmpty);
        error = true;
      }

      if (input.id === 'size' && isNaN(parseInt(input.value))) {
        modalErrorInput(spanModelError, Constants.fieldCannotBeString);
        error = true;
      }

      if (input.id === 'count' && isNaN(parseInt(input.value))) {
        modalErrorInput(spanModelError, Constants.fieldCannotBeString);
        error = true;
      }
    });

    if (error) return;

    const size = sizeRef.current!.value;
    const count = parseInt(countRef.current!.value);

    let sizeAlreadyExist = false;

    sizes.forEach((data) => {
      if (data.size === size) sizeAlreadyExist = true;
    });

    if (sizeAlreadyExist) {
      const sizesCopy = [...sizes];

      for (let i = 0; i < sizesCopy.length; i++) {
        if (sizesCopy[i].size === size) {
          sizesCopy[i].count = count;
        }
      }

      setSizes(sizesCopy);
      setIsOpen(false);
      return;
    }

    setSizes([...sizes, { size, count }]);

    sizeRef.current!.value = '';
    countRef.current!.value = '';
  };

  const removeSizeElement = (elem: ISizeBase) => {
    setSizes((oldElements) => {
      return oldElements.filter((elemFilter) => elemFilter !== elem);
    });
  };

  const removeImageElement = (elem: string, file: File | undefined) => {
    let images: string[] = [...productImages];
    let filesArr = files !== undefined ? [...files] : [];

    images = images.filter((x) => x.toLocaleLowerCase().trim() !== elem.toLocaleLowerCase().trim());
    filesArr = filesArr.filter((x) => x.name !== file!.name);

    setProductImages(images);
    setFiles(filesArr);

    if (images.length === 1) {
      setPrimaryImage(0);
    } else if (images.length > 1 && (primaryImage === undefined || primaryImage === null)) {
      setPrimaryImage(0);
    }
  };

  const addImages = (e: FileChangeEvent) => {
    const selectedFiles = e.target.files as FileList;
    let filesArr = [...files];
    let images: string[] = [...productImages];

    if (selectedFiles.length < 1) return;

    for (let i = 0; i < selectedFiles.length; i++) {
      let objectUrl = URL.createObjectURL(selectedFiles[i]);

      images.push(objectUrl);
      filesArr.push(selectedFiles[i]);
    }

    setFiles(filesArr);
    setProductImages(images);

    if (images.length === 1) {
      setPrimaryImage(0);
    } else if (images.length > 1 && (primaryImage === undefined || primaryImage === null)) {
      setPrimaryImage(0);
    }
  };

  return (
    <>
      <OpenImage isOpen={isOpenModal} setIsOpen={setIsOpenModal} url={mainImage} />
      <LoadingScreen isOpen={showLoading} />
      <MetaTags data={SeoConfig.AddProduct} />
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} title='DODAJ VELIČINU'>
        <div className='modal-data-list'>
          <FormGroup type='no-wrapper'>
            <Input label='Veličina' elRef={sizeRef} id='size' />
            <Input label='Količina' elRef={countRef} id='count' type='number' />
          </FormGroup>
          <button onClick={saveSize}>SAČUVAJ</button>
          <span className='modal-error'></span>
        </div>
      </Modal>
      <div className='sign-in-page register admin-panel-width edit-product-page' id='admin-panel'>
        <section className='form add-page-form'>
          <h1>DODAJ NOVI PROIZVOD</h1>

          <FormGroup type='no-wrapper'>
            <Input label='Naziv artikla' elRef={articleNameRef} id='article-name' />
            <Input label='Šifra artikla' elRef={articleCodeRef} id='article-code' />
          </FormGroup>

          <FormGroup type='no-wrapper'>
            <Select
              elRef={genderRef}
              id='gender'
              label='Pol'
              hasNoValue={true}
              onChange={(x) => setGender(Number(x.target.value))}
              defaultValue={Gender.Male}
            >
              {genders.map((x, i) => (
                <option key={i} value={x.id}>
                  {x.name}
                </option>
              ))}
            </Select>
            <Select elRef={categoryRef} id='category' label='Kategorija' hasNoValue={true}>
              {Categories.filter((x) => x.gender === gender).map((x, i) => (
                <option key={i} value={x.title}>
                  {x.title}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup type='no-wrapper'>
            <Input id='price' type='number' label='Cena' elRef={priceRef} />
            <Input id='interior' label='Materijal unutrašnjosti' elRef={interiorRef} />
          </FormGroup>

          <FormGroup type='no-wrapper'>
            <Input id='exterior' label='Materijal spoljašnosti' elRef={exteriorRef} />
            <Input id='sole' label='Đon' elRef={soleRef} />
          </FormGroup>

          <FormGroup type='no-wrapper'>
            <Input id='brand' label='Brend' elRef={brandRef} />
            <Select id='season' label='Sezona' elRef={seasonRef} hasNoValue={true}>
              <option value='Jesen-Zima'>Jesen-Zima</option>
              <option value='Proleće-Leto'>Proleće-Leto</option>
            </Select>
          </FormGroup>

          <FormGroup type='no-wrapper'>
            <Input id='discount' type='number' label='Popust u %' elRef={discountRef} />
            <Input
              id='discount-expire-date'
              type='date'
              label='Datum isteka popusta (opciono)'
              elRef={expireDiscountDateRef}
            />
          </FormGroup>

          <ToggleSwitch label='Dostupan' elRef={availableRef} />

          <div className='address-data'>
            <h3>Veličine</h3>
            <div className='add-size-map'>
              {sizes?.map((elem, index) => (
                <div
                  key={index}
                  className='add-size-map-button add-size-add-page'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '5px 5px',
                    width: 260,
                  }}
                >
                  Veličina: {elem.size} &nbsp; &nbsp; Količina: {elem.count}
                  <div style={{ marginLeft: 10 }}>
                    <AiFillEdit
                      size={20}
                      onClick={() => {
                        sizeRef.current!.value = elem.size.toString();
                        countRef.current!.value = elem.count.toString();
                        sizeIndexRef.current = index;
                        setIsOpen(true);
                      }}
                    />
                    <BsFillTrashFill
                      size={20}
                      color='#fa0a32'
                      style={{ marginLeft: 10 }}
                      onClick={() => removeSizeElement(elem)}
                    />
                  </div>
                </div>
              ))}
              <div
                className='add-size-map-button add-size-add-page'
                onClick={() => setIsOpen(true)}
              >
                + DODAJ NOVU VELIČINU
              </div>
            </div>
          </div>

          <div className='w-img'>
            {cloudinaryDisabled && (
              <div className='disable-image-upload'>
                <h1 style={{ color: '#fa0a32' }}>UPOZORENJE!</h1>
                <strong>
                  {Constants.CloudinaryDanger.replace(
                    '{{PERCENT}}',
                    cloudinaryStatus.usedPercentage.toString(),
                  )}
                  <span className='danger-txt' onClick={() => setCloudinaryDisabled(false)}>
                    ovde
                  </span>
                </strong>
              </div>
            )}
            <div className='product-images-section'>
              <h3>Slike</h3>
              {cloudinaryStatus.status === CloudinaryStatus.Warning && (
                <Notification
                  isOk={true}
                  customTopMargin={0}
                  customBottomMargin={10}
                  title={Constants.CloudinaryWarning.replace(
                    '{{PERCENT}}',
                    cloudinaryStatus.usedPercentage.toString(),
                  )}
                  customColor='#ff9c1c'
                  customBorderColor='#db881b'
                />
              )}
              <div className='add-product-images'>
                <LuImagePlus className='img-ic' />
                <input
                  type='file'
                  name=''
                  id=''
                  multiple
                  accept={Environment.allowedMimeTypes}
                  onChange={(e: FileChangeEvent) => addImages(e)}
                />
              </div>
              <div className='product-images-list'>
                {productImages.map((image, index) => (
                  <div className='product-image-block-admin' key={index}>
                    <div className='remove-img-wrp'>
                      <img
                        key={index}
                        src={image}
                        alt=''
                        onClick={() => {
                          const img = productImages[index];
                          setIsOpenModal(true);
                          setMainImage(img);
                        }}
                      />
                      <BsFillTrashFill
                        color='#ffff'
                        className='remove-image-btn'
                        onClick={() => removeImageElement(image, files[index])}
                      />
                    </div>
                    <input
                      type='radio'
                      name=''
                      checked={index === primaryImage}
                      onChange={() => setPrimaryImage(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <button onClick={addProduct}>KREIRAJ</button>
          <span ref={resErrorRef} className='modal-error'></span>
        </section>
      </div>
    </>
  );
};

export default AddProduct;
