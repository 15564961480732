import React from 'react';
import Modal from '../Html/Modal';
import IUser from '../../models/user';
import AccountService from '../../services/account.service';

interface IComponentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: IUser;
  loadUsers: () => Promise<void>;
}

const DeleteAccountModal: React.FC<IComponentProps> = ({ isOpen, setIsOpen, user, loadUsers }) => {
  const deleteAccount = async () => {
    await AccountService.deleteUserAccountByAdmin(user.id);
    setIsOpen(false);
    await loadUsers();
  };

  return (
    <Modal title='IZBRIŠI NALOG' isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='delete-acc-modal'>
        <p>
          Da li ste sigurni da želite da izbrišete nalog korisniku
          <span style={{ color: '#fa0a32', marginLeft: 7 }}>{user.username}</span>? Svi podaci
          vezani za ovaj nalog uključujući i podatke o porudžbinama će biti zauvek izbrisani i nije
          ih moguće povratiti.
        </p>
        <div className='buttons-delete'>
          <button className='delete-btn' onClick={deleteAccount}>
            IZBRIŠI
          </button>
          <button onClick={() => setIsOpen(false)}>OTKAŽI</button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
