import { useRef, useState, useEffect } from 'react';
import { modalError } from '../../helpers/uiHelper';
import '../../styles/SignIn.css';
import { isNullOrEmpty } from '../../utils/strings';
import Constants from '../../common/constants';
import AccountService from '../../services/account.service';
import Notification from '../Html/Notification';

const ChangePassword = () => {
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [isOk, setIsOk] = useState<boolean>(false);
  const [enableSaveButton, setEnableSaveButton] = useState<boolean>(true);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
        setIsOk(false);
        setMessage('');
      }, 2500);
    }

    setTimeout(() => {
      setEnableSaveButton(true);
    }, 2600);
  }, [show]);

  const oldPasswordRef = useRef(document.createElement('input'));
  const newPasswordRef = useRef(document.createElement('input'));
  const newPasswordConfirmRef = useRef(document.createElement('input'));

  const oldPasswordErrorRef = useRef(document.createElement('span'));
  const newPasswordErrorRef = useRef(document.createElement('span'));
  const newPasswordConfirmErrorRef = useRef(document.createElement('span'));

  const changePassword = async () => {
    const oldPassword = oldPasswordRef.current.value;
    const newPassword = newPasswordRef.current.value;
    const newPasswordConfirm = newPasswordConfirmRef.current.value;

    if (isNullOrEmpty(oldPassword)) {
      modalError(oldPasswordErrorRef, Constants.fieldCannotBeEmpty);
      return;
    }
    if (isNullOrEmpty(newPassword)) {
      modalError(newPasswordErrorRef, Constants.fieldCannotBeEmpty);
      return;
    }
    if (isNullOrEmpty(newPasswordConfirm)) {
      modalError(newPasswordConfirmErrorRef, Constants.fieldCannotBeEmpty);
      return;
    }

    if (newPassword === oldPassword) {
      modalError(newPasswordErrorRef, Constants.newPasswordCannotBeSameAsOldPassword);
      return;
    }

    if (newPassword !== newPasswordConfirm) {
      modalError(newPasswordErrorRef, Constants.newPasswordConfirmMustBeSameAsNewPassword);
      modalError(newPasswordConfirmErrorRef, Constants.newPasswordConfirmMustBeSameAsNewPassword);
      return;
    }

    const { data, ok } = await AccountService.changePassword(oldPassword, newPassword);

    if (ok) {
      setIsOk(true);
      setShow(true);
      setMessage(data.message);
      setEnableSaveButton(false);

      oldPasswordRef.current.value = '';
      newPasswordRef.current.value = '';
      newPasswordConfirmRef.current.value = '';
    } else {
      setIsOk(false);
      setShow(true);
      setMessage(data.message);
      setEnableSaveButton(false);
    }
  };

  return (
    <div className='modal-data-list'>
      <div className='content-wrapper'>
        <div className='input-element'>
          <label>Stara lozinka</label>
          <input
            ref={oldPasswordRef}
            type='password'
            name='oldPassword'
            id='oldPassword'
            placeholder='Stara lozinka'
            autoComplete='off'
          />
          <span ref={oldPasswordErrorRef} className='modal-error'></span>
        </div>
        <div className='input-element'>
          <label>Nova lozinka</label>
          <input
            ref={newPasswordRef}
            type='password'
            name='newPassword'
            id='newPassword'
            placeholder='Nova lozinka'
            autoComplete='off'
          />
          <span ref={newPasswordErrorRef} className='modal-error'></span>
        </div>
        <div className='input-element'>
          <label>Potvrdi lozinku</label>
          <input
            ref={newPasswordConfirmRef}
            type='password'
            name='confirmPassword'
            id='confirmPassword'
            placeholder='Potvrdi lozinku'
            autoComplete='off'
          />
          <span ref={newPasswordConfirmErrorRef} className='modal-error'></span>
        </div>
      </div>
      {enableSaveButton && <button onClick={changePassword}>SAČUVAJ</button>}
      <div style={{ width: '90%' }}>
        {show && enableSaveButton === false && <Notification isOk={isOk} title={message} />}
      </div>
    </div>
  );
};

export default ChangePassword;
