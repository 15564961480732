import React, { useState, useEffect } from 'react';
import { formatPrice } from '../../helpers/uiHelper';
import { IOrderItemBaseResponse } from '../../models/order-base';
import OpenImage from '../Html/OpenImage';

interface IComponentProps {
  product: IOrderItemBaseResponse;
}

const OrderItem: React.FC<IComponentProps> = ({ product }) => {
  const [openImage, setOpenImage] = useState<boolean>(false);
  const [renderModal, setRenderModal] = useState<boolean>(false);

  useEffect(() => {
    if (openImage) {
      setRenderModal(true);
    } else {
      setTimeout(() => {
        setRenderModal(false);
      }, 275);
    }
  }, [openImage]);

  return (
    <>
      {renderModal && (
        <OpenImage
          url={product.image.replace('w_160,', '')}
          setIsOpen={setOpenImage}
          isOpen={openImage}
        />
      )}
      <div className='checkout-product admin order-item-width-admin'>
        <div className='checkout-main'>
          <img
            src={product.image}
            alt={product.title}
            className='checkout-product-image'
            loading='lazy'
            decoding='async'
            onClick={() => setOpenImage(true)}
          />
          <div className='main-checkout-details'>
            <h1 className='checkout-title'>{product.title}</h1>
            {product.productDiscount !== null && product.productDiscount !== 0 && (
              <div className='checkout-discount'>
                <span>-{product.productDiscount}%</span>
              </div>
            )}
            <span className='checkout-code'>Šifra proizvoda: {product.productCode}</span>
            <br />
            <span className='checkout-code'>Kategorija: {product.category}</span>
          </div>
        </div>

        <div className='order-product-info-sizes-middle'>
          <div className='info-model'>
            <h3>VELIČINA</h3>
            <span className='table-size'>{product.size}</span>
          </div>
          <div className='info-model'>
            <h3>KOLIČINA</h3>
            <span className='table-size'>{product.count}</span>
          </div>
        </div>

        <div className='order-product-info'>
          <div className='info-model hide-mobile-info-order'>
            <h3>VELIČINA</h3>
            <span className='table-size'>{product.size}</span>
          </div>
          <div className='info-model hide-mobile-info-order'>
            <h3>KOLIČINA</h3>
            <span className='table-size'>{product.count}</span>
          </div>
          <div className='info-model'>
            <h3>CENA</h3>
            {product.productDiscount !== null && product.productDiscount !== 0 && (
              <h3 className='regular-price'>
                <s>{formatPrice(product.pricePerUnit)}</s>
              </h3>
            )}
            <h1>
              {formatPrice(
                product.pricePerUnit -
                  product.pricePerUnit * ((product.productDiscount ?? 0) / 100),
              )}
            </h1>
          </div>
          <div className='info-model'>
            <h3>UKUPNO</h3>
            <h1>
              {formatPrice(
                product.pricePerUnit * product.count -
                  product.pricePerUnit * product.count * ((product.productDiscount ?? 0) / 100),
              )}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderItem;
