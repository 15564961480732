import { useState, useEffect, useReducer, useLayoutEffect, createContext } from 'react';
import OrderService from '../../../services/order.service';
import { IOrderBaseResponse } from '../../../models/order-base';
import Order from '../../../components/Orders/Order';
import EmptyShoppingCart from '../../../components/Product/Other/EmptyShoppingCart';
import useUser from '../../../hooks/useUser';
import { ISortModel } from '../../../models/search';
import { UserRole } from '../../../common/enums';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import MetaTags from '../../../components/Html/MetaTags';
import { SeoConfig } from '../../../helpers/seo-config';
import NoResultsFound from '../../../components/General/NoResultsFound';
import { useContentWidth } from '../../../hooks/useContentWidth';
import AdminBackofficeSearch from '../../../components/Orders/AdminBackofficeSearch';
import { IAdminBackofficeBaseSearchContext } from '../../../models/context';
import { ordersReducer } from '../../../helpers/reducers';
import Pagination from '../../../components/Pagination/Pagination';
import { sortOrders } from '../../../common/data';

export const OrderContext = createContext<IAdminBackofficeBaseSearchContext>({} as any);

const Orders = () => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [orders, setOrders] = useState<IOrderBaseResponse[]>([]);
  const [maxNumberOfPages, setMaxNumberOfPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [deleteCurrentPage, setDeleteCurrentPage] = useState<boolean>(false);
  const [sort, setSort] = useState<ISortModel>(sortOrders[0]);

  useScrollOnLoad();
  useContentWidth();

  const [search, dispatch] = useReducer(ordersReducer, {});

  const user = useUser();

  useLayoutEffect(() => {
    (async () => {
      const { data } = await OrderService.loadTotalCount();
      setTotalCount(data.count);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (totalCount > 0) {
        await getOrders();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, search, sort, resultsPerPage, currentPage, totalCount]);

  useEffect(() => {
    setDeleteCurrentPage(true);
  }, [search]);

  useEffect(() => {
    if (deleteCurrentPage) {
      setCurrentPage(0);
      setDeleteCurrentPage(false);
    }
  }, [deleteCurrentPage]);

  const getOrders = async () => {
    const { data, ok } = await OrderService.getAll(search, sort, resultsPerPage, currentPage);
    if (ok) {
      setOrders(data.data);
      setMaxNumberOfPages(data.pageCount);
    }
  };

  const contextReference: IAdminBackofficeBaseSearchContext = {
    setCurrentPage,
    setDeleteCurrentPage,
    setResultsPerPage,
    setSort,
    maxNumberOfPages,
    currentPage,
    resultsPerPage,
    sort,
    dispatch,
  };

  const isAdmin = user?.roleId === UserRole.Admin || user?.roleId === UserRole.Employee;

  return (
    <>
      <MetaTags data={isAdmin ? SeoConfig.OrdersAdmin : SeoConfig.Orders} />
      <div
        className={`dashboard min-height-block ${isAdmin ? ' admin-panel-width' : ''}`}
        id={`${isAdmin ? 'admin-panel' : 'xxx'}`}
        style={!isAdmin ? { paddingTop: 0 } : {}}
      >
        {totalCount > 0 && isAdmin && <AdminBackofficeSearch dispatch={dispatch} />}

        <OrderContext.Provider value={contextReference}>
          {totalCount > 0 && <Pagination context={OrderContext} sortCategories={sortOrders} />}
        </OrderContext.Provider>

        <div className='orders-map'>
          {totalCount > 0 &&
            orders.map((order, index) => (
              <Order order={order} index={index} key={index} getOrders={getOrders} />
            ))}
          {totalCount === 0 && <EmptyShoppingCart title='TRENUTNO NEMATE PORUDŽBINA' />}
          {totalCount > 0 && orders.length === 0 && <NoResultsFound />}
        </div>
      </div>
    </>
  );
};

export default Orders;
