import React from 'react';
import { isNullOrWhiteSpace } from '../../utils/strings';

interface IComponentProps {
  isOk: boolean;
  title: string;
  customColor?: string;
  customBorderColor?: string;
  customTopMargin?: number;
  customBottomMargin?: number;
}

const Notification: React.FC<IComponentProps> = ({
  isOk,
  title,
  customColor,
  customBorderColor,
  customTopMargin,
  customBottomMargin,
}) => {
  let customCss: any = {};

  if (!isNullOrWhiteSpace(customBorderColor) && !isNullOrWhiteSpace(customColor)) {
    customCss = {
      backgroundColor: customColor,
      borderRightColor: customBorderColor,
    };
  }

  if (customTopMargin !== null) {
    customCss.marginTop = customTopMargin;
  }

  if (customBottomMargin !== null) {
    customCss.marginBottom = customBottomMargin;
  }

  return (
    <div className={`notification ${isOk ? 'success' : 'failed'}`} style={customCss}>
      <p>{title}</p>
    </div>
  );
};

export default Notification;
