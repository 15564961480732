import React, { useState, useEffect } from 'react';
import { RiCoupon2Line } from 'react-icons/ri';

interface IComponentProps {
  children: any;
}

const EmptyCoupons: React.FC<IComponentProps> = ({ children }) => {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);
  }, []);

  return show ? (
    <div className='empty-shoppinh-cart'>
      <RiCoupon2Line className='empty-icon' />
      <h1>NEMATE KUPONE</h1>
      <p>Trenutno nemate ni jedan kupon. Kupone koje budete bili dodali biće prikazani ovde.</p>
      <>{children}</>
    </div>
  ) : (
    <></>
  );
};

export default EmptyCoupons;
