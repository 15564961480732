import { useNavigate } from 'react-router-dom';
import '../../styles/NotFound.css';
import MetaTags from '../../components/Html/MetaTags';
import { SeoConfig } from '../../helpers/seo-config';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <MetaTags data={SeoConfig.NotFound} />
      <div className='not-found'>
        <h1>404</h1>
        <p><b>Žao nam je, ali stranica koju ste tražili nije pronađena.</b></p>
        <button onClick={() => navigate('/')}>
          <span>Nazad na početnu</span>
        </button>
      </div>
    </>
  );
};

export default NotFound;
