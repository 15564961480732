export const isNullOrEmpty = (text: string | null | undefined): boolean => {
  if (text === null || text === undefined || text === "") return true;
  return false;
};

export const isNullOrWhiteSpace = (text: string | null | undefined): boolean => {
  let isWhiteSpace = false;
  let whiteSpaceArrays: string[] = [];

  if (text !== null && text !== undefined) {
    for (let i = 0; i < text.length; i++) {
      if (text[i] === " ") whiteSpaceArrays.push(text[i]);
    }

    if (text.length === whiteSpaceArrays.length) isWhiteSpace = true;
  }

  if (text === null || text === undefined || text === "" || isWhiteSpace)
    return true;
  return false;
};

export const isBlobURL = (url: string): boolean => url.startsWith("blob:http://");

export const hasSpace = (value: string): boolean => /\s/.test(value);

export const validateUsername = (value: string): boolean => /^(?=.*[A-Za-z])[A-Za-z0-9._]+$/.test(value);

export const hasOnlyNumbers = (value: string): boolean => /^\d+$/.test(value);

export const hasOnlySpecialCharacters = (value: string): boolean => /^[^A-Za-z0-9]+$/.test(value);

export const hasOnlySpecialCharactersAndNumbers = (value: string): boolean => /^[^A-Za-z]+$/.test(value);

export const base64ReversedDecrypt = (base64: string): string => {
  const binaryString = base64.split('').reverse().join('');

  let bytes = new Uint8Array(binaryString.length);

  for (var i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  let decoder = new TextDecoder("utf-8");
  const decodedString = decoder.decode(bytes);

  return atob(decodedString);
}

export const decodeBase64 = (base64: string): any => atob(base64);