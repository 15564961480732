import { useContentWidth } from "./useContentWidth";

export const useDOMLoaderSequence = () => {
  useContentWidth(5);
  useContentWidth(10);
  useContentWidth(12);
  useContentWidth(15);
  useContentWidth(18);
  useContentWidth(20);
  useContentWidth(22);
  useContentWidth(25);
}
