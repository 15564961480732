import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isBlobURL, isNullOrEmpty, isNullOrWhiteSpace } from '../../../utils/strings';
import ProductService from '../../../services/product.service';
import { modalError, modalErrorInput } from '../../../helpers/uiHelper';
import { ISizeBase } from '../../../models/product-base';
import Constants from '../../../common/constants';
import { LuImagePlus } from 'react-icons/lu';
import { CloudinaryStatus, Gender, ImageType } from '../../../common/enums';
import Modal from '../../../components/Html/Modal';
import { IProduct } from '../../../models/product';
import { AiFillEdit } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { Categories, genders } from '../../../common/data';
import { useScrollOnLoad } from '../../../hooks/useScrollOnLoad';
import MetaTags from '../../../components/Html/MetaTags';
import { SeoConfig } from '../../../helpers/seo-config';
import OpenImage from '../../../components/Html/OpenImage';
import { useDOMLoaderSequence } from '../../../hooks/useDOMLoaderSequence';
import LoadingScreen from '../../../components/Html/LoadingScreen';
import Environment from '../../../common/environment';
import Input from '../../../components/Html/Input';
import Select from '../../../components/Html/Select';
import ToggleSwitch from '../../../components/Html/ToggleSwitch';
import FormGroup from '../../../components/Html/FormGroup';
import { useCloudinaryStatusChecker } from '../../../hooks/useCloudinaryStatusChecker';
import Notification from '../../../components/Html/Notification';

type FileChangeEvent = React.ChangeEvent<HTMLInputElement>;

const EditProduct = () => {
  const [product, setProduct] = useState<IProduct | null>(null);
  const [productState, setProductState] = useState<boolean>(false);
  const [primaryImage, setPrimaryImage] = useState<string | undefined>(undefined);
  const [blobImageIndex, setBlobImageIndex] = useState<number | undefined>(undefined);
  const [hasNoImage, setHasNoImage] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [mainImage, setMainImage] = useState<string>('');
  const [sizes, setSizes] = useState<ISizeBase[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [gender, setGender] = useState<Gender>(Gender.Male);
  const [category, setCategory] = useState<string>('');
  const [productImages, setProductImages] = useState<string[]>([]);
  const [cloudinaryDisabled, setCloudinaryDisabled] = useState<boolean>(false);

  const resErrorRef = useRef(document.createElement('span'));

  const cloudinaryStatus = useCloudinaryStatusChecker();

  const { id } = useParams();

  const navigate = useNavigate();

  let inputs = document.querySelectorAll<HTMLInputElement>('.input-text');

  const articleNameRef = useRef<HTMLInputElement>(null);
  const articleCodeRef = useRef<HTMLInputElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);
  const genderRef = useRef<HTMLSelectElement>(null);
  const categoryRef = useRef<HTMLSelectElement>(null);
  const interiorRef = useRef<HTMLInputElement>(null);
  const exteriorRef = useRef<HTMLInputElement>(null);
  const soleRef = useRef<HTMLInputElement>(null);
  const countRef = useRef<HTMLInputElement>(null);
  const sizeRef = useRef<HTMLInputElement>(null);
  const availableRef = useRef<HTMLInputElement>(null);
  const newRef = useRef<HTMLInputElement>(null);
  const discountRef = useRef<HTMLInputElement>(null);
  const brandRef = useRef<HTMLInputElement>(null);
  const expireDiscountDateRef = useRef<HTMLInputElement>(null);
  const seasonRef = useRef<HTMLSelectElement>(null);

  const sizeIndexRef = useRef<number>(-1);

  useLayoutEffect(() => {
    if (cloudinaryStatus.status === CloudinaryStatus.Danger) {
      setCloudinaryDisabled(true);
    }
  }, [cloudinaryStatus]);

  useScrollOnLoad();
  useDOMLoaderSequence();

  useLayoutEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    inputs = document.querySelectorAll<HTMLInputElement>('.input-text');
  }, []);

  useLayoutEffect(() => {
    (async () => {
      if (!isNullOrEmpty(id)) {
        let { data, ok } = await ProductService.getProductWithAllData(id as string);

        setProduct(data);
        setProductState(ok);
        setGender(data?.gender);
        setCategory(data?.category);
      }
    })();
  }, [id]);

  useLayoutEffect(() => {
    if (productState) {
      articleNameRef.current!.value = product?.title ?? '';
      articleCodeRef.current!.value = product?.code ?? '';
      priceRef.current!.value = product?.price.toString() ?? '';
      genderRef.current!.value = product?.gender.toString() ?? '';
      categoryRef.current!.value = product?.category.toString() ?? '';
      interiorRef.current!.value = product?.interior ?? '';
      exteriorRef.current!.value = product?.exterior ?? '';
      soleRef.current!.value = product?.sole ?? '';
      brandRef.current!.value = product?.brand ?? '';
      discountRef.current!.value = product?.discount?.toString() ?? '0';
      availableRef.current!.checked = product?.available ?? false;
      newRef.current!.checked = product?.isNew ?? false;
      seasonRef.current!.value = product?.season?.toString() ?? '';
      expireDiscountDateRef.current!.value = product?.discountExpirationDate?.toString() ?? '';

      const sizes = product?.sizes?.map(({ size, count, id }) => ({ size, count, id })) || [];
      setSizes(sizes);

      const images: string[] = product?.images?.map((imageUrl) => imageUrl.url) || [];
      setProductImages(images);

      const primary = product?.images?.find((x) => x.type === ImageType.Main);

      if (primary) {
        setPrimaryImage(primary.url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productState]);

  useEffect(() => {
    if (!isOpen) {
      if (sizeRef.current && countRef.current) {
        sizeRef.current!.value = '';
        countRef.current!.value = '';
      }
    }
  }, [isOpen]);

  const addProduct = async () => {
    let error = false;

    inputs.forEach((input) => {
      let spanModelError = document.getElementById(`error-${input.id}`) as HTMLSpanElement;

      if (input.id !== 'size' && input.id !== 'count' && input.id !== 'discount-expire-date') {
        if (isNullOrEmpty(input.value)) {
          modalErrorInput(spanModelError, Constants.fieldCannotBeEmpty);
          error = true;
        }

        if (input.id === 'price' && isNaN(parseInt(input.value)) && !error) {
          modalErrorInput(spanModelError, Constants.priceMustBeNumber);
          error = true;
        }
      }
    });

    if (error) return;

    const articleName = articleNameRef.current!.value;
    const articleCode = articleCodeRef.current!.value;
    const price = parseInt(priceRef.current!.value);
    const gender = parseInt(genderRef.current!.value);
    const category = categoryRef.current!.value;
    const interior = interiorRef.current!.value;
    const exterior = exteriorRef.current!.value;
    const sole = soleRef.current!.value;
    const available = availableRef.current!.checked;
    const isNew = newRef.current!.checked;
    const brand = brandRef.current!.value;
    const discount = discountRef.current!.value ?? 0;
    const expireDiscountDate = isNullOrWhiteSpace(expireDiscountDateRef.current!.value)
      ? null
      : expireDiscountDateRef.current!.value;
    const season = seasonRef.current!.value;
    const availableStatus = productImages.length === 0 ? false : available;

    let productBase = {
      product: {
        title: articleName,
        price,
        exterior,
        interior,
        sole,
        code: articleCode,
        gender,
        category,
        available: availableStatus,
        brand,
        isNew,
        discount,
        discountExpirationDate: expireDiscountDate,
        season,
      },
      sizes,
    };

    let formData = new FormData();

    if (newFiles !== undefined && newFiles.length > 0) {
      for (let i = 0; i < newFiles.length; i++) {
        formData.append('files', newFiles[i]);
      }
    }

    setShowLoading(true);

    let { data: productData, ok: productOk } = await ProductService.edit(productBase, id as string);

    if (!productOk) {
      setShowLoading(false);
      modalError(resErrorRef, productData.message);
      return;
    }

    if (newFiles !== undefined && newFiles.length > 0) {
      let { data: imageData, ok: imageOk } = await ProductService.addProductImages(
        formData,
        product?.id as number,
      );

      if (!imageOk) {
        setShowLoading(false);
        modalError(resErrorRef, imageData.message);
        return;
      }

      if (hasNoImage === true) {
        const primaryEntity = imageData.success[Number(blobImageIndex)];

        if (primaryEntity !== undefined && primaryEntity !== null) {
          await ProductService.changeImagePriority(primaryEntity.id);
        }
      }
    }

    if (primaryImage !== null && primaryImage !== undefined && hasNoImage === false) {
      const imgEntity = product?.images.find((x) => x.url === primaryImage);
      await ProductService.changeImagePriority(imgEntity?.id!);
    }

    productImages.forEach((image) => {
      URL.revokeObjectURL(image);
    });

    inputs.forEach((input) => (input.value = ''));

    setSizes([]);
    setProductImages([]);
    setPrimaryImage(undefined);
    setShowLoading(false);
    document.body.style.overflow = 'auto';
    navigate('/admin/products');
  };

  const saveSize = () => {
    let error = false;
    const size = sizeRef.current!.value;
    let edit = sizes.filter((x) => x.size === size).length > 0;

    inputs.forEach((input) => {
      let spanModelError = document.getElementById(`error-${input.id}`) as HTMLSpanElement;

      if ((input.id === 'size' || input.id === 'count') && isNullOrEmpty(input.value)) {
        modalErrorInput(spanModelError, Constants.fieldCannotBeEmpty);
        error = true;
      }

      if (input.id === 'size' && isNaN(parseInt(input.value))) {
        modalErrorInput(spanModelError, Constants.fieldCannotBeString);
        error = true;
      }

      if (input.id === 'count' && isNaN(parseInt(input.value))) {
        modalErrorInput(spanModelError, Constants.fieldCannotBeString);
        error = true;
      }
    });

    if (error) return;

    const count = parseInt(countRef.current!.value);

    if (edit) {
      const updatedSizes = [...sizes];
      updatedSizes[sizeIndexRef.current].size = size;
      updatedSizes[sizeIndexRef.current].count = count;

      sizeIndexRef.current = -1;
      setSizes(updatedSizes);
    } else {
      setSizes([...sizes, { size, count }]);

      sizeRef.current!.value = '';
      countRef.current!.value = '';
    }

    setIsOpen(false);
  };

  const removeImageElement = (
    elem: string,
    isNewFile: boolean,
    fileIndex: number,
    regularIndex: number,
  ) => {
    let deletedImage = product?.images.find((image) => image.url === elem);

    let images: string[] = [...productImages];
    images = images.filter((x) => x.toLocaleLowerCase().trim() !== elem.toLocaleLowerCase().trim());

    if (productImages[regularIndex] === elem && images.length > 0) {
      setPrimaryImage(images[0]);
    } else {
      setPrimaryImage(undefined);
    }

    let newFileList = [...newFiles];

    if (isNewFile) {
      let fileNameToRemove = newFiles[fileIndex].name;

      newFileList = newFileList.filter((x) => x.name !== fileNameToRemove);
      setNewFiles(newFileList);
    }

    setProductImages(images);

    if (newFileList.length === 0) {
      setHasNoImage(false);
    }

    if (newFileList.length === 0 && product?.images && product?.images.length > 0) {
      setPrimaryImage(product?.images[0].url ?? undefined);
    }

    (async () => {
      if (deletedImage && !isNewFile) {
        await ProductService.deleteImage(deletedImage.id);
      }
    })();
  };

  const addImages = (e: FileChangeEvent) => {
    const selectedFiles = e.target.files as FileList;
    let filesArr = [...newFiles];
    let images: string[] = [...productImages];

    if (images.length === 0) {
      setHasNoImage(true);
      setPrimaryImage(productImages[0]);
    }

    if (selectedFiles.length < 1) return;

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];

      images.push(URL.createObjectURL(file));
      filesArr.push(file);
    }

    setNewFiles(filesArr);
    setProductImages(images);

    if (primaryImage === undefined) {
      setPrimaryImage(images[0]);
      setHasNoImage(true);
      setBlobImageIndex(0);
    }
  };

  const isImagePrimary = (imageUrl: string, index: number) => {
    const url = product?.images.find((x) => x.url === imageUrl)?.url;

    if ((url === undefined || url === null) && hasNoImage === true) {
      return imageUrl === primaryImage;
    } else if ((url === undefined || url === null) && hasNoImage === false) {
      return false;
    } else {
      return url === primaryImage;
    }
  };

  const changeImagePriority = (imageUrl: string, index: number) => {
    const id = product?.images.find((x) => x.url === imageUrl)?.id;

    if (id !== undefined) {
      setHasNoImage(false);
      setPrimaryImage(imageUrl);
      return;
    } else {
      setHasNoImage(true);
      setPrimaryImage(imageUrl);

      let blobImages = productImages.filter((x) => x.includes('blob:http'));
      let primaryBlobImageIndex = blobImages.findIndex((x) => x === productImages[Number(index)]);

      if (primaryBlobImageIndex !== -1 && primaryBlobImageIndex !== undefined) {
        setBlobImageIndex(primaryBlobImageIndex);
      }
    }
  };

  return (
    <>
      <OpenImage isOpen={isOpenModal} setIsOpen={setIsOpenModal} url={mainImage} />
      <LoadingScreen isOpen={showLoading} />
      <MetaTags data={SeoConfig.EditProduct} />
      {productState ? (
        <>
          <Modal isOpen={isOpen} setIsOpen={setIsOpen} title='DODAJ VELIČINU'>
            <div className='modal-data-list'>
              <FormGroup type='no-wrapper'>
                <Input label='Veličina' elRef={sizeRef} id='size' />
                <Input label='Količina' elRef={countRef} id='count' type='number' />
              </FormGroup>
              <button onClick={saveSize}>SAČUVAJ</button>
              <span className='modal-error'></span>
            </div>
          </Modal>
          <div
            className='sign-in-page register admin-panel-width edit-product-page'
            id='admin-panel'
          >
            <section className='form'>
              <h1>IZMENI PROIZVOD</h1>

              <FormGroup type='no-wrapper'>
                <Input id='article-name' label='Naziv artikla' elRef={articleNameRef} />
                <Input id='article-code' label='Šifra artikla' elRef={articleCodeRef} />
              </FormGroup>

              <FormGroup type='no-wrapper'>
                <Select
                  elRef={genderRef}
                  id='gender'
                  label='Pol'
                  defaultValue={Gender.Male}
                  onChange={(x) => setGender(Number(x.target.value))}
                >
                  {genders.map((x, i) => (
                    <option key={i} value={x.id}>
                      {x.name}
                    </option>
                  ))}
                </Select>
                <Select
                  elRef={categoryRef}
                  id='category'
                  label='Kategorija'
                  defaultValue={category}
                  onChange={(x) => setCategory(x.target.value)}
                >
                  {Categories.filter((x) => x.gender === gender).map((x, i) => (
                    <option key={i} value={x.title}>
                      {x.title}
                    </option>
                  ))}
                </Select>
              </FormGroup>

              <FormGroup type='no-wrapper'>
                <Input id='price' type='number' label='Cena' elRef={priceRef} />
                <Input id='interior' label='Materijal unutrašnjosti' elRef={interiorRef} />
              </FormGroup>

              <FormGroup type='no-wrapper'>
                <Input id='exterior' label='Materijal spoljašnosti' elRef={exteriorRef} />
                <Input id='sole' label='Đon' elRef={soleRef} />
              </FormGroup>

              <FormGroup type='no-wrapper'>
                <Input id='brand' label='Brend' elRef={brandRef} />
                <Select id='season' label='Sezona' elRef={seasonRef}>
                  <option value='Jesen-Zima'>Jesen-Zima</option>
                  <option value='Proleće-Leto'>Proleće-Leto</option>
                </Select>
              </FormGroup>

              <FormGroup type='no-wrapper'>
                <Input id='discount' type='number' label='Popust u %' elRef={discountRef} />
                <Input
                  id='discount-expire-date'
                  type='date'
                  label='Datum isteka popusta (opciono)'
                  elRef={expireDiscountDateRef}
                />
              </FormGroup>

              <FormGroup type='column'>
                <ToggleSwitch label='Nova kolekcija' elRef={newRef} />
                <ToggleSwitch label='Dostupan' elRef={availableRef} />
              </FormGroup>

              <div className='address-data'>
                <h3>Veličine</h3>
                <div className='add-size-map'>
                  {sizes?.map((elem, index) => (
                    <div key={index} className='add-size-map-button'>
                      Veličina: {elem.size} &nbsp; &nbsp; Količina: {elem.count}
                      <div style={{ marginLeft: 10 }}>
                        <AiFillEdit
                          size={20}
                          onClick={() => {
                            sizeRef.current!.value = elem.size.toString();
                            countRef.current!.value = elem.count.toString();
                            sizeIndexRef.current = index;
                            setIsOpen(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  <div className='add-size-map-button edit-option' onClick={() => setIsOpen(true)}>
                    + DODAJ NOVU VELIČINU
                  </div>
                </div>
              </div>

              <div className='w-img'>
                {cloudinaryDisabled && (
                  <div className='disable-image-upload'>
                    <h1 style={{ color: '#fa0a32' }}>UPOZORENJE!</h1>
                    <strong>
                      {Constants.CloudinaryDanger.replace(
                        '{{PERCENT}}',
                        cloudinaryStatus.usedPercentage.toString(),
                      )}
                      <span className='danger-txt' onClick={() => setCloudinaryDisabled(false)}>
                        ovde
                      </span>
                    </strong>
                  </div>
                )}
                <div className='product-images-section'>
                  <h3>Slike</h3>
                  {cloudinaryStatus.status === CloudinaryStatus.Warning && (
                    <Notification
                      isOk={true}
                      customTopMargin={0}
                      customBottomMargin={10}
                      title={Constants.CloudinaryWarning.replace(
                        '{{PERCENT}}',
                        cloudinaryStatus.usedPercentage.toString(),
                      )}
                      customColor='#ff9c1c'
                      customBorderColor='#db881b'
                    />
                  )}
                  <div className='add-product-images'>
                    <LuImagePlus className='img-ic' />
                    <input
                      type='file'
                      name=''
                      id=''
                      multiple
                      accept={Environment.allowedMimeTypes}
                      onChange={(e: FileChangeEvent) => addImages(e)}
                    />
                  </div>
                  <div className='product-images-list'>
                    {productImages.map((imageUrl, index) => (
                      <div className='product-image-block-admin' key={index}>
                        <div className='remove-img-wrp'>
                          <img
                            src={imageUrl}
                            alt=''
                            onClick={() => {
                              setMainImage(imageUrl);
                              setIsOpenModal(true);
                            }}
                          />
                          <BsFillTrashFill
                            color='#ffff'
                            className='remove-image-btn'
                            onClick={() => {
                              let fileIndex = productImages.length - index - 1;
                              removeImageElement(imageUrl, isBlobURL(imageUrl), fileIndex, index);
                            }}
                          />
                        </div>
                        <input
                          type='radio'
                          name=''
                          checked={isImagePrimary(imageUrl, index)}
                          onChange={() => changeImagePriority(imageUrl, index)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <button onClick={addProduct}>SAČUVAJ</button>
              <span ref={resErrorRef} className='modal-error'></span>
            </section>
          </div>
        </>
      ) : (
        <h1 className='min-height-block'>Proizvod nije pronadjen.</h1>
      )}
    </>
  );
};

export default EditProduct;
