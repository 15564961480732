import { isNullOrWhiteSpace } from "./strings";

export class URLQueries {
  public static add(key: any, value: any, query: string): string {
    if (value !== null && value !== undefined) {
      const sign = isNullOrWhiteSpace(query) ? "?" : "&";
      query += `${sign}${key}=${value}`;
    }

    return query;
  }

  public static addList(entity: any, query: string, excludeProperties?: string[]): string {
    if (entity === null || entity === undefined) {
      return query;
    }

    const entries = Object.entries(entity);

    for (const [key, value] of entries) {
      const isString = (typeof value).toLocaleLowerCase() === "string";
      const validateString = isString && !isNullOrWhiteSpace(value?.toString());
      const shouldAdd = value !== null && value !== undefined && !excludeProperties?.includes(key);

      if (shouldAdd && isString && validateString) {
        const sign = isNullOrWhiteSpace(query) ? "?" : "&";
        query += `${sign}${key}=${value}`;
      }

      if (!isString && shouldAdd) {
        const sign = isNullOrWhiteSpace(query) ? "?" : "&";
        query += `${sign}${key}=${value}`;
      }
    }

    return query;
  }

}