import React, { useRef, useState, useLayoutEffect } from 'react';
import { SliderList } from '../../Html/SliderList';
import { IBaseGender, IBaseItem, IProductBase, ISizeBase } from '../../../models/product-base';
import { AiOutlineClose } from 'react-icons/ai';
import Environment from '../../../common/environment';
import ProductService from '../../../services/product.service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import * as DataMapping from '../../../common/data';
import { StoreSearchCriteriaLoaderFilter } from '../../../models/filters';
import { RangeSlider } from '../../Html/RangeSlider';
import { AxiosBase } from '../../../common/axios';

interface IComponentProps {
  searchCriteria: any;
  setProducts: React.Dispatch<React.SetStateAction<IProductBase[]>>;
  setDeleteCurrentPage: React.Dispatch<React.SetStateAction<boolean>>;
  setPriceRangeValue: React.Dispatch<React.SetStateAction<[number, number] | null>>;
  dispatch: any;
  priceRangeValue: [number, number] | null;
}

const ProductsSearchForm: React.FC<IComponentProps> = ({
  searchCriteria,
  setProducts,
  dispatch,
  setDeleteCurrentPage,
  setPriceRangeValue,
  priceRangeValue,
}) => {
  const [categories, setCategories] = useState<IBaseItem[]>();
  const [brands, setBrands] = useState<IBaseItem[]>();
  const [seasons, setSeasons] = useState<IBaseItem[]>();
  const [genders, setGenders] = useState<IBaseGender[]>();
  const [sizes, setSizes] = useState<ISizeBase[]>();
  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 0]);

  const [searchParams] = useSearchParams();

  const searchType = searchParams.get('searchType');

  const codeRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const isSelected = (value: any, field: string) => {
    const data = { ...searchCriteria } as any;
    let list = data[field] ?? [];

    return list?.includes(value) ? 'underline' : 'none';
  };

  useLayoutEffect(() => {
    const loadData = async () => {
      let criteria = new StoreSearchCriteriaLoaderFilter(
        searchCriteria,
        priceRangeValue,
        searchType,
      );

      const [categoriesData, sizesData, gendersData, brandsData, seasonsData] = await Promise.all([
        ProductService.loadCategories(criteria, ['categories']),
        ProductService.loadSizes(criteria, ['sizes']),
        ProductService.loadGenders(criteria, ['genders']),
        ProductService.loadBrands(criteria, ['brands']),
        ProductService.loadSeasons(criteria, ['seasons']),
      ]);

      if (categoriesData.ok) {
        setCategories(categoriesData.data);
      }

      if (sizesData.ok) {
        setSizes(sizesData.data);
      }

      if (gendersData.ok) {
        setGenders(gendersData.data);
      }

      if (brandsData.ok) {
        setBrands(brandsData.data);
      }

      if (seasonsData.ok) {
        setSeasons(seasonsData.data);
      }
    };

    loadData();
  }, [searchCriteria, priceRangeValue, searchType]);

  useLayoutEffect(() => {
    const loadData = async () => {
      const sp = searchCriteria;
      const criteria = new StoreSearchCriteriaLoaderFilter(sp);

      const priceRangeData = await ProductService.loadPriceRange(criteria, [
        'minPrice',
        'maxPrice',
      ]);

      if (priceRangeData.ok) {
        setMaxPrice(priceRangeData.data.max);
        setMinPrice(priceRangeData.data.min);
        setPriceRange([Number(priceRangeData.data.min), Number(priceRangeData.data.max)]);
      }
    };

    loadData();
  }, [searchCriteria]);

  const isChecked = (value: any, field: string) => {
    const data = { ...searchCriteria } as any;
    let list = data[field] ?? [];

    return list?.includes(value);
  };

  const searchByCode = async () => {
    const code = codeRef.current?.value;

    let url = `${Environment.api}/products/filter?sortBy=code&sortDirection=asc&resultsPerPage=24&pageNumber=0&code=${code}`;
    const { data } = await AxiosBase.get(url);
    setProducts(data.products);
  };

  const selectItem = (value: any, field: string) => {
    const data = { ...searchCriteria } as any;
    let list = data[field] ?? [];

    if (!list?.includes(value)) {
      list?.push(value);
    } else {
      list = list.filter((x: any) => x !== value);
    }

    dispatch({
      type: field,
      payload: list,
    });
  };

  return (
    <div className='store-search'>
      <div
        className='reset-filters'
        onClick={() => {
          if (sessionStorage.getItem('searchCriteria_store') !== 'all') {
            navigate('/store');
            setDeleteCurrentPage(true);
            dispatch({ type: 'reset' });
            setPriceRangeValue(null);
          }
        }}
      >
        <b>
          <p>
            RESETUJ FILTERE
            <AiOutlineClose style={{ marginLeft: 4, fontSize: 21, marginBottom: 0 }} />
          </p>
        </b>
      </div>

      <SliderList title='Kategorije'>
        <div className='store-search-mapping scroll-list-slider'>
          {categories?.map((category, index) => (
            <div className='store-search-list-item' key={index}>
              <p
                className='select-p'
                style={{
                  textDecoration: isSelected(category.name, 'categories'),
                }}
                onClick={() => {
                  setDeleteCurrentPage(true);
                  selectItem(category.name, 'categories');
                }}
              >
                {category.name}
              </p>
              <p>{category.count}</p>
            </div>
          ))}
        </div>
      </SliderList>

      <SliderList title='Brend'>
        <div className='store-search-mapping scroll-list-slider'>
          {brands?.map((brand, index) => (
            <div className='store-search-list-item' key={index}>
              <p
                className='select-p'
                style={{
                  textDecoration: isSelected(brand.name, 'brands'),
                }}
                onClick={() => {
                  setDeleteCurrentPage(true);
                  selectItem(brand.name, 'brands');
                }}
              >
                {brand.name}
              </p>
              <p>{brand.count}</p>
            </div>
          ))}
        </div>
      </SliderList>

      <SliderList title='Pol'>
        <div className='store-search-mapping'>
          {genders &&
            genders.map((gender, index) => (
              <div className='store-search-list-item' key={index}>
                <div className='flex-left'>
                  <input
                    type='checkbox'
                    className='sotre-checbox'
                    checked={isChecked(gender.gender, 'genders')}
                    onChange={() => {
                      setDeleteCurrentPage(true);
                      selectItem(gender.gender, 'genders');
                    }}
                  />
                  <p>{DataMapping.genders.find((x) => x.id === gender.gender)?.name ?? ''}</p>
                </div>
                <p>{gender.count}</p>
              </div>
            ))}
        </div>
      </SliderList>

      <SliderList title='Veličine'>
        <div className='store-search-mapping scroll-list-slider'>
          {sizes?.map((size, index) => (
            <div className='store-search-list-item' key={index}>
              <div className='flex-left'>
                <input
                  type='checkbox'
                  className='sotre-checbox'
                  checked={isChecked(size.size, 'sizes')}
                  onChange={() => {
                    selectItem(size.size, 'sizes');
                    setDeleteCurrentPage(true);
                  }}
                />
                <p>{size.size}</p>
              </div>
              <p>{size.count}</p>
            </div>
          ))}
        </div>
      </SliderList>

      <SliderList title='Sezona'>
        <div className='store-search-mapping scroll-list-slider'>
          {seasons?.map((season, index) => (
            <div className='store-search-list-item' key={index}>
              <p
                className='select-p'
                style={{
                  textDecoration: isSelected(season.name, 'seasons'),
                }}
                onClick={() => {
                  setDeleteCurrentPage(true);
                  selectItem(season.name, 'seasons');
                }}
              >
                {season.name}
              </p>
              <p>{season.count}</p>
            </div>
          ))}
        </div>
      </SliderList>

      <div className='store-search-block'>
        <h3 style={{ marginTop: 8 }}>Cena</h3>
        <div className='store-search-mapping'>
          <div className='price-range-mapping'>
            <p style={{ opacity: '0.8' }}>{minPrice} RSD</p>
            <p style={{ opacity: '0.8' }}>{maxPrice} RSD</p>
          </div>
          <RangeSlider
            max={maxPrice}
            min={minPrice}
            setPriceRange={setPriceRange}
            priceRange={priceRange}
            setPriceRangeValue={setPriceRangeValue}
            setDeleteCurrentPage={setDeleteCurrentPage}
          />
          <div className='price-range-mapping' style={{ justifyContent: 'center' }}>
            <p style={{ fontWeight: 'bolder' }}>
              {priceRange[0]} RSD - {priceRange[1]} RSD
            </p>
          </div>
        </div>
      </div>

      <div className='store-search-block'>
        <h3 style={{ marginTop: 8 }}>Šifra proizvoda</h3>
        <div className='store-search-mapping code-search'>
          <input type='text' placeholder='Unesite šifru' ref={codeRef} className='search-code' />
          <button onClick={searchByCode} className='search-code-btn'>
            <BsSearch />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductsSearchForm;
