import '../../styles/Checkout.css';
import { BsCircleFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { ISavedProduct } from '../../models/product-base';
import { useState, useRef, useLayoutEffect } from 'react';
import { formatPrice } from '../../helpers/uiHelper';
import { isNullOrEmpty } from '../../utils/strings';
import Constants from '../../common/constants';
import useUser from '../../hooks/useUser';
import IOrder from '../../models/order';
import OrderService from '../../services/order.service';
import { useDispatch } from 'react-redux';
import { removeAllFromCart } from '../../redux/reducers/shoppingCartReducer';
import ShoppingCart from './ShoppingCart';
import CouponService from '../../services/coupon.service';
import Notification from '../../components/Html/Notification';
import OrderCompleted from '../../components/Orders/OrderCompleted';
import { useScrollOnLoad } from '../../hooks/useScrollOnLoad';
import MetaTags from '../../components/Html/MetaTags';
import { SeoConfig } from '../../helpers/seo-config';
import Input from '../../components/Html/Input';
import FormGroupInline from '../../components/Html/FormGroupInline';
import { Link } from 'react-router-dom';

const Chekout = () => {
  const products: ISavedProduct[] = useSelector((x: RootState) => x.shoppingCart.products);

  useScrollOnLoad();

  const [total, setTotal] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [couponFailed, setCouponFailed] = useState<boolean>(false);
  const [couponAmount, setCouponAmount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [acceptedPrivacyAndPolicy, setAcceptedPrivacyAndPolicy] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<Partial<{ couponId: null | number; used: boolean }>>();

  const dispatch = useDispatch();
  const user = useUser();

  const firstnameRef = useRef(document.createElement('input'));
  const lastnameRef = useRef(document.createElement('input'));
  const phoneRef = useRef(document.createElement('input'));
  const emailRef = useRef(document.createElement('input'));
  const countyRef = useRef(document.createElement('input'));
  const cityRef = useRef(document.createElement('input'));
  const addressRef = useRef(document.createElement('input'));
  const zipRef = useRef(document.createElement('input'));
  const noteRef = useRef(document.createElement('textarea'));
  const couponRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    const sumUp = () => {
      let amount = 0;

      products.forEach((x) => {
        amount += x.price * x.size.count - x.price * x.size.count * ((x.discount ?? 0) / 100);
      });

      setTotal(Number(amount.toFixed(0)));
    };

    sumUp();
  }, [products]);

  useLayoutEffect(() => {
    if (user) {
      firstnameRef.current.value = user?.firstname as string;
      lastnameRef.current.value = user?.lastname as string;
      emailRef.current.value = user?.email as string;
      phoneRef.current.value = user?.phone as string;
      countyRef.current.value = user?.county as string;
      cityRef.current.value = user?.city as string;
      addressRef.current.value = user?.address as string;
      zipRef.current.value = user?.zip.toString() as string;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const order = async () => {
    if (!acceptedPrivacyAndPolicy) {
      return;
    }

    const firstname = firstnameRef.current.value;
    const lastname = lastnameRef.current.value;
    const email = emailRef.current.value;
    const phone = phoneRef.current.value;
    const county = countyRef.current.value;
    const city = cityRef.current.value;
    const address = addressRef.current.value;
    const zip = zipRef.current.value;
    const note = noteRef.current.value;

    if (isNullOrEmpty(firstname)) {
      throwError(Constants.firstnameCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(lastname)) {
      throwError(Constants.lastnameCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(email)) {
      throwError(Constants.emailCannotBeEmpty);
      return;
    }

    if (!email.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
      throwError(Constants.emailIsNotValid);
      return;
    }

    if (isNullOrEmpty(phone)) {
      throwError(Constants.phoneCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(county)) {
      throwError(Constants.countyCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(city)) {
      throwError(Constants.cityCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(address)) {
      throwError(Constants.addressCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(zip)) {
      throwError(Constants.zipCannotBeEmpty);
      return;
    }

    if (isNaN(parseInt(zip))) {
      throwError(Constants.zipMustBeNumber);
      return;
    }

    // if (!acceptedPrivacyAndPolicy) {
    //   throwError(Constants.checkboxCannotBeEmptyCheckout);
    //   return;
    // }

    const orderItems = [];

    for (let i = 0; i < products.length; i++) {
      orderItems[i] = {
        productId: products[i].id,
        count: products[i].size.count,
        sizeId: products[i].size.id,
      };
    }

    const order: IOrder = {
      order: {
        firstname,
        lastname,
        email,
        phone,
        county,
        city,
        address,
        zip: parseInt(zip),
        note,
        couponId: coupon?.couponId,
        userId: user ? user.id : null,
      },
      orderItems,
    };

    const { data, ok } = await OrderService.order(order);

    if (ok) {
      setData(data);
      dispatch(removeAllFromCart());
      setIsOpen(true);
    } else {
      throwError(data?.message);
    }
  };

  const checkCouponCode = async () => {
    const coupon = couponRef.current?.value as string;
    const { data, ok } = await CouponService.applyCoupon(coupon, total);

    if (ok) {
      setCoupon({ couponId: data.couponId, used: true });
      setTotal(data.reducedAmount);
      setCouponAmount(data.couponDiscountValue);
    }

    if (ok === false) {
      setCouponFailed(true);

      setTimeout(() => {
        setCouponFailed(false);
      }, 3000);
    }
  };

  const throwError = (message: string) => {
    setErrorMessage(message);
    setShowError(true);

    setTimeout(() => {
      setErrorMessage('');
      setShowError(false);
    }, 3000);
  };

  return (
    <>
      <MetaTags data={SeoConfig.Checkout} />
      <div className='checkout'>
        <OrderCompleted data={data} isOpen={isOpen} setIsOpen={setIsOpen} />
        <ShoppingCart />
        {products.length > 0 && (
          <div className='input-data-wrapper'>
            <div className='checkout-form'>
              <div className='user-data order-form'>
                <div className='headline'>
                  <h2>Detalji za naplatu</h2>
                </div>
                <FormGroupInline>
                  <Input label='Ime' id='firstname' elRef={firstnameRef} isRequired />
                  <Input label='Prezime' id='lastname' elRef={lastnameRef} isRequired />
                </FormGroupInline>

                <FormGroupInline>
                  <Input label='Broj telefona' id='phone' elRef={phoneRef} isRequired />
                  <Input label='Email' id='email' elRef={emailRef} isRequired />
                </FormGroupInline>

                <FormGroupInline>
                  <Input label='Okrug' id='county' elRef={countyRef} isRequired />
                  <Input label='Grad' id='city' elRef={cityRef} isRequired />
                </FormGroupInline>

                <FormGroupInline>
                  <Input label='Adresa' id='address' elRef={addressRef} isRequired />
                  <Input label='Poštanski broj' id='postal-code' elRef={zipRef} isRequired />
                </FormGroupInline>

                <div className='note'>
                  <label>
                    Napomene o narudžbini <span style={{ opacity: 0.7 }}>(opciono)</span>
                  </label>
                  <textarea
                    ref={noteRef}
                    name=''
                    placeholder='Napomena o narudžbini npr. poseban način isporuke'
                    id='note'
                    rows={5}
                  ></textarea>
                </div>
              </div>
              <div className='privacy-policy-check sign-in-options'>
                <div className='remember-me' style={{ marginBottom: -2 }}>
                  <input
                    type='checkbox'
                    id='remeberme'
                    onChange={(e) => setAcceptedPrivacyAndPolicy(e.target.checked)}
                  />
                  <p>
                    Slažem se sa&nbsp;
                    <Link to='/privacy-and-policy'>Politikom privatnošću.</Link>
                  </p>
                </div>
              </div>

              {showError === false && (
                <button
                  className={`checkout-btn ${acceptedPrivacyAndPolicy ? '' : 'freez-btn'}`}
                  onClick={order}
                >
                  Naručite
                </button>
              )}

              {showError === true && <Notification isOk={false} title={errorMessage} />}
            </div>

            <div className='form-sides'>
              <div className='promo-form'>
                <div className='headline'>
                  <h2>POSEDUJETE PROMO KUPON?</h2>
                </div>
                <div className='input-element'>
                  <label style={{ fontSize: 17 }}>Promo kod</label>
                  <input
                    ref={couponRef}
                    type='text'
                    name=''
                    id='promoCode'
                    placeholder='Unesite promo kod'
                    disabled={coupon?.used ? true : false}
                  />
                  <p>Iskoristi promo kod i ostvari čak do 50% popusta</p>
                  {coupon?.used && (
                    <Notification isOk={true} title='Uspešno ste iskoristili kupon.' />
                  )}

                  {!coupon?.used && couponFailed === false && (
                    <button className='checkout-btn' onClick={checkCouponCode}>
                      POTVRDI PROMO KOD
                    </button>
                  )}

                  <div className='error-width-checkout'>
                    {couponFailed === true && (
                      <Notification isOk={false} title='Kupon sa unetim kodom ne postoji.' />
                    )}
                  </div>
                </div>
              </div>

              <div className='promo-form'>
                <div className='headline'>
                  <h2>Kupovina</h2>
                </div>
                <div className='payment-details'>
                  <div className='payment-detail'>
                    <p>Ukupno:</p>
                    <b>
                      <p>{formatPrice(total + couponAmount)}</p>
                    </b>
                  </div>
                  <div className='payment-detail'>
                    <p>Popust:</p>
                    <b>
                      <p>{formatPrice(couponAmount)}</p>
                    </b>
                  </div>
                  <div className='payment-detail'>
                    <p>Ukupan iznos za uplatu:</p>
                    <b>
                      <p>{formatPrice(total)}</p>
                    </b>
                  </div>
                  <div className='privacy-policy'>
                    <div className='payment'>
                      <BsCircleFill size={10} style={{ marginRight: 8 }} />
                      <p>
                        Besplatna dostava za iznos preko{' '}
                        <span style={{ color: '#fa0a32' }}>6000</span> RSD.
                      </p>
                    </div>
                    <div className='payment'>
                      <BsCircleFill size={10} style={{ marginRight: 8 }} />
                      <p>Plaćanje gotovinom prilikom dostave.</p>
                    </div>
                    <div className='payment'>
                      <BsCircleFill size={10} style={{ marginRight: 8 }} />
                      <p>Kurir će vam doneti porudžbinu na naznačenu adresu.</p>
                    </div>
                    <div className='payment'>
                      <BsCircleFill size={10} style={{ marginRight: 8 }} />
                      <p>U cenu je uračunat PDV.</p>
                    </div>
                    <div className='payment'>
                      <BsCircleFill size={10} style={{ marginRight: 8 }} />
                      <p>Isporuka se vrši SAMO na teritoriji Republike Srbije.</p>
                    </div>
                    <div className='payment'>
                      <BsCircleFill size={10} style={{ marginRight: 8 }} />
                      <p>Otkazivanje isporuke NIJE moguće.</p>
                    </div>
                    <p style={{ marginTop: 17 }}>
                      Vaši lični podaci biće upotrebljeni za obradu vaše narudžbine.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Chekout;
