import Logo from '../../assets/logo-white.png';
import AdminLinksMapping from './AdminLinksMapping';

const AdminSidebar = () => {
  return (
    <div className='sidebar' id='sidebar'>
      <div className='admin-logo' id='logo-div'>
        <img src={Logo} alt='Logo' className='admin-logo-img' id='sitemap-logo-img' />
      </div>
      <AdminLinksMapping />
    </div>
  );
};

export default AdminSidebar;
 