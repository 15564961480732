import '../../styles/Checkout.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { ISavedProduct } from '../../models/product-base';
import CheckoutProduct from '../../components/Product/Other/CheckoutProduct';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLayoutEffect, useState } from 'react';
import { formatPrice } from '../../helpers/uiHelper';
import { FaArrowRight } from 'react-icons/fa';
import EmptyShoppingCart from '../../components/Product/Other/EmptyShoppingCart';
import useResizeEvent from '../../hooks/useResizeEvent';
import CartItemMobile from '../../components/Product/Other/CartItemMobile';
import { useScrollOnLoad } from '../../hooks/useScrollOnLoad';
import MetaTags from '../../components/Html/MetaTags';
import { SeoConfig } from '../../helpers/seo-config';

const ShoppingCart = () => {
  const [total, setTotal] = useState<number>(0);
  const products: ISavedProduct[] = useSelector((x: RootState) => x.shoppingCart.products);

  useScrollOnLoad();

  const location = useLocation();
  const navigate = useNavigate();
  const renderMobile = useResizeEvent(950);

  useLayoutEffect(() => {
    const sumUp = () => {
      let amount = 0;

      products.forEach((x) => {
        amount += x.price * x.size.count - x.price * x.size.count * ((x.discount ?? 0) / 100);
      });

      setTotal(Number(amount.toFixed(0)));
    };

    sumUp();
  }, [products]);

  return (
    <>
      <MetaTags data={SeoConfig.ShoppingCart} />
      <div className='cart-mapping'>
        {products.length === 0 && <EmptyShoppingCart title='VAŠA KORPA JE PRAZNA' />}
        {products.length > 0 && (
          <div className='article-list'>
            <h1>VAŠA KORPA</h1>
          </div>
        )}
        {!renderMobile
          ? products.map((x) => <CartItemMobile product={x} key={x.productCartId} />)
          : products.map((x) => <CheckoutProduct product={x} key={x.productCartId} />)}
        {location.pathname === '/shopping-cart' && products.length > 0 && (
          <div className='cart-total-amount'>
            <div>
              <h1>UKUPNO:</h1>
              <p style={{ lineHeight: 1 }}>PDV je uključen u cenu</p>
            </div>
            <h1>{formatPrice(total)}</h1>
          </div>
        )}
        {location.pathname === '/shopping-cart' && products.length > 0 && (
          <div className='cart-list-data'>
            <button onClick={() => navigate('/checkout')} className='go-to-checkout'>
              NASTAVI NA PLAĆANJE
              <FaArrowRight className='continue-checkout' />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ShoppingCart;
