import React, { createContext } from 'react';
import BurgerLink from './BurgerLink';
import {
  AiOutlineSetting,
  AiOutlineShopping,
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineBarChart,
} from 'react-icons/ai';
import { MdOutlineDiscount } from 'react-icons/md';
import { CgMail } from 'react-icons/cg';
import { BiImages } from 'react-icons/bi';
import { IAdminMobileNavigationContext } from '../../models/context';
import useUser from '../../hooks/useUser';
import { UserRole } from '../../common/enums';

interface IComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AdminNavigationContext = createContext<IAdminMobileNavigationContext>({} as any);

const AdminUI: React.FC<IComponentProps> = ({ setOpen }) => {
  const user = useUser();

  const isAdmin = user?.roleId === UserRole.Admin;

  return (
    <div className='admin-nav-mobile'>
      <AdminNavigationContext.Provider value={{ setOpen }}>
        {isAdmin && (
          <>
            <BurgerLink to='/admin/dashboard' title='Kontrolna tabla' Icon={AiOutlineBarChart} />
            <BurgerLink to='/admin/users' title='Korisnici' Icon={AiOutlineUser} />
          </>
        )}

        <BurgerLink to='/admin/orders' title='Porudžbine' Icon={AiOutlineShoppingCart} />
        <BurgerLink to='/admin/products' title='Proizvodi' Icon={AiOutlineShopping} />

        {isAdmin && (
          <>
            <BurgerLink to='/admin/coupons' title='Kuponi' Icon={MdOutlineDiscount} />
            <BurgerLink to='/admin/mail-servers' title='Mail serveri' Icon={CgMail} />
            <BurgerLink to='/admin/web-images' title='Web slike' Icon={BiImages} />
            <BurgerLink to='/admin/settings' title='Podešavanja' Icon={AiOutlineSetting} />
          </>
        )}
      </AdminNavigationContext.Provider>
    </div>
  );
};

export default AdminUI;
