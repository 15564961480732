import React, { useState } from 'react';
import Modal from '../Html/Modal';
import Lottie from 'lottie-react';
import SuccessAnimation from '../../assets/success.json';
import Constants from '../../common/constants';
import { useNavigate } from 'react-router-dom';
import AccountService from '../../services/account.service';
import Notification from '../Html/Notification';

interface IComponentProps {
  isOpen: boolean;
  email: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccountCreated: React.FC<IComponentProps> = ({ isOpen, setIsOpen, email }) => {
  const navigate = useNavigate();

  const [isOk, setIsOk] = useState<boolean>(false);
  const [isSentAgain, setIsSentAgain] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const resendAccountVerification = async () => {
    setIsSentAgain(false);
    setIsSentAgain(true);

    const data = await AccountService.resendVerificationEmail(email);

    setIsOk(data.ok);
    setMessage(data?.data?.message ?? '');

    setTimeout(() => {
      setIsSentAgain(false);
    }, 6000);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} title='USPEŠNO STE KREIRALI NALOG'>
      <div className='order-confirmation-popup acc-created'>
        <div className='completed-animation'>
          <Lottie
            animationData={SuccessAnimation}
            loop={true}
            style={{
              width: '50%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </div>
        <p>{Constants.accountCreated}</p>
        <br />
        <p>{Constants.accountVerificationNotRecived}</p>
        {isSentAgain && <Notification isOk={isOk} title={message} />}
        <div className='account-created'>
          <button className='created-btn' onClick={resendAccountVerification}>
            POŠALJI PONOVO
          </button>
          <button
            onClick={() => {
              setIsOpen(false);
              document.body.style.overflow = 'auto';
              navigate('/');
            }}
          >
            POČETNA
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AccountCreated;
