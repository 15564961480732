import "../../styles/Account.css";
import ProfilePicture from "../../assets/profile-picture.webp";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { BsHouseDoor } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { TbMapSearch, TbZip } from "react-icons/tb";
import { LuEdit2 } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { user } from "../../redux/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import Modal from "../../components/Html/Modal";
import React, { useState } from "react";
import EditContact from "../../components/Account/EditContact";
import ChangePassword from "../../components/Account/ChangePassword";
import { UserRole } from "../../common/enums";
import { useScrollOnLoad } from "../../hooks/useScrollOnLoad";
import MetaTags from "../../components/Html/MetaTags";
import { SeoConfig } from "../../helpers/seo-config";
import { useContentWidth } from "../../hooks/useContentWidth";
import { setAuthorizationHeader } from "../../common/axios";

const Account = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useScrollOnLoad();
  useContentWidth();

  const userModel = useUser();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [editChildren, setEditChildren] = useState<React.ReactElement>(<></>);

  const signOut = () => {
    dispatch(user(null));
    setAuthorizationHeader();
    localStorage.removeItem("_token");
    navigate("/");
  };

  return (
    <>
      <MetaTags data={SeoConfig.Account} />
      <div
        className={`account ${
          userModel?.roleId === UserRole.Admin ? " admin-panel-width admin-top-margin-acc" : ""
        }`}
        id={`${userModel?.roleId === UserRole.Admin ? "admin-panel" : "xxx"}`}
      >
        <Modal title={modalTitle} isOpen={isOpen} setIsOpen={setIsOpen}>
          {editChildren}
        </Modal>
        <div className="form">
          <img src={ProfilePicture} alt="user profile" />
          <div className="primary">
            <h1>
              {userModel?.firstname} {userModel?.lastname}
            </h1>
            <h2>{userModel?.username}</h2>
          </div>

          <div className="contact-info">
            <div className="headline">
              <h3>Kontakt podaci</h3>
            </div>

            <div className="contact-detail">
              <div className="icon">
                <AiOutlineMail />
              </div>
              <div className="info-mapping">
                <small>Mejl</small>
                <p>{userModel?.email}</p>
              </div>
            </div>
            <div className="contact-detail">
              <div className="icon">
                <AiOutlinePhone />
              </div>
              <div className="info-mapping">
                <small>Broj telefona</small>
                <p>{userModel?.phone}</p>
              </div>
            </div>
          </div>

          {userModel?.roleId === UserRole.Buyer && (
            <div className="contact-info">
              <div className="headline">
                <h3>Adresa dostave</h3>
              </div>
              <div className="contact-detail">
                <div className="icon">
                  <TbMapSearch />
                </div>
                <div className="info-mapping">
                  <small>Okrug</small>
                  <p>{userModel?.county}</p>
                </div>
              </div>

              <div className="contact-detail">
                <div className="icon">
                  <IoLocationOutline />
                </div>
                <div className="info-mapping">
                  <small>Grad</small>
                  <p>{userModel?.city}</p>
                </div>
              </div>

              <div className="contact-detail">
                <div className="icon">
                  <BsHouseDoor />
                </div>
                <div className="info-mapping">
                  <small>Adresa</small>
                  <p>{userModel?.address}</p>
                </div>
              </div>

              <div className="contact-detail">
                <div className="icon">
                  <TbZip />
                </div>
                <div className="info-mapping">
                  <small>Poštanski broj</small>
                  <p>{userModel?.zip}</p>
                </div>
              </div>
            </div>
          )}

          <div className="options">
            <button
              className="change-password"
              onClick={() => {
                setIsOpen(true);
                setModalTitle("Promeni lozinku");
                setEditChildren(<ChangePassword />);
              }}
            >
              Promeni lozinku
            </button>
            <button className="sign-out" onClick={signOut}>
              Odjavi se
            </button>
          </div>
          <button
            className="account-edit"
            onClick={() => {
              setIsOpen(true);
              setModalTitle("Izmeni kontakt podatke");
              setEditChildren(<EditContact />);
            }}
          >
            <LuEdit2 style={{ marginRight: 5 }} />
            Izmeni
          </button>
        </div>
      </div>
    </>
  );
};

export default Account;
