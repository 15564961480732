import React, { useState, useEffect } from 'react';
import { BiCartDownload } from 'react-icons/bi';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import { UserRole } from '../../../common/enums';

interface IComponentProps {
  title: string;
}

const EmptyShoppingCart: React.FC<IComponentProps> = ({ title }) => {
  const navigate = useNavigate();
  const user = useUser();

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const timeout = !user || (user && user.roleId === UserRole.Buyer) ? 0 : 100;
    setTimeout(() => {
      setShow(true);
    }, timeout);
  }, [user]);

  return show ? (
    <div className='empty-shoppinh-cart'>
      <BiCartDownload className='empty-icon' />
      <h1>{title}</h1>
      {(!user || (user !== null && user.roleId === UserRole.Buyer)) && (
        <p>
          Još uvek nemate ništa u korpi. Pogledajte veliki asortiman naših proizvoda i počnite sa
          kupovinom.
        </p>
      )}
      {user !== null && user.roleId === UserRole.Admin && (
        <p>
          Trenutno nemate ni jednu porudžbinu. Kada neko bude bio naručio neki proizvod porudžbina
          će vam se prikazati ovde.
        </p>
      )}
      {(!user || (user !== null && user.roleId === UserRole.Buyer)) && (
        <button onClick={() => navigate('/store')} className='go-to-checkout'>
          POČNITE SA KUPOVINOM
          <FaArrowRight className='cart-arrow-start' />
        </button>
      )}
    </div>
  ) : (
    <></>
  );
};

export default EmptyShoppingCart;
