import { useRef, useState, useEffect } from 'react';
import '../../styles/SignIn.css';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AuthService from '../../services/auth.service';
import { useDispatch } from 'react-redux';
import { user } from '../../redux/reducers/userReducer';
import { isNullOrEmpty } from '../../utils/strings';
import Constants from '../../common/constants';
import { modalError } from '../../helpers/uiHelper';
import { useScrollOnLoad } from '../../hooks/useScrollOnLoad';
import MetaTags from '../../components/Html/MetaTags';
import { SeoConfig } from '../../helpers/seo-config';
import { UserRole } from '../../common/enums';
import WishlistService from '../../services/wishlist.service';
import { useUnauthorizedProtection } from '../../hooks/useUnauthorizedProtection';
import useUser from '../../hooks/useUser';
import Input from '../../components/Html/Input';

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);

  const userData = useUser();

  useEffect(() => {
    if (userData !== null) {
      setShouldRedirect(true);
    }
  }, [userData]);

  useScrollOnLoad();
  useUnauthorizedProtection(shouldRedirect);

  const usernameRef = useRef(document.createElement('input'));
  const passwordRef = useRef(document.createElement('input'));

  const usernameErrorRef = useRef(document.createElement('span'));
  const passwordErrorRef = useRef(document.createElement('span'));
  const resErrorRef = useRef(document.createElement('span'));

  const signIn = async () => {
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;

    if (isNullOrEmpty(username)) {
      modalError(usernameErrorRef, Constants.usernameCannotBeEmpty);
      return;
    }

    if (isNullOrEmpty(password)) {
      modalError(passwordErrorRef, Constants.passwordCannotBeEmpty);
      return;
    }

    let { data, ok } = await AuthService.signIn(username, password);

    if (ok === false) {
      modalError(resErrorRef, data.message);
      return;
    }

    const fallbackUrl = searchParams.get('fallbackUrl');
    const wishlist = searchParams.get('saveToWishlist');
    const productId = searchParams.get('productId');
    
    const saveToWishlist = wishlist !== null && wishlist === '1';
    
    if (saveToWishlist && productId !== null && data.user.roleId === UserRole.Buyer) {
      await WishlistService.save(Number(productId));
    }

    if (!isNullOrEmpty(fallbackUrl)) {
      navigate(fallbackUrl!.toString());
      dispatch(user(data.user));
      setShouldRedirect(false);
      return;
    }

    const isEmployee = data.user.roleId === UserRole.Employee;
    const isAdmin = data.user.roleId === UserRole.Admin;

    const navigateUrl = isAdmin ? '/admin/dashboard' : isEmployee ? '/admin/products' : '/';
    navigate(navigateUrl);

    dispatch(user(data.user));
    setShouldRedirect(true);
  };

  return (
    <>
      <MetaTags data={SeoConfig.SignIn} />
      <div className='sign-in-page'>
        <section className='form'>
          <h1>Prijava</h1>
          <Input
            label='Korisničko ime'
            id='username'
            elRef={usernameRef}
            customErrorRef={usernameErrorRef}
          />
          <Input
            label='Lozinka'
            id='password'
            type='password'
            elRef={passwordRef}
            customErrorRef={passwordErrorRef}
          />

          <div className='sign-in-options'>
            <div className='remember-me'>
              <input type='checkbox' id='remeberme' />
              <p>Zapamti me</p>
            </div>
            <Link to='/forgot-password'>Zaboravili ste lozinku?</Link>
          </div>
          <button onClick={signIn} className='remove-hover-black'>
            prijavi se
          </button>
          <span ref={resErrorRef} className='modal-error'></span>
          <p className='register'>
            Nemate nalog? <Link to='/register'>Registrujte se</Link>
          </p>
        </section>
      </div>
    </>
  );
};

export default SignIn;
