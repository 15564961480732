import { useRef, useState, useLayoutEffect } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import '../../styles/SignIn.css';
import AuthService from '../../services/auth.service';
import Notification from '../../components/Html/Notification';
import Constants from '../../common/constants';
import { useScrollOnLoad } from '../../hooks/useScrollOnLoad';
import MetaTags from '../../components/Html/MetaTags';
import { SeoConfig } from '../../helpers/seo-config';
import { useUnauthorizedProtection } from '../../hooks/useUnauthorizedProtection';
import { isNullOrEmpty } from '../../utils/strings';

const ResetPassword = () => {
  const { token } = useParams();
  const newPasswordRef = useRef(document.createElement('input'));
  const confirmPasswordRef = useRef(document.createElement('input'));

  const navigate: NavigateFunction = useNavigate();

  useUnauthorizedProtection(true);

  const [ok, setOk] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [data, setData] = useState<any>('');
  const [showButton, setShowButton] = useState<boolean>(true);

  useScrollOnLoad();

  useLayoutEffect(() => {
    (async () => {
      const isValid_ = await AuthService.validateToken(token);
      setIsValid(isValid_.ok);
      setShowButton(isValid_.ok);
    })();
  }, [token]);

  const resetPassword = async () => {
    const newPassword = newPasswordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    const response = await AuthService.resetPassword(token as string, newPassword, confirmPassword);

    setOk(response.ok);
    setData(response.data.message ?? '');
    setShowButton(false);

    setTimeout(() => {
      setData('');

      if (response.ok === false) {
        setShowButton(true);
      } else {
        navigate('/sign-in');
      }
    }, 2500);
  };

  return (
    <>
      <MetaTags data={SeoConfig.ResetPassword} />
      <div className='sign-in-page'>
        <section className='form'>
          <h1 className='forgot-password-title'>Resetovanje lozinke</h1>
          {!ok && isValid && (
            <>
              <div className='input-element'>
                <label>Nova lozina</label>
                <input
                  ref={newPasswordRef}
                  type='password'
                  name=''
                  id='password'
                  placeholder='Nova lozinka'
                  autoComplete='off'
                />
              </div>
              <div className='input-element'>
                <label>Potvrdi lozinku</label>
                <input
                  ref={confirmPasswordRef}
                  type='password'
                  name=''
                  id='confirm-password'
                  placeholder='Potvrdi lozinku'
                  autoComplete='off'
                />
              </div>
            </>
          )}

          {!isValid && <Notification isOk={false} title={Constants.resetPwdTokenInvalid} />}

          {!isNullOrEmpty(data) && <Notification isOk={ok} title={data} />}

          {ok === true && (
            <button onClick={() => navigate('/sign-in')} className='remove-hover-black'>
              PRIJAVI SE
            </button>
          )}

          {showButton && isValid && <button onClick={resetPassword}>PROMENI</button>}
        </section>
      </div>
    </>
  );
};

export default ResetPassword;
