import React, { useRef, useEffect } from 'react';
import Modal from '../Html/Modal';
import { isNullOrEmpty } from '../../utils/strings';
import { modalError, modalErrorInput } from '../../helpers/uiHelper';
import Constants from '../../common/constants';
import CouponService from '../../services/coupon.service';

interface IComponentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getAll: () => Promise<void>;
}

const AddCoupon: React.FC<IComponentProps> = ({ isOpen, setIsOpen, getAll }) => {
  let inputs = document.querySelectorAll<HTMLInputElement>('.add-coupon-input');

  const codeRef = useRef<HTMLInputElement>(null);
  const discountRef = useRef<HTMLInputElement>(null);
  const expireTimeRef = useRef<HTMLInputElement>(null);
  const resErrorRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    inputs = document.querySelectorAll<HTMLInputElement>('.add-coupon-input');
  }, []);

  const addCoupon = async () => {
    let error = false;

    inputs.forEach((input) => {
      let spanModelError = document.getElementById(`error-${input.id}`) as HTMLSpanElement;

      if (isNullOrEmpty(input.value)) {
        modalErrorInput(spanModelError, Constants.fieldCannotBeEmpty);
        error = true;
      }

      if (input.id === 'discount') {
        if (
          isNaN(parseInt(input.value)) ||
          parseInt(input.value) < 0 ||
          parseInt(input.value) > 100
        ) {
          modalErrorInput(spanModelError, Constants.fieldNotGoodFormat);
          error = true;
        }
      }

      if (input.id === 'expire-time') {
        if (new Date(input.value).getTime() < new Date().getTime()) {
          modalErrorInput(spanModelError, Constants.fieldNotGoodFormat);
          error = true;
        }
      }
    });

    if (error) return;

    const code = codeRef.current!.value;
    const discount = parseInt(discountRef.current!.value);
    const expireTime = new Date(expireTimeRef.current!.value);

    const { data, ok } = await CouponService.addCoupon(code, discount, expireTime);

    if (ok) {
      await getAll();
      setIsOpen(false);
    } else {
      modalError(resErrorRef, data.message);
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} title='DODAJ KUPON'>
      <div className='modal-data-list'>
        <div className='content-wrapper'>
          <div className='input-element'>
            <label>Kod</label>
            <input
              ref={codeRef}
              type='text'
              name=''
              className='input-text add-coupon-input'
              id='code-1'
              placeholder='Kod'
              autoComplete='off'
            />
            <span className='modal-error' id='error-code-1'></span>
          </div>
          <div className='input-element'>
            <label>Popust u %</label>
            <input
              ref={discountRef}
              type='text'
              name=''
              className='input-text add-coupon-input'
              id='discount'
              placeholder='Popust'
              autoComplete='off'
            />
            <span className='modal-error' id='error-discount'></span>
          </div>
          <div className='input-element'>
            <label>Datum isteka</label>
            <input
              ref={expireTimeRef}
              type='date'
              name=''
              className='input-text add-coupon-input'
              id='expire-time'
              placeholder='Datum isteka'
              autoComplete='off'
            />
            <span className='modal-error' id='error-expire-time'></span>
          </div>
        </div>
        <button onClick={addCoupon}>SAČUVAJ</button>
        <span ref={resErrorRef} className='modal-error'></span>
      </div>
    </Modal>
  );
};

export default AddCoupon;
