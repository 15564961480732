import React, { useContext } from 'react';
import { SliderList } from '../Html/SliderList';
import FormGroup from '../Html/FormGroup';
import Input from '../Html/Input';
import { IAdminBackofficeSearchContext } from '../../models/context';
import useResize from '../../hooks/useResize';
import { SettingsContext } from '../../pages/admin/settings/Settings';

const AdminBackofficeSearch = () => {
  const { dispatch } = useContext<Partial<IAdminBackofficeSearchContext>>(SettingsContext);
  const searchOpenDefaultState = useResize() > 1000;

  return (
    <div className='search-product-admin admin-search-mobile'>
      <SliderList title='Pretraga' defaultState={searchOpenDefaultState}>
        <div className='admin-inputes mobile-admin-inputs'>
          <FormGroup type='w-50'>
            <Input
              label='Naziv'
              onChange={(e) =>
                dispatch!({
                  type: 'name',
                  payload: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup type='w-50'>
            <Input
              label='Vrednost'
              onChange={(e) =>
                dispatch!({
                  type: 'value',
                  payload: e.target.value,
                })
              }
            />
          </FormGroup>
        </div>
      </SliderList>

      <div className='buttons-align'>
        <div className='buttons-wrp'>
          <button
            className='button-el-classic reset-btn-admin'
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => {
              dispatch!({ type: 'reset' });
            }}
          >
            RESETUJ
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminBackofficeSearch;
