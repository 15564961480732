import { useRef, useState, useEffect } from 'react';
import { IOrderBaseResponse } from '../../models/order-base';
import { BsCircleFill } from 'react-icons/bs';
import { OrderStatuses } from '../../common/data';
import { formatDate, formatPrice } from '../../helpers/uiHelper';
import { OrderStatus, UserRole } from '../../common/enums';
import OrderItem from './OrderItem';
import useUser from '../../hooks/useUser';
import ChangeStatusModal from './ChangeStatusModal';
import { isNullOrWhiteSpace } from '../../utils/strings';

interface IComponentProps {
  order: IOrderBaseResponse;
  index: number;
  getOrders: () => Promise<void>;
}

export interface Status {
  orderId: number;
  orderStatus: OrderStatus;
}

const Order: React.FC<IComponentProps> = ({ order, getOrders, index }) => {
  const user = useUser();

  const [showOrderItems, setShowOrderItems] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [orderStatus, setOrderStatus] = useState<Status>();
  const [status, setStatus] = useState<OrderStatus>();

  const orderStatusRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    setShowOrderItems(false);
  }, [order]);

  const openModal = (order: IOrderBaseResponse) => {
    setOrderStatus({ orderId: order.id, orderStatus: order.status });
    orderStatusRef.current!.value = order.status.toString();
    setStatus(order.status);
    setIsOpen(true);
  };

  const declinedUI =
    order.status === OrderStatus.Declined
      ? { color: '#fa0a32' }
      : order.status === OrderStatus.Finished
      ? { color: '#16c784' }
      : order.status === OrderStatus.Delivering
      ? { color: '#0064e4' }
      : {};

  return (
    <>
      <ChangeStatusModal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        getOrders={getOrders}
        setStatus={setStatus}
        status={status}
        orderStatus={orderStatus}
        orderStatusRef={orderStatusRef}
      />
      <div className='orders-map-data-wrapper'>
        <div className='primary-order-data' style={index === 0 ? { marginTop: -10 } : {}}>
          <div className='align-items'>
            <h1 className='order-status-info' style={declinedUI}>
              #{order.id}
            </h1>
            <BsCircleFill style={declinedUI} className='order-status-info-svg' />
            <h1 className='order-status-info' style={declinedUI}>
              {OrderStatuses[order.status]}
            </h1>
          </div>
          <div className='align-items order-content'>
            <button
              className='order-button toggle-order-button'
              onClick={() => {
                setShowOrderItems(!showOrderItems);
              }}
            >
              {showOrderItems ? 'SAKRIJ' : 'PRIKAŽI'} ARTIKLE
            </button>
            {user?.roleId === UserRole.Admin && (
              <button className='order-button edit-order-btn' onClick={() => openModal(order)}>
                PROMENI STATUS
              </button>
            )}
          </div>
        </div>
        <div className='order-data-admin'>
          <div className='contact-data-order-admin-wrapper'>
            <div className='contact-data-order-admin'>
              <h1>Kontakt podaci</h1>
              <h3>
                {order.firstname} {order.lastname}
                <span style={{ color: '#0064e4', marginLeft: 5 }}>
                  {user?.roleId === UserRole.Admin && order.isAuthorized ? '(korisnik sajta)' : ''}
                </span>
              </h3>
              <h3>{order.email}</h3>
              <h3>{order.phone}</h3>
            </div>
            <div className='contact-data-order-admin'>
              <h1>Adresa dostave</h1>
              <h3>{order.county}</h3>
              <h3>
                {order.zip}, {order.city}
              </h3>
              <h3>{order.address}</h3>
            </div>
          </div>
          <div className='contact-data-order-admin-wrapper'>
            <div className='contact-data-order-admin'>
              <h1>O porudžbini</h1>
              <h3>
                Kupon:
                {order.couponDiscount ? ' -' + order.couponDiscount + '%' : ' ----'}
              </h3>
              <h3>Ukupno: {formatPrice(order.totalPrice)} RSD</h3>
              <h3>Vreme: {formatDate(order.orderTime)}</h3>
            </div>
            <div className='contact-data-order-admin order-note-admin'>
              {!isNullOrWhiteSpace(order.note) && user?.roleId === UserRole.Buyer && (
                <>
                  <h1>Napomena</h1>
                  <h3 className='order-note-txt'>{order.note ?? 'Nema napomene'}</h3>
                </>
              )}
              {user?.roleId === UserRole.Admin && (
                <>
                  <h1>Napomena</h1>
                  <h3 className='order-note-txt'>{order.note ?? 'Nema napomene'}</h3>
                </>
              )}
            </div>
          </div>
        </div>
        {showOrderItems && (
          <>
            {order.orderItems.map((product, i) => (
              <OrderItem product={product} key={i} />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default Order;
