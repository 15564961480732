import { useState, useReducer, useLayoutEffect, useEffect } from 'react';
import { IProductBase } from '../../models/product-base';
import { sortProducts } from '../../common/data';
import ProductListItem from '../../components/Product/Other/ProductListItem';
import ProductService from '../../services/product.service';
import ProductsSearchForm from '../../components/Product/Other/ProductsSearchForm';
import { getQueryParamsForStore } from '../../helpers/uiHelper';
import '../../styles/Store.css';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import EmptyProducts from '../../components/Product/Other/EmptyProducts';
import { useScrollOnLoad } from '../../hooks/useScrollOnLoad';
import MetaTags from '../../components/Html/MetaTags';
import { ISEO, SeoConfig } from '../../helpers/seo-config';
import NoResultsFound from '../../components/General/NoResultsFound';
import { isNullOrWhiteSpace } from '../../utils/strings';
import { Gender } from '../../common/enums';
import { defaultStoreSearchCriteria, storeReducer } from '../../helpers/reducers';
import { FilterStoreProducts } from '../../models/filters';
import { MdFilterList } from 'react-icons/md';
import FilterStoreMobile from '../../layout/BurgerMenu/FilteStoreMobile';
import useBurgerMenu from '../../hooks/useBurgerMenu';

const Store = () => {
  const [searchCriteria, dispatch] = useReducer(storeReducer, defaultStoreSearchCriteria);

  const [seo, setSeo] = useState<ISEO>(SeoConfig.Store);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [products, setProducts] = useState<IProductBase[]>([]);
  const [maxNumberOfPages, setMaxNumberOfPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [deleteCurrentPage, setDeleteCurrentPage] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [priceRangeValue, setPriceRangeValue] = useState<[number, number] | null>(null);
  const [openSearch, setOpenSearch] = useState<boolean>(false);

  const searchRef = useBurgerMenu(openSearch);

  const location = useLocation();

  const [searchParams] = useSearchParams();

  useScrollOnLoad();

  useLayoutEffect(() => {
    const clear = () => {
      setDeleteCurrentPage(true);
      dispatch({ type: 'reset' });
      setPriceRangeValue(null);
    };

    clear();
    getQueryParamsForStore(dispatch, searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useLayoutEffect(() => {
    const loadData = async () => {
      const totalCountData = await ProductService.loadTotalCount();
      setTotalCount(totalCountData.data.count);
    };

    loadData();
  }, []);

  useLayoutEffect(() => {
    const loadProducts = async () => {
      const resultsPerPage = 24;

      const filterProducts = new FilterStoreProducts(
        currentPage,
        resultsPerPage,
        searchCriteria,
        priceRangeValue,
      );

      const data = await ProductService.filter(filterProducts);

      if (data.ok) {
        setMaxNumberOfPages(data.data.pageCount);
        setProducts(data.data.products);

        if (data.data.products.length === 0) {
          setTimeout(() => {
            setNotFound(true);
          }, 300);
        } else {
          setNotFound(false);
        }

        if (deleteCurrentPage) {
          setCurrentPage(0);
        }
      }
    };

    if (totalCount > 0) {
      loadProducts();
    }
  }, [searchCriteria, currentPage, totalCount, deleteCurrentPage, priceRangeValue]);

  useEffect(() => {
    getQueryParamsForStore(dispatch, searchParams);

    if (!isNullOrWhiteSpace(searchParams.get('searchType'))) {
      const data =
        searchParams.get('searchType') === 'new-collection'
          ? SeoConfig.NewCollection
          : SeoConfig.Discount;
      setSeo(data);
    }

    if (
      !isNullOrWhiteSpace(searchParams.get('genders')) &&
      !searchParams.get('genders')?.includes(',')
    ) {
      const data =
        Number(searchParams.get('genders')) === Gender.Male
          ? SeoConfig.Male
          : Number(searchParams.get('genders')) === Gender.Female
          ? SeoConfig.Female
          : SeoConfig.Kids;
      setSeo(data);
    }

    if (
      isNullOrWhiteSpace(searchParams.get('genders')) &&
      isNullOrWhiteSpace(searchParams.get('searchType'))
    ) {
      setSeo(SeoConfig.Store);
    }
  }, [searchParams]);

  return (
    <>
      <MetaTags data={seo} />
      {totalCount > 0 && (
        <FilterStoreMobile setOpen={setOpenSearch} searchMenuRef={searchRef}>
          <ProductsSearchForm
            searchCriteria={searchCriteria}
            setProducts={setProducts}
            dispatch={dispatch}
            priceRangeValue={priceRangeValue}
            setDeleteCurrentPage={setDeleteCurrentPage}
            setPriceRangeValue={setPriceRangeValue}
          />
        </FilterStoreMobile>
      )}
      <div className='store-wrapper'>
        {totalCount === 0 && (
          <EmptyProducts>
            <></>
          </EmptyProducts>
        )}
        {totalCount > 0 && (
          <ProductsSearchForm
            searchCriteria={searchCriteria}
            setProducts={setProducts}
            dispatch={dispatch}
            priceRangeValue={priceRangeValue}
            setDeleteCurrentPage={setDeleteCurrentPage}
            setPriceRangeValue={setPriceRangeValue}
          />
        )}

        {totalCount > 0 && (
          <button className='filters-mobile' onClick={() => {
            setOpenSearch(true);
            document.body.style.overflow = "hidden";
          }}>
            <MdFilterList className='filter-icon' />
            Filteri
          </button>
        )}

        {totalCount > 0 && (
          <div className='products-wrapper'>
            <div className='products-header'>
              <div className='pagination user-pagination'>
                <button
                  className='pagination-block'
                  onClick={() => {
                    setDeleteCurrentPage(false);
                    setCurrentPage(currentPage === 0 ? 0 : currentPage - 1);

                    if (currentPage !== 0) {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }
                  }}
                >
                  <FaChevronLeft />
                </button>
                <div className='pagination-block'>{currentPage + 1}</div>
                <button
                  className='pagination-block'
                  onClick={() => {
                    let maxPageCount = maxNumberOfPages - 1;

                    if (currentPage < maxPageCount) {
                      setDeleteCurrentPage(false);
                      setCurrentPage(currentPage + 1);
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }
                  }}
                >
                  <FaChevronRight />
                </button>
              </div>
              <div className='sort-content'>
                <p>Sortiraj</p>
                <select
                  className='store-select'
                  onChange={(event) => {
                    const sortBy = event.target.value;
                    const selected = sortProducts.find((x) => x.title === sortBy);

                    setDeleteCurrentPage(false);

                    dispatch({
                      type: 'sort',
                      payload: {
                        sortBy: selected?.sortBy,
                        sortDirection: selected?.sortDirection,
                      },
                    });
                  }}
                >
                  {sortProducts.map((x, idnex) => (
                    <option value={x.title} key={idnex}>
                      {x.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='products-mapping'>
              {products?.map((product, index) => (
                <ProductListItem product={product} key={index} />
              ))}
              {notFound === true ? <NoResultsFound /> : <></>}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Store;
