import React, { useState } from 'react';
import IUser from '../../models/user';
import { UserRole } from '../../common/enums';
import { formatDate } from '../../helpers/uiHelper';
import AuthService from '../../services/auth.service';
import DeleteAccountModal from './DeleteAccountModal';
import { UserRolesMap } from '../../common/data';
import ChangeUserVerificationStatus from './ChangeUserVerificationStatus';

interface IComponentProps {
  user: IUser;
  loadUsers: () => Promise<void>;
}

const UserListItem: React.FC<IComponentProps> = ({ user, loadUsers }) => {
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState<boolean>(false);
  const [isVerifiedModalOpen, setIsVerifiedModalOpen] = useState<boolean>(false);
  const [disableSendMailBtn, setDisableSendMailBtn] = useState<boolean>(false);
  const [mailBtnMsg, setMailBtnMsg] = useState<string>('RESETUJ LOZINKU');

  const adminUI = user.roleId === UserRole.Admin ? { color: '#fa0a32' } : {};

  const sendResetPasswordMail = async () => {
    if (disableSendMailBtn) {
      return;
    }

    setDisableSendMailBtn(true);
    setMailBtnMsg('ŠALJE SE...');
    const email = user.email;

    const data = await AuthService.forgotPassword(email);

    if (!data.ok) {
      setMailBtnMsg('GREŠKA');

      setTimeout(() => {
        setDisableSendMailBtn(false);
        setMailBtnMsg('RESETUJ LOZINKU');
      }, 1000);
    }

    setMailBtnMsg('POSLATO');

    if (data.ok) {
      setTimeout(() => {
        setDisableSendMailBtn(false);
        setMailBtnMsg('RESETUJ LOZINKU');
      }, 1000);
    }
  };

  return (
    <>
      <DeleteAccountModal
        loadUsers={loadUsers}
        isOpen={isDeleteAccountModalOpen}
        setIsOpen={setIsDeleteAccountModalOpen}
        user={user}
      />
      <ChangeUserVerificationStatus
        user={user}
        getAll={loadUsers}
        setIsVerifiedModalOpen={setIsVerifiedModalOpen}
        isVerifiedModalOpen={isVerifiedModalOpen}
      />
      <div className='user-data-wrapper'>
        <div className='primary-order-data'>
          <div className='align-items'>
            <h1 style={adminUI}>{user.username}</h1>
          </div>
          <div className='align-items'>
            <button
              onClick={sendResetPasswordMail}
              className={`order-button ${disableSendMailBtn ? 'freez-btn' : ''}`}
            >
              {mailBtnMsg}
            </button>
            {user.roleId === UserRole.Buyer && (
              <button
                className='order-button blue-btn'
                onClick={() => setIsVerifiedModalOpen(true)}
              >
                VERIFIKACIJA
              </button>
            )}
            {user.roleId !== UserRole.Admin && (
              <button
                className='order-button edit-order-btn'
                onClick={() => setIsDeleteAccountModalOpen(true)}
              >
                IZBRIŠI NALOG
              </button>
            )}
          </div>
        </div>
        <div className='order-data-admin'>
          <div className='contact-data-order-admin user-admin-data'>
            <h1>Kontakt podaci</h1>
            <h3>
              {user.firstname} {user.lastname}
            </h3>
            <h3>{user.email}</h3>
            <h3>{user.phone}</h3>
          </div>
          {user.roleId === UserRole.Buyer && (
            <div className='contact-data-order-admin user-admin-data'>
              <h1>Adresa</h1>
              <h3>{user.county}</h3>
              <h3>
                {user.city}, {user.zip}
              </h3>
              <h3>{user.address}</h3>
            </div>
          )}
          <div className='contact-data-order-admin user-admin-data'>
            <h1>Ostalo</h1>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                gap: 7,
              }}
            >
              <h3
                className='user-role-tag'
                style={{
                  backgroundColor: UserRolesMap.find((x) => x.role === user.roleId)?.color,
                }}
              >
                {UserRolesMap.find((x) => x.role === user.roleId)?.title}
              </h3>
              <h3
                className='user-role-tag'
                style={{
                  backgroundColor: !user.isVerified ? '#fa0a32' : '#16c784',
                }}
              >
                {user.isVerified === true ? 'Verifikovan' : 'Nije verifikovan'}
              </h3>
            </div>
            <h3>
              Pridružio se: &nbsp;
              {formatDate(user.time)}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserListItem;
